import { Trans } from '@lingui/macro';
import Modal from 'react-bootstrap/Modal';
import { handleMobileAppNavigation } from '../../utils/navigator-util';
import { CopyIcon } from '../copy-icon/copy-icon';
import './how-to-join-modal.scss';

export interface IHowToJoinModalProps {
    show: boolean;
    onHide?: () => void;
}

const chiaClientUrl = "https://www.chia.net/download/";
const chiaStepsUrl = "https://github.com/Chia-Network/chia-blockchain/wiki/Pooling-User-Guide#how-to-start-pooling-in-6-steps";
const termsUrl = "https://pool.space/terms";
const guideUrl = "https://blog.pool.space/how-to-unstuck-your-account-4d03218aeabf";
const discordUrl = "https://pool.space/discord";

export const HowToJoinModal = (props: IHowToJoinModalProps) => {
    return <Modal className='how-to-join-modal' centered show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton>
            <Modal.Title><Trans>How do I join Space Pool?</Trans></Modal.Title>
        </Modal.Header>

        <Modal.Body className="how-to-join-modal-body">
            <div>
                <ol>
                    <li><Trans>Install the latest version of the Chia client. You can find the release notes and download links <a onClick={(ev: any) => handleMobileAppNavigation(ev, chiaClientUrl)} target="_blank" rel="noreferrer" href={chiaClientUrl}>here</a></Trans></li>
                    <li><Trans>Check out how to join pools in the official Pooling User Guide by the Chia team <a onClick={(ev: any) => handleMobileAppNavigation(ev, chiaStepsUrl)} target="_blank" rel="noreferrer" href={chiaStepsUrl}>here</a></Trans>
                
                    <p><Trans>During step 3, use any of the following addresses to join Space Pool:</Trans></p>
                    
                    <div className="region"><div className="join-url">(Americas) <span className="url">https://na1.pool.space</span></div> <CopyIcon text={"https://na1.pool.space"} /></div>
                    <div className="region"><div className="join-url">(Europe) <span className="url">https://eu1.pool.space</span></div> <CopyIcon text={"https://eu1.pool.space"} /></div>
                    <div className="region"><div className="join-url">(Asia)  <span className="url">https://asia1.pool.space</span></div> <CopyIcon text={"https://asia1.pool.space"} /></div>
                    
                    <p><Trans>All three URLs connect to the same pool containing all the users of Space Pool. Make sure you don’t have a trailing slash when you enter it in the Chia GUI or CLI. If you get an error connecting to the url, please check section 4.3 in the <a onClick={(ev: any) => handleMobileAppNavigation(ev, termsUrl)} target="_blank" rel="noreferrer" href={termsUrl}>terms</a> page</Trans></p>
                    </li>
                </ol>
                <Trans>Should you run into issues, here is a <a onClick={(ev: any) => handleMobileAppNavigation(ev, guideUrl)} target="_blank" rel="noreferrer" href={guideUrl}>guide</a> to common issues and how to fix them. Reach out in the <a onClick={(ev: any) => handleMobileAppNavigation(ev, discordUrl)} target="_blank" rel="noreferrer" href={discordUrl}>#pooling-help</a> discord channel if you have any questions</Trans>
            </div>
        </Modal.Body>

        <Modal.Footer className='how-to-join-modal-footer'>
        </Modal.Footer>
    </Modal>
};