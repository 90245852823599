import { createAction } from "typesafe-actions";
import AccountDetail from "./models/account-detail";
import { IAccountStatsResp } from "./models/account-stats";
import FarmerBlocks from "./models/farmer-block";
import UnclaimedFarmerBlocks from "./models/farmer-unclaimed-block";
import Leaderboard from "./models/leaderboard";
import { PartialsResp } from "./models/partials";
import { ITransactionsResp } from "./models/payouts";
import PoolStats, { IPoolStatHistory } from './models/pool-stats';
import PoolHistory from "./models/PoolHistory";
import Quote from "./models/quote";
import RecentlyFarmed from "./models/recently-farmed";
import { RoundsResp } from "./models/rounds";

// NOTE
// DO NOT USE dynamic string operations(like template string) as action type property.
// see more details: https://github.com/piotrwitek/typesafe-actions#--the-actions;
export const RECENTLY_FARMED_GET = "@@recentlyFarmed/GET";
export const RECENTLY_FARMED_SET = "@@recentlyFarmed/SET";
export const RECENTLY_FARMED_ERROR = "@@recentlyFarmed/ERROR";
export const CHIA_QUOTE_GET = "@@chiaQuote/GET";
export const CHIA_QUOTE_SET = "@@chiaQuote/SET";
export const CHIA_QUOTE_ERROR = "@@chiaQuote/ERROR";
export const POOL_STATS_GET = "@@poolStats/GET";
export const POOL_STATS_SET = "@@poolStats/SET";
export const POOL_STATS_ERROR = "@@poolStats/ERROR";
export const FARM_STATS_GET = "@@farmStats/GET";
export const FARM_STATS_SET = "@@farmStats/SET";
export const FARM_STATS_ERROR = "@@farmStats/ERROR";
export const LEADERBOARD_GET = "@@leaderboard/GET";
export const LEADERBOARD_SET = "@@leaderboard/SET";
export const LEADERBOARD_ERROR = "@@leaderboard/ERROR";
export const STATISTICS_GET = "@@statistics/GET";
export const STATISTICS_SET = "@@statistics/SET";
export const STATISTICS_ERROR = "@@statistics/ERROR";
export const FARM_TRANSACTIONS_GET = "@@farmTransactions/GET";
export const FARM_TRANSACTIONS_SET = "@@farmTransactions/SET";
export const FARM_TRANSACTIONS_ERROR = "@@farmTransactions/ERROR";
export const FARM_BLOCKS_GET = "@@farmBlocks/GET";
export const FARM_BLOCKS_SET = "@@farmBlocks/SET";
export const FARM_BLOCKS_ERROR = "@@farmBlocks/ERROR";
export const FARM_UNCLAIMED_BLOCKS_GET = "@@farmUnclaimedBlocks/GET";
export const FARM_UNCLAIMED_BLOCKS_SET = "@@farmUnclaimedBlocks/SET";
export const FARM_UNCLAIMED_BLOCKS_ERROR = "@@farmUnclaimedBlocks/ERROR";
export const FARM_PARTIALS_GET = "@@farmPartials/GET";
export const FARM_PARTIALS_SET = "@@farmPartials/SET";
export const FARM_PARTIALS_ERROR = "@@farmPartials/ERROR";
export const DARK_MODE_SET = "@@/darkMode/SET";

// top level
export const setDarkModeAction = createAction(
    DARK_MODE_SET,
    resolve => (payload: { darkMode: boolean }) => resolve({ ...payload }),
);

// recentlyFarmed
export const recentlyFarmedGetAction = createAction(
    RECENTLY_FARMED_GET,
    (resolve) => ({ continuationToken: string }) => resolve({ continuationToken: string })
);

export const recentlyFarmedSetAction = createAction(
    RECENTLY_FARMED_SET,
    (resolve) => (data: RecentlyFarmed) => resolve(data)
);

export const recentlyFarmedErrorAction = createAction(
    RECENTLY_FARMED_ERROR,
    (resolve) => (error: Error) => resolve(error)
);

// chia quote
export const chiaQuoteGetAction = createAction(
    CHIA_QUOTE_GET,
    (resolve) => () => resolve({})
);

export const chiaQuoteSetAction = createAction(
    CHIA_QUOTE_SET,
    (resolve) => (data: Quote) => resolve(data)
);

export const chiaQuoteErrorAction = createAction(
    CHIA_QUOTE_ERROR,
    (resolve) => (error: Error) => resolve(error)
);

// pool stats
export const poolStatsGetAction = createAction(
    POOL_STATS_GET,
    (resolve) => () => resolve({})
);

export const poolStatsSetAction = createAction(
    POOL_STATS_SET,
    (resolve) => (data: PoolStats) => resolve(data));

export const poolStatsErrorAction = createAction(
    POOL_STATS_ERROR,
    (resolve) => (error: Error) => resolve(error)
);

// pool stats history
export const poolStatHistoryGetAction = createAction(
    '@@poolStatsHistory/GET',
    (resolve) => () => resolve({})
);

export const poolStatHistorySetAction = createAction(
    '@@poolStatsHistory/SET',
    (resolve) => (data: IPoolStatHistory[]) => resolve(data));

export const poolStatHistoryErrorAction = createAction(
    '@@poolStatsHistory/ERROR',
    (resolve) => (error: Error) => resolve(error)
);

// leaderboard
export const leaderboardGetAction = createAction(
    LEADERBOARD_GET,
    (resolve) => (pageSize?: number) => resolve({ pageSize })
);

export const leaderboardSetAction = createAction(
    LEADERBOARD_SET,
    (resolve) => (data: Leaderboard) => resolve(data));

export const leaderboardErrorAction = createAction(
    LEADERBOARD_ERROR,
    (resolve) => (error: Error) => resolve(error)
);

// statistics
export const statisticsGetAction = createAction(
    STATISTICS_GET,
    (resolve) => () => resolve()
);

export const statisticsSetAction = createAction(
    STATISTICS_SET,
    (resolve) => (data: PoolHistory) => resolve(data));

export const statisticsErrorAction = createAction(
    STATISTICS_ERROR,
    (resolve) => (error: Error) => resolve(error)
);

// farm partials
export const accountPartialsGetAction = createAction(
    FARM_PARTIALS_GET,
    (resolve) => ({ launcherId: string }) => resolve({ launcherId: string })
);

export const accountPartialsSetAction = createAction(
    FARM_PARTIALS_SET,
    (resolve) => (data: PartialsResp) => resolve(data));

export const accountPartialsErrorAction = createAction(
    FARM_PARTIALS_ERROR,
    (resolve) => (error: Error) => resolve(error)
);

// farm rounds
export const accountRoundsGetAction = createAction(
    "@@farmRounds/GET",
    (resolve) => ({ launcherId: string }) => resolve({ launcherId: string })
);

export const accountRoundsSetAction = createAction(
    "@@farmRounds/SET",
    (resolve) => (data: RoundsResp) => resolve(data));

export const accountRoundsErrorAction = createAction(
    "@@farmRounds/ERROR",
    (resolve) => (error: Error) => resolve(error)
);

// farm transactions
export const accountTransactionsGetAction = createAction(
    FARM_TRANSACTIONS_GET,
    (resolve) => ({ launcherId: string }) => resolve({ launcherId: string })
);

export const accountTransactionsSetAction = createAction(
    FARM_TRANSACTIONS_SET,
    (resolve) => (data: ITransactionsResp) => resolve(data));

export const accountTransactionsErrorAction = createAction(
    FARM_TRANSACTIONS_ERROR,
    (resolve) => (error: Error) => resolve(error)
);

// farm unclaimed blocks
export const accountUnclaimedBlocksGetAction = createAction(
    FARM_UNCLAIMED_BLOCKS_GET,
    (resolve) => ({ launcherId: string }) => resolve({ launcherId: string })
);

export const accountUnclaimedBlocksSetAction = createAction(
    FARM_UNCLAIMED_BLOCKS_SET,
    (resolve) => (data: UnclaimedFarmerBlocks) => resolve(data));

export const accountUnclaimedBlocksErrorAction = createAction(
    FARM_UNCLAIMED_BLOCKS_ERROR,
    (resolve) => (error: Error) => resolve(error)
);

// farm blocks
export const accountBlocksGetAction = createAction(
    FARM_BLOCKS_GET,
    (resolve) => ({ launcherId: string }) => resolve({ launcherId: string })
);

export const accountBlocksSetAction = createAction(
    FARM_BLOCKS_SET,
    (resolve) => (data: FarmerBlocks) => resolve(data));

export const accountBlocksErrorAction = createAction(
    FARM_BLOCKS_ERROR,
    (resolve) => (error: Error) => resolve(error)
);


// account stats
export const accountStatsGetAction = createAction(
    '@@accountStats/GET',
    (resolve) => ({ launcherId: string }) => resolve({ launcherId: string })
);

export const accountStatsSetAction = createAction(
    '@@accountStats/SET',
    (resolve) => (data: IAccountStatsResp) => resolve(data));

export const accountStatsErrorAction = createAction(
    '@@accountStats/ERROR',
    (resolve) => (error: Error) => resolve(error)
);

// account
export const accountDetailGetAction = createAction(
    FARM_STATS_GET,
    (resolve) => ({ launcherId: string }) => resolve({ launcherId: string })
);

export const accountDetailSetAction = createAction(
    FARM_STATS_SET,
    (resolve) => (data: AccountDetail) => resolve(data));

export const accountDetailErrorAction = createAction(
    FARM_STATS_ERROR,
    (resolve) => (error: Error) => resolve(error)
);

// account settings
export const accountSettingsSetAliasAction = createAction(
    '@@accountSettings/alias/SET',
    resolve => (payload: { launcherId: string; loginToken: string; alias: string }) => resolve({ ...payload }),
);