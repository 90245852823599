import { t, Trans } from '@lingui/macro';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "../../index.reducers";
import { actions } from "../../store";
import { getAge } from '../../utils/date-util';
import { handleMobileAppNavigation, mobilePostMessage } from '../../utils/navigator-util';
import { LuckInfoIcon } from '../luck-info-icon/luck-info-icon';
import { SpacePoolTable } from '../spacepool-table/spacepool-table';
import './recently-farmed.scss';
import { madMaxWinnerIdentity } from '../../constants';

interface Props {
    showTitle?: boolean;
    showViewAll?: boolean;
}

function formatLuck(luck: number) {
    if (luck > 400) {
        return { label: t`Extremely Unlucky`, className: 'unlucky' };
    } else if (luck > 250) {
        return { label: t`Very Unlucky`, className: 'unlucky' };
    } else if (luck > 150) {
        return { label: t`Unlucky`, className: 'unlucky' };
    }

    if (luck < 25) {
        return { label: t`Extremely Lucky`, className: 'lucky' };
    } else if (luck < 50) {
        return { label: t`Very Lucky`, className: 'lucky' };
    } else if (luck < 67) {
        return { label: t`Lucky`, className: 'lucky' };
    }

    return { label: t`Average`, className: 'average-luck' };
}

export const RecentlyFarmed = (props: Props) => {
    const dispatch = useDispatch();
    const [lastNextToken, setLastNextToken] = useState<string>('');
    const history = useHistory();

    const onNavigateToViewAllFarmed = (ev: any) => {
        if (navigator.userAgent.indexOf('SpacePoolApp') >= 0) {
            mobilePostMessage(JSON.stringify({ navigateTo: 'ViewAllFarmed', accountId: ev.target.dataset.hash }), '*')
        } else {
            history.push(`/viewallfarmed`);
        }
        ev.preventDefault();
    };

    const onLoadMoreItems = (ev: any) => {
        setLastNextToken(recentlyFarmed?.continuationToken || '');
        dispatch(actions.recentlyFarmedGetAction({
            continuationToken: recentlyFarmed?.continuationToken
        }));
        ev.preventDefault();
    };

    const onNavigateToAddress = (ev: any) => {
        if (navigator.userAgent.indexOf('SpacePoolApp') >= 0) {
            mobilePostMessage(JSON.stringify({ navigateTo: 'Stats', accountId: ev.target.dataset.hash }), '*')
        } else {
            history.push(`/account/${ev.target.dataset.hash}`);
        }
        ev.preventDefault();
    };

    useEffect(() => {
        dispatch(actions.recentlyFarmedGetAction({
            continuationToken: recentlyFarmed?.continuationToken
        }));
    }, [dispatch]);
    const { recentlyFarmed } = useSelector((state: RootState) => state.recentlyFarmed);

    if (!recentlyFarmed?.results?.length) {
        return null;
    }

    var results = props.showViewAll ? recentlyFarmed?.results?.slice(0, 15) : recentlyFarmed?.results;
    const pendingInfo = <div className="pending-block">
        <Trans>Pending</Trans>
        <OverlayTrigger
            trigger="click"
            key={"stat-box-pending-block-" + Math.random()}
            placement={"bottom"}
            rootClose
            overlay={
                <Popover id={`popover-positioned-${"bottom"}`}>
                    <Popover.Content>
                        <div className="pending-content">
                            {t`Waiting for blockchain confirmation and calculating round shares for all users`}
                        </div>
                    </Popover.Content>
                </Popover>
            }
        >
            <div className="pending-info">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                </svg>
            </div>
        </OverlayTrigger>
    </div>;

    return (
        <div className='recently-farmed'>
            {props.showTitle && <div className='recently-farmed-title'>
                <h4><Trans>Recently Farmed Chia</Trans></h4>
            </div>}

            <SpacePoolTable
                headers={[
                    {
                        label: <Trans>Block #</Trans>,
                        extraClassName: 'block-number',
                    },
                    {
                        label: <Trans>Coin Hash</Trans>,
                        extraClassName: 'header-hash',
                        flexible: true,
                    },
                    {
                        label: <Trans>Farmer</Trans>,
                        flexible: true,
                    },
                    {
                        label: <div className="luck-header">
                            <Trans>Luck</Trans>
                            <LuckInfoIcon />
                        </div>,
                        extraClassName: 'luck-column',
                    },
                    {
                        label: <Trans>Date</Trans>,
                    },
                    {
                        label: <div className="luck-header">
                            <Trans>Reward</Trans>
                            <OverlayTrigger
                                trigger="click"
                                key="stat-box-luck-header"
                                placement={"bottom"}
                                rootClose
                                overlay={
                                    <Popover id={`popover-positioned-${"bottom"}`}>
                                        <Popover.Content>
                                            <div className="luck-content">
                                                {t`According to the Chia pooling protocol, 1/8 of the XCH reward is given directly to the farmer who won the block, and 7/8 of the XCH reward is given to the pool to distribute among its members proportionally to their contribution.`}
                                            </div>
                                        </Popover.Content>
                                    </Popover>
                                }
                            >
                                <div className="reward-info">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                    </svg>
                                </div>
                            </OverlayTrigger>
                        </div>,
                        extraClassName: 'reward',
                    },
                ]}
                rows={results.map((x, i) => {
                    const formattedLuck = formatLuck(x.luck);
                    return [
                        x.confirmed ?
                            (x.winnerIdentity !== madMaxWinnerIdentity ? <span><a href={`https://xch.space/blocks/${x.blockNumber}`} target="_blank" onClick={(ev: any) => handleMobileAppNavigation(ev)}>{x.blockNumber}</a></span> :
                                <span><a href={`https://xch.space/blocks/${x.blockNumber}`} target="_blank" onClick={(ev: any) => handleMobileAppNavigation(ev)}>{x.blockNumber}</a>
                                    <OverlayTrigger
                                        trigger="click"
                                        key="gigahorse-box-popver"
                                        placement={"bottom"}
                                        rootClose
                                        overlay={
                                            <Popover id={`gigahorse-positioned-${"bottom"}`}>
                                                <Popover.Content>
                                                    <div>
                                                        <Trans>This farmer block reward was claimed by Gigahorse as a fee. See <a href="https://github.com/madMAx43v3r/chia-gigahorse">madMAx43v3r's GitHub page</a> for more information about Gigahorse fees.</Trans>
                                                    </div>
                                                </Popover.Content>
                                            </Popover>
                                        }>
                                        <span style={{ cursor: 'pointer' }}> 🐎</span>
                                    </OverlayTrigger></span>)
                            : pendingInfo,

                        x.confirmed ? x.headerHash : <Trans>Pending</Trans>,

                        x.confirmed ? <a href="/" onClick={onNavigateToAddress} data-hash={x.winnerLauncherId}>{x.winnerLauncherId}</a> : <Trans>Pending</Trans>,

                        x.confirmed
                            ? <OverlayTrigger
                                trigger="click"
                                key={i}
                                placement={"bottom"}
                                rootClose
                                overlay={
                                    <Popover id={`popover-positioned-${"bottom"}`}>
                                        <Popover.Content>
                                            <div className="luck-content">{`${x.luck.toFixed(0)}%`}</div>
                                        </Popover.Content>
                                    </Popover>
                                }
                            >
                                <div className={classNames('luck-value', formattedLuck.className)}>
                                    {formattedLuck.label}
                                </div>
                            </OverlayTrigger>
                            : pendingInfo,

                        getAge(x.dateTimeUtc),

                        `${x.reward} XCH`
                    ];
                })}
            />

            {props.showViewAll && <div className='view-all'>
                <a href="/" onClick={onNavigateToViewAllFarmed}><button className='primary'><Trans>View more</Trans></button></a>
            </div>}
        </div>
    );
};