import { Epic } from "redux-observable";
import { from, of } from "rxjs";
import { exhaustMap, filter, map, catchError } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";

import { RootState } from "../../index.reducers";
import { actions, ActionsType } from "../../store";
import * as API from "../../services/api";

export const chiaQuoteEpic: Epic<
  ActionsType,
  ActionsType,
  RootState,
  typeof API
> = (action$, store, { getQuote }) => {
  return action$.pipe(
    filter(isActionOf(actions.chiaQuoteGetAction)),
    exhaustMap((action) =>
      from(getQuote()).pipe(
        map(actions.chiaQuoteSetAction),
        catchError((error) => of(actions.chiaQuoteErrorAction(error)))
      )
    )
  );
 }

export default chiaQuoteEpic;
