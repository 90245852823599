import { currencies, currencyCookieName } from "../components/currency-picker/currency-picker";
import Quote from "../models/quote";
import { getCookie } from "./cookie-manager";

export const getCurrency = (chiaQuote?: Quote): {price: number, change: number, key: string } => {
    try {
        var currency: { key: string; label: string;};
        var currencyKey = getCookie<string>(currencyCookieName) || "USD";

        var currencyInfo = chiaQuote && chiaQuote?.data && chiaQuote?.data?.XCH?.quote[currencyKey];
        if (!currencyInfo) {
            currencyInfo = chiaQuote && chiaQuote?.data && chiaQuote?.data?.XCH?.quote?.USD;
            currency = currencies[0];
        }

        return {
            price: currencyInfo?.price || 0,
            change: currencyInfo?.percent_change_24h || 0,
            key: currencyKey
        }
    } catch(e) {
        return {
            price: 0,
            change: 0,
            key: "USD"
        }
    }
}