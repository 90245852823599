import { dustStormMempoolSize, dustStormNumRequiredDatapoints } from "../constants";
import PoolStats from "../models/pool-stats";

export const isDustStorm = (poolStats: PoolStats | undefined) => {
    let exceedStormThresholdCount = 0;

    if ((poolStats?.recentStats?.results?.length ?? 0) > dustStormNumRequiredDatapoints) {
        for (var i = 0; i < dustStormNumRequiredDatapoints; i++) {
            if ((poolStats?.recentStats?.results[i].mempoolSize ?? 0) > dustStormMempoolSize) {
                exceedStormThresholdCount++;
            }
        }
    }
    return (exceedStormThresholdCount === dustStormNumRequiredDatapoints);
}