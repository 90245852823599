import { Trans } from '@lingui/macro';
import { poolFeePercent, pplnsPartials } from '../constants';
import { handleMobileAppNavigation } from '../utils/navigator-util';
import './fees-page.scss';

export const FeesPage = () => {
    return (
        <div className='fees-page'>
            <h4><Trans>Fees</Trans></h4>
            <div className='fees-page-content'>
                <div>
                    <Trans>Space Pool's basic fee is </Trans> <span className="accent-color"> {Intl.NumberFormat().format(poolFeePercent)}% </span><Trans>, but can be lowered with loyalty programs.</Trans>
                    <br /><br />
                    <Trans>Fees will be collected before crediting your “Unpaid Balance”. For example, if your share for a block reward is 0.1 XCH and your schedule of fees is 0.75% after applying discounts, then your “Unpaid Balance” will be credited 0.09925 XCH and the pool collects 0.00075 XCH.</Trans>
                </div>

                <br /><br />
                <div>
                    <div className="bold"><Trans>How does the loyalty discount program work?</Trans></div>
                    <br />
                    <p><Trans>Based on your rank when a new block is found</Trans></p>
                    <ul>
                        <li><Trans>Ranks 1 - 10 will receive a <span className="accent-color">10%</span> discount</Trans></li>
                        <li><Trans>Ranks 11 - 100 will receive a <span className="accent-color">7%</span> discount</Trans></li>
                        <li><Trans>Ranks 101 - 1,000 receive a <span className="accent-color">5%</span> discount</Trans></li>
                        <li><Trans>Ranks 1,001 to 10,000 receive a <span className="accent-color">3%</span> discount</Trans></li>
                    </ul>

                    <p><Trans>Based on how long you have been a member with Space Pool when a new block is found</Trans></p>
                    <ul>
                        <li><Trans>Your fees are reduced (linearly) the longer you stay with the pool, up to a max of <span className="accent-color">15%</span> at two years and beyond.</Trans></li>
                        <li><Trans>Your farms going offline <b>will not</b> interrupt or reset this discount.</Trans></li>
                        <li><Trans>Explicitly switching to another pool or changing to self-pooling for longer than 12 hours <b>will reset this discount completely</b>.</Trans></li>
                    </ul>
                    <p><Trans>Both discounts can be combined, potentially adding up to <span className="accent-color">25%</span>.</Trans></p>
                </div>

                <br /><br />
                <div>
                    <div className="bold"><Trans>PPLNS - How does the Pool distribute block rewards?</Trans></div>
                    <br />
                    <p><Trans>Space Pool uses PPLNS (Pay Per Last N Shares) to distribute block rewards. This means when a block is won, we look at the last valid N partials (shares) submitted by all users in the Pool and distribute the block reward value based on your share among those N partials, taking into account the number of partials and the difficulty of each valid partial. This share is then credited towards your “Unpaid Balance”.</Trans></p>
                    <p><Trans>The N value is currently {Intl.NumberFormat().format(pplnsPartials)} or the number of partials in the last 24 hrs, whichever is smaller.</Trans></p>
                    <p><Trans>Only partials that are valid as defined by the official pooling protocol are counted.</Trans></p>
                    <p><Trans>Your PlotNFT must still be assigned to Space Pool at the time of winning a block or you will not be credited. This means that even if you submitted partials during the PPLNS period but left Space Pool for another pool or switched to self-pooling before a block was won, you would NOT be credited.</Trans></p>
                </div>
                <br />
                <div className="bold"><Trans>Payouts</Trans></div>
                <br />
                <p><Trans>Anyone above their minimum “Unpaid Balance” will be paid out. By default, you will only be paid out once every 24 hours even if you reach the minimum "Unpaid Balance" more than once per day. You can be disable this 24 hour limit via your account settings to receive multiple payouts per day if you so choose. Your progress towards the minimum payout balance and your payout history can be found on your account page. Space Pool will cover all transaction fees associated with the payout. The minimum “Unpaid Balances” for payouts is <span className='accent-color'>0.01 XCH</span>.</Trans></p>

                <br />
                <div className="bold"><Trans>Payouts during Duststorms</Trans></div>
                <br />
                <p>Duststorm refers to some artificial event (whether malicious or not, both intended or unintentional) that impacts the Chia blockchain to the point where processing of transactions without fees would be affected.</p>

                <p><Trans>During a duststorm the following policies will be used instead of the normal payout policies:</Trans></p>
                <p><Trans><b>Minimum Payout</b>: <span className='accent-color'>0.05 XCH</span>.</Trans></p>
                <p><Trans><b>Payout cooldown</b>: <span className='accent-color'>24 hours</span> (one payout per day, even if minimum payout threshold is met earlier).</Trans></p>
                <p><b>Space Pool will pay all transaction fees</b>; farmers can expect their usual total payout amount over a period to be the same but receive fewer, larger payouts.</p>

                <br />
                <p><Trans>All values regarding PPLNS, Fees, and Payouts are subject to periodic review and may be changed. Any changes will be posted on some or all of these sources: <a onClick={(ev: any) => handleMobileAppNavigation(ev)} href="https://pool.space">https://pool.space</a>, <a onClick={(ev: any) => handleMobileAppNavigation(ev)} target="_blank" href="https://blog.pool.space">https://blog.pool.space</a> and in the official Space Pool <a onClick={(ev: any) => handleMobileAppNavigation(ev)} target="_blank" href="https://pool.space/discord">Discord</a> announcements at least 24 hours prior to going into effect.</Trans></p>

                <p><Trans>By joining Space Pool, you agree to the distribution methodology (PPLNS), the schedule of fees including the means by which it is calculated and collected, the payout schedule, and are compliant with our Terms of Service.</Trans></p>
            </div>
        </div>
    );
};
