import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import { accountReducer, AccountState } from "./account-page/account-page.reducers";
import {
    chiaQuoteReducer, ChiaQuoteState, poolStatsReducer, PoolStatsState,
    recentlyFarmedReducer, RecentlyFarmedState
} from './landing-page/landing-page.reducers';
import {
    leaderboardReducer,
    LeaderboardState
} from './leaderboard-page/leaderboard-page.reducers';
import { statisticsReducer, StatisticsState } from "./statistics-page/statistics-page.reducers";
import { actions, ActionsType } from "./store";
import { getCookie } from "./utils/cookie-manager";
import { isEmbedMode } from "./utils/navigator-util";


export interface TopLevelState {
    darkMode: boolean;
}

export const topLevelReducer
    = createReducer<TopLevelState, ActionsType>({
        darkMode: (!isEmbedMode() && !getCookie('darkMode')) || getCookie('darkMode') === '1' || (isEmbedMode() && navigator.userAgent.indexOf('dark') >= 0),
    })
        .handleAction(actions.setDarkModeAction, (state, action) => ({
            ...state,
            darkMode: action.payload.darkMode,
        }));

export type RootState = {
    recentlyFarmed: RecentlyFarmedState;
    chiaQuote: ChiaQuoteState;
    poolStats: PoolStatsState;
    leaderboard: LeaderboardState;
    statistics: StatisticsState;
    account: AccountState,
    topLevel: TopLevelState;
};

const reducers = combineReducers({
    recentlyFarmed: recentlyFarmedReducer,
    chiaQuote: chiaQuoteReducer,
    poolStats: poolStatsReducer,
    leaderboard: leaderboardReducer,
    statistics: statisticsReducer,
    account: accountReducer,
    topLevel: topLevelReducer,
});

export default reducers;