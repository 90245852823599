import { t } from '@lingui/macro';
import { useSelector } from 'react-redux';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { RootState } from '../../index.reducers';

export const RecentShareRateChart = () => {
    // https://recharts.org/en-US/examples/SimpleLineChart
    const darkMode = useSelector((state: RootState) => state.topLevel.darkMode);
    const rounds = useSelector((state: RootState) => state.account.rounds);

    if (!rounds?.length) {
        return null;
    }

    let data = rounds ? rounds.map(x => ({
        block: x.block,
        value: parseFloat((x.share * 100).toFixed(6)),
    })) : [];

    data = data.sort((a, b) => a.block - b.block).slice(-50);

    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart
                width={500}
                height={300}
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" opacity="0.2" />
                <XAxis dataKey="block" />
                <YAxis />
                <Tooltip contentStyle={{ backgroundColor: darkMode ? '#000' : '#FFF', opacity: '0.7' }} />
                <Legend />
                <Line type="monotone" dataKey="value" name={t`Your share %`} stroke="#03b000" />
            </LineChart>
        </ResponsiveContainer>
    );
}
