import './faq-page.scss';
import { FAQQuestion } from '../components/faq-question/faq-question';
import { defaultMinPayout, poolFeePercent, pplnsPartials } from '../constants';
import SoloImg from '../images/solo.png';
import PoolFarmingImg from '../images/pool-tree.png';
import LeftPool from '../images/left-pool.png';
import { Trans, t } from '@lingui/macro';
import { Space } from '../components/space/space';
import { Link } from 'react-router-dom';
import { handleMobileAppNavigation } from '../utils/navigator-util';

export const FAQPage = () => {
    return (
        <div className="faq-page">
            <h4><Trans>FAQ</Trans></h4>
            <FAQQuestion title={t`What is Chia all about?`}>
                <span><Trans><a onClick={(ev: any) => handleMobileAppNavigation(ev)} href="https://www.chia.net/" target="_blank" rel="noreferrer">Chia.net</a> is the official website of the Chia Network. Chia was incorporated in August of 2017 to develop an improved blockchain, smart transaction platform, and to improve the global financial and payments systems. Chia is an enterprise-grade cryptocurrency using the first new Nakamoto consensus algorithm since Bitcoin. To learn more, read <a onClick={(ev: any) => handleMobileAppNavigation(ev)} href="https://www.chia.net/greenpaper/" target="_blank" rel="noreferrer">Chia's green paper</a>.</Trans></span>

                <span><Trans>Chia supports smart contracts through the <a onClick={(ev: any) => handleMobileAppNavigation(ev)} href="https://chialisp.com/" target="_blank" rel="noreferrer">Chialisp</a> programming language. This allows for custom DeFi applications that are secure and easily auditable. Tokens available on the Chia blockchain follow the <strong>Chia Asset Token (CAT)</strong> standard.</Trans></span>
                
            </FAQQuestion>

            <FAQQuestion title={t`What is Chia Farming?`}>
                <span><Trans>Utilizing unused storage space, everyone has the ability to support the permissionless and decentralized Chia network. Plot to fill unused hard drive space, then passively farm to have a chance of confirming a Chia block and getting rewarded the Chia (XCH) cryptocurrency. A new block is added to the blockchain on average every 18.75 seconds. </Trans></span>
            </FAQQuestion>

            <FAQQuestion title={t`Why should I join a Chia pool?`}>
            <div className="why-using-pools">
                <span><Trans>Chia farming in a pool produces a constant reward of smaller values, whereas solo farming tends to be more erratic and could take years to farm even one block. With pooling, you have confidence that your farm is working and earning you Chia.</Trans></span>
                <div className='diagram'>
                    <div className="solo-tree">
                        <img alt={t`Solo farming`} src={SoloImg}></img>
                        <div><Trans>Solo farming</Trans></div>
                    </div>
                    <div className={`pool-tree`}>
                        <img alt="Pool farming" src={PoolFarmingImg}></img>
                        <div><Trans>Pool farming</Trans></div>
                    </div>
                </div>
            </div>
            </FAQQuestion>
            <FAQQuestion title={t`Why should I join Space Pool?`}>
                <p> <Trans>Being the largest pool following the official pooling protocol, Space Pool has the most consistent block wins and payouts. We provide an active and engaged community with thousands of members for any questions or issues that may arise. </Trans></p>

                <p><Trans>We support farm notifications (through Discord and mobile push notifications) if your farm loses connection to the pool, allowing you to quickly recover if your PC crashes. </Trans></p>

                <p><Trans>Use our public analytics to track partials submitted, estimated farm size, block wins, and more.</Trans></p>
                
                <p><Trans>Space Pool is available through an app on both iOS and Android.</Trans></p>
                
            </FAQQuestion>
            <FAQQuestion title={t`Do I need Chia in my wallet to join a pool? How do I get some?`}>
                <p><Trans>To join a pool you must create a plot NFT. This process is a transaction on the blockchain and requires a very tiny amount of Chia. Use a <a onClick={(ev: any) => handleMobileAppNavigation(ev)} href="https://faucet.chia.net/" target="_blank" rel="noreferrer">chia faucet</a>! This tiny amount of chia is often measured in <strong>mojos</strong>, the smallest fraction of a Chia coin.</Trans></p>

                <p><Trans>To see this Chia in your wallet, you'll want to make sure your client is completely synced and that the wallet is synced as well. You can always check a receive address on a block explorer such as <a onClick={(ev: any) => handleMobileAppNavigation(ev)} href="https://xch.space" target="_blank" rel="noreferrer">Space Explorer</a> if you want to double check the address or transaction.</Trans></p>
                
            </FAQQuestion>
            <FAQQuestion title={t`How does the pool prove that members actually have the amount of space they have?`}>
                <p><Trans>The pool does not need to know the amount of space you have. Your plots generate partials (partial solutions) that are sent to the pool. The pool will never ask or trust anyone claiming to have some amount of space and instead collects partials as cryptographic proof you have valid plots farming.</Trans></p>

                <p><Trans>When anyone in the pool wins a block, we look back at the partials each farmer has submitted and divide the XCH reward accordingly.</Trans></p>
            </FAQQuestion>
            <FAQQuestion title={t`Is Space Pool friendly to the small farmer?`}>
                <p><Trans>Having a pool that consistently wins blocks is very beneficial to the small farmer as it smooths out rewards more than solo farming or using a very small pool. The larger the pool, the more often Space Pool farmers win blocks, the smoother the payouts.</Trans></p>

                <p><Trans>A common misconception is that a larger pool means smaller rewards. A more correct explanation is that a large pool provides more frequent rewards that are smaller. The end result is the same but more predictable and consistent.</Trans></p>
            </FAQQuestion>
            <FAQQuestion title={t`How are payout amounts calculated?`}>
                <p><Trans>Valid plots send partials to the pool. We keep track of these partials and divide earnings proportionally based on your space contribution.</Trans></p>

                <p><Trans>Because large farms can submit a large number of partials, there is also a <strong>difficulty</strong> level. To reduce network traffic, higher difficulty partials are sent less often but are worth more. The total contribution is measured in points.</Trans></p> 

                <p><Trans>You can see your total contribution in the partials tab of your launcher page.</Trans></p>
            </FAQQuestion>
            <FAQQuestion title={t`What is PPLNS?`}>
                <p><Trans>We pay out XCH to active farmers within the last {Intl.NumberFormat().format(pplnsPartials)} partials sent to Space Pool. This means that any points you collect farming with Space Pool within this window will be considered for XCH payouts.</Trans></p>

                <p><Trans>This payment structure is known as <strong>PPLNS</strong>, or <strong>pay-per-last N shares</strong> (In the context of Chia pooling, pay-per-last N partials). N in our case is {Intl.NumberFormat().format(pplnsPartials)}.</Trans></p>

                <p><Trans>This leads to a gradual increase in rewards when your farm comes online, or a gradual decrease when your farm goes offline as partials expire by going beyond the {Intl.NumberFormat().format(pplnsPartials)} partial window. Here is a visual example of a farm going offline:</Trans></p>

                <div className="why-using-pools">

                    <div className='diagram'>
                        <div className="left-pool">
                            <img alt={t`left pool`} src={LeftPool}></img>
                        </div>
                       
                    </div>
                </div>

                <p><Trans>You can see more details on our <a onClick={(ev: any) => handleMobileAppNavigation(ev)} href="https://pool.space/fees/" target="_blank" rel="noreferrer">fees page</a>.</Trans></p>
            </FAQQuestion>
            <FAQQuestion title={t`Will there be a minimum payout or payout schedule?`}>
                <p><Trans>The default minimum payout is {Intl.NumberFormat().format(defaultMinPayout)} XCH, but we allow this value to be customized by <a onClick={(ev: any) => handleMobileAppNavigation(ev)} href="https://blog.pool.space/how-to-login-to-your-space-pool-farm-settings-page-a161a6bddb91" target="_blank" rel="noreferrer">logging in</a>.</Trans></p>
            </FAQQuestion>
            <FAQQuestion title={t`What are the pool fees?`}>
                <p><Trans>Nobody loves fees, but there is a real cost to running the service in many geographical locations. Our current fee is {Intl.NumberFormat().format(poolFeePercent)}% of pooling rewards. Refer to the <a onClick={(ev: any) => handleMobileAppNavigation(ev)} href="https://pool.space/fees/" target="_blank" rel="noreferrer">fees page</a> for more details.</Trans></p>
            </FAQQuestion>
            <FAQQuestion title={t`Is Space Pool a money transmitter company?`}>
                <p><Trans>Farmers and Pool operators are not money transmitters as described in Section 5.4 of this <a onClick={(ev: any) => handleMobileAppNavigation(ev)} href="https://www.fincen.gov/sites/default/files/2019-05/FinCEN%20Guidance%20CVC%20FINAL%20508.pdf" target="_blank" rel="noreferrer">FINCEN guidance</a> document.</Trans></p>
            </FAQQuestion>
            <FAQQuestion title={t`Do I need to pay taxes? Will you issue a tax document?`}>
                <p><Trans>Farming rewards are income, and it is the responsibility of individuals to report these based on their jurisdiction and consult with a local tax specialist. We do not issue tax documents, but we do give you the ability to download your payout records.</Trans></p>
            </FAQQuestion>
            <FAQQuestion title={t`Having Sync issues? Need to forward port 8444?`}>
            <p><Trans>Check out this guide on <a onClick={(ev: any) => handleMobileAppNavigation(ev)} href="https://github.com/Chia-Network/chia-blockchain/wiki/Resolving-Sync-Issues---Port-8444f" target="_blank" rel="noreferrer">sync issues</a>.</Trans></p>
            </FAQQuestion>
            <FAQQuestion title={t`Farming on many machines?`}>
                <p><Trans>Check out this guide on <a onClick={(ev: any) => handleMobileAppNavigation(ev)} href="https://github.com/Chia-Network/chia-blockchain/wiki/Farming-on-many-machines" target="_blank" rel="noreferrer">harvesters</a>.</Trans></p>

                <p><Trans>With Space Pool you can farm across many machines all using the same plot NFT. You can see valid partials for each of your harvesters within the analytics for your launcher ID.</Trans></p>
            </FAQQuestion>
            <FAQQuestion title={t`Will I need to re-plot everything to join Space Pool?`}>
                <p><Trans>If you are coming from another pool following the official pooling protocol, you can easily move all plots over to farm on Space Pool.</Trans></p>

                <p><Trans>If you're coming from an unofficial pool (using "OG" Plots) and want to upgrade to NFT plots, you'll need to replot to an NFT pointed at Space Pool. If this is the case, we recommend re-plotting your plots drive-by-drive. That way you still solo farm with your old plots while slowly migrating plots over to Space Pool.</Trans></p>

                <p><Trans>When pooling, you'll want to make sure your <a onClick={(ev: any) => handleMobileAppNavigation(ev)} href="https://blog.pool.space/how-to-find-your-0-25xch-after-winning-a-block-in-the-pool-fc61d0bee5c5" target="_blank" rel="noreferrer">farming reward payout address</a> is correct. If you find a block while pooling, this is the address 0.25 XCH will be sent to.</Trans></p>
                
            </FAQQuestion>
             
            <div className="faq-footer">
                <Trans><span className="start">Don’t see your question here? Visit our</span><Space/><a onClick={(ev: any) => handleMobileAppNavigation(ev)} href='https://pool.space/discord' rel="noreferrer" target='_blank'>Discord</a></Trans>
            </div>
        </div>
    );
};