import { t, Trans } from '@lingui/macro';
import { useState } from 'react';
import { useEffect } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CopyIcon } from '../components/copy-icon/copy-icon';
import { SpacePoolTable } from '../components/spacepool-table/spacepool-table';
import { defaultMinPayout } from '../constants';
import { RootState } from "../index.reducers";
import { actions } from "../store";
import { getDelta } from '../utils/date-util';
import { prettifyHash } from '../utils/hash-util';
import { handleMobileAppNavigation } from '../utils/navigator-util';
import useWindowSize from "../utils/use-window-size";
import './payouts-page.scss';

const getStatus = (status: string) => {
    switch (status) {
        case 'Confirmed':
            return t`Confirmed`;
        case 'Unconfirmed':
            return t`Unconfirmed`;
        default:
            return t`Unknown`;
    }
}

interface Props {
    autoRefresh?: boolean;
    customMinPayout?: number;
    autoRefreshValueInSecs?: number;
}

export const PayoutsPage = (props: Props) => {
    const { width } = useWindowSize();
    let { accountId } = useParams<{ accountId: string }>();
    let [ showDuration, setShowDuration] = useState<boolean>(true);
    const dispatch = useDispatch();
    const getData = () => {
        dispatch(actions.accountTransactionsGetAction({ launcherId: accountId }));
    };

    useEffect(() => {
        getData();
        if (props.autoRefresh) {
            const interval = setInterval(getData, (props.autoRefreshValueInSecs || 60) * 1000);
            return () => clearInterval(interval);
        }
    }, [dispatch, accountId, props.autoRefresh]);

    const { transactions } = useSelector((state: RootState) => state.account);

    if (transactions && transactions.length > 1) {
        for (var i = 0; i < transactions.length - 1; i++) {
            transactions[i].duration = getDelta(transactions[i].payoutDate, transactions[i + 1].payoutDate);
        }
    }

    var explanation = <div className="explanation"><Trans>A transaction will be automatically scheduled when the unpaid balance exceeds the minimum payout amount: {Intl.NumberFormat().format(props.customMinPayout || defaultMinPayout)} XCH.</Trans><span style={{ marginLeft: 5 }}><Trans>To change the minimum payout amount or enable payout notifications, follow these <a target='_blank' onClick={(ev: any) => handleMobileAppNavigation(ev, 'https://blog.pool.space/how-to-edit-your-minimum-payout-amount-in-space-pool-660100dee7ff')} href='https://blog.pool.space/how-to-edit-your-minimum-payout-amount-in-space-pool-660100dee7ff'>steps</a>. To change your payout address, follow these <a target='_blank' onClick={(ev: any) => handleMobileAppNavigation(ev, 'https://blog.pool.space/how-to-change-your-payout-address-in-space-pool-df7441f0bfdc')} href='https://blog.pool.space/how-to-change-your-payout-address-in-space-pool-df7441f0bfdc'>steps</a>.</Trans></span></div>;
    if (!transactions?.length) {
        return <div className='payout-page'>
            {explanation}
        </div>;
    }

    return (
        <div className='payout-page'>
            {explanation}
            <div className="explanation"><Trans>By default, payouts are limited to once every 24 hours. You can disable this feature from your <a href="https://blog.pool.space/how-to-change-your-payout-address-in-space-pool-df7441f0bfdc" target='_blank'>Account Settings</a>.</Trans></div>
            <SpacePoolTable
                headerLine={true}
                headers={[
                    {
                        label: <div style={{minWidth:180, cursor:'pointer'}} onClick={() => { setShowDuration(!showDuration)}}>{showDuration ?  <><Trans>Duration</Trans> | <span style={{opacity:'50%'}}><Trans>Date</Trans></span></> : <><Trans>Date</Trans> | <span style={{opacity:'50%'}}><Trans>Duration</Trans></span></>}</div>,
                    },
                    {
                        label: <Trans>Status</Trans>,
                        flexible: true,
                        extraClassName: 'payout-status'
                    },
                    {
                        label: <div className="coin">
                            <Trans>Coin Id</Trans>
                            <OverlayTrigger
                                trigger="click"
                                key="stat-box-coin-id-header"
                                placement={"bottom"}
                                rootClose
                                overlay={
                                    <Popover id={`popover-positioned-${"bottom"}`}>
                                        <Popover.Title as="h3"><Trans>Coin Id</Trans></Popover.Title>
                                        <Popover.Content>
                                            <Trans>If you are the payee, you can verify this payout by executing this command: </Trans><br /><br />
                                            <div className="coin-info-content">
                                                <span>
                                                    chia wallet get_transaction -tx CoinId
                                                </span>
                                                <CopyIcon text="chia wallet get_transaction -tx CoinId" />
                                            </div>
                                        </Popover.Content>
                                    </Popover>
                                }
                            >
                                <div className="coin-id-info">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                    </svg>
                                </div>
                            </OverlayTrigger>
                        </div>,
                        flexible: true,
                        extraClassName: 'payout-coinid'
                    },
                    {
                        label: <Trans>Block #</Trans>,
                    },
                    {
                        label: <Trans>Amount</Trans>,
                    }
                ]}
                rows={transactions && transactions.map((row, i) => {
                    return [
                        <div>{showDuration ? row.duration : new Date(row.payoutDate).toLocaleString()}</div>,
                        <div>{getStatus(row.status)}</div>,
                        <div>
                            <OverlayTrigger
                                trigger="click"
                                key={`stat-box-${i}`}
                                placement={"left"}
                                rootClose
                                overlay={
                                    <Popover id={`popover-positioned-${row.coinId}`}>

                                        <Popover.Content>
                                            <div className="payout-page-item-info">
                                                <div className="payout-page-item-info-label"><Trans>Coin Id</Trans></div>
                                                <div className="payout-page-item-info-copy-coin-id">
                                                    <div className="payout-page-item-info-copy-coin-id-value"><a target="_blank" href={`https://xch.space/coins/0x${row.coinId}`} onClick={(ev: any) => handleMobileAppNavigation(ev)}>{row.coinId}</a></div>
                                                    <CopyIcon text={row.coinId} />
                                                </div>
                                            </div>
                                            <div className="payout-page-item-info">
                                                <span><Trans>View in explorer</Trans></span>&nbsp;<a target="_blank" href={`https://xch.space/coins/0x${row.coinId}`} onClick={(ev: any) => handleMobileAppNavigation(ev)}><svg xmlns="http://www.w3.org/2000/svg" style={{height:20}} viewBox="0 0 512 512"><title>Open</title><path d="M384 224v184a40 40 0 01-40 40H104a40 40 0 01-40-40V168a40 40 0 0140-40h167.48M336 64h112v112M224 288L440 72" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg></a>
                                            </div>
                                            <div className="payout-page-item-info">
                                                <div className="payout-page-item-info-label"><Trans>Confirmation Block</Trans></div>
                                                <div><a target="_blank" href={`https://xch.space/blocks/${row.confirmationBlock}`} onClick={(ev: any) => handleMobileAppNavigation(ev)}>{row.confirmationBlock}</a></div>
                                            </div>
                                            <div className="payout-page-item-info">
                                                <div className="payout-page-item-info-label"><Trans>Fee (XCH)</Trans></div>
                                                <div>{row.fee || 0}</div>
                                            </div>
                                            <div className="payout-page-item-info">
                                                <div className="payout-page-item-info-label"><Trans>Block #</Trans></div>
                                                <div>{row.confirmationBlock > 0 ? <a target="_blank" href={`https://xch.space/blocks/${row.confirmationBlock}`} onClick={(ev: any) => handleMobileAppNavigation(ev)}>{row.confirmationBlock.toString()}</a> : getStatus(row.status)}</div>
                                            </div>
                                            <div className="payout-page-item-info">
                                                <div className="payout-page-item-info-label"><Trans>Status</Trans></div>
                                                <div>{getStatus(row.status)}</div>
                                            </div>
                                            <div className="payout-page-item-info">
                                                <div className="payout-page-item-info-label"><Trans>Launcher Id</Trans></div>
                                                <div>{row.launcherId}</div>
                                            </div>
                                            <div className="payout-page-item-info">
                                                <div className="payout-page-item-info-label"><Trans>Payout Balance (XCH)</Trans></div>
                                                <div>{row.payoutBalanceInXCH}</div>
                                            </div>
                                            <div className="payout-page-item-info">
                                                <div className="payout-page-item-info-label"><Trans>Payout Date</Trans></div>
                                                <div>{new Date(row.payoutDate).toLocaleString()}</div>
                                            </div>
                                            <div className="payout-page-item-info">
                                                <div className="payout-page-item-info-label"><Trans>Payout Puzzle Hash</Trans></div>
                                                <div>{row.payoutPuzzleHash}</div>
                                            </div>
                                        </Popover.Content>
                                    </Popover>
                                }
                            >
                                <span className="coinId">{prettifyHash(row.coinId)}</span>
                            </OverlayTrigger>
                        </div>,
                        <div>{row.confirmationBlock > 0 ? <a target="_blank" href={`https://xch.space/blocks/${row.confirmationBlock}`} onClick={(ev: any) => handleMobileAppNavigation(ev)}>{row.confirmationBlock.toString()}</a> : getStatus(row.status)}</div>,
                        <div>{width > 460 ? row.payoutBalanceInXCH : row.payoutBalanceInXCH.toFixed(5)}</div>,
                    ];
                })}
            />
        </div>
    );
};

