import { Trans } from '@lingui/macro';
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RollingNumber } from '../components/rolling-number/rolling-number';
import { SpacePoolTable } from '../components/spacepool-table/spacepool-table';
import { RootState } from "../index.reducers";
import { actions } from "../store";
import { getAge } from '../utils/date-util';
import { mobilePostMessage } from "../utils/navigator-util";
import { formatTiB } from '../utils/tib-formatter';
import './leaderboard-page.scss';

export const LeaderboardPage = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.leaderboardGetAction());
        dispatch(actions.poolStatsGetAction());
    }, [dispatch]);


    const { leaderboard } = useSelector((state: RootState) => state.leaderboard);
    const { poolStats } = useSelector((state: RootState) => state.poolStats);

    if (!leaderboard?.results?.length) {
        return null;
    }

    const netspace = poolStats ? formatTiB(poolStats.totalNetSpaceTiB) : { value: 0, unit: 'TiB' };
    const poolspace = poolStats ? formatTiB(poolStats.poolNetSpaceTiB) : { value: 0, unit: 'TiB' };
    const globalPendingPoints = leaderboard.results[0]?.globalPendingPoints || 0;

    var onNavigateToAddress = (ev: any) => {
        if (navigator.userAgent.indexOf('SpacePoolApp') >= 0) {
            mobilePostMessage(JSON.stringify({ navigateTo: 'Stats', accountId: ev.target.dataset.hash }), '*')
        } else {
            history.push(`/account/${ev.target.dataset.hash}`);
        }
        ev.preventDefault();
    };

    return (
        <div className='leaderboard-page'>
            <h4><Trans>Leaderboard</Trans></h4>
            <div className='pool-stats'>
                <div className='value-container'>
                    <h5><Trans>Global Netspace</Trans></h5>
                    {netspace && <div className="value"><RollingNumber value={netspace.value} /> {netspace.unit}</div>}
                </div>
                <div className='value-container'>
                    <h5><Trans>Pool Netspace</Trans></h5>
                    {poolspace && <div className="value"><RollingNumber value={poolspace.value} /> {poolspace.unit}</div>}
                </div>
                <div className='value-container'>
                    <h5><Trans>Global Points</Trans></h5>
                    {<div className="value"><RollingNumber value={globalPendingPoints} decimals={0} /></div>}
                </div>
            </div>
            <SpacePoolTable
                headers={[
                    {
                        label: <Trans>Rank</Trans>,
                        compact: true,
                    },
                    {
                        label: <Trans>Farmer</Trans>,
                        flexible: true,
                    },
                    {
                        label: <Trans>Plot Size</Trans>,
                        extraClassName: 'plot-size',
                    },
                    {
                        label: <Trans>Difficulty</Trans>,
                        extraClassName: 'difficulty',
                    },
                    {
                        label: <Trans>Points</Trans>,
                        extraClassName: 'points',
                    },
                    {
                        label: <Trans>Joined</Trans>,
                        extraClassName: 'joined-date',
                    },
                ]}
                rows={leaderboard.results.map((row, i) => {
                    const fTiB = formatTiB(+row.estimatedPlotSizeTiB);

                    return [
                        `#${i + 1}`,
                        <a href="/" onClick={onNavigateToAddress} data-hash={row.accountDisplayName || row.launcherId}>{row.accountDisplayName || row.launcherId}</a>,
                        `${new Intl.NumberFormat(undefined, { minimumFractionDigits: 3 }).format(fTiB.value)} ${fTiB.unit}`,
                        row.lastDifficulty,
                        new Intl.NumberFormat().format(row.pendingPoints),
                        getAge(row.joinDateTimeUtc),
                    ];
                })}
                headerLine={true}
                rowLine={true}
            />
        </div>
    );
};

