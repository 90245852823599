import { t, Trans } from "@lingui/macro";
import classNames from 'classnames';
import { useSelector } from "react-redux";
import { RootState } from "../../index.reducers";
import { InfoIcon } from "../../info-icon/info-icon";
import './partials-dashboard.scss';

export const PartialsDashboard = (props: { diff: number, numHarvesters: number, hasCustomDifficulty: boolean }) => {
    const stats = useSelector((state: RootState) => state.account.stats);

    if (!stats) {
        return null;
    }

    const last24Hrs = stats.slice(0, 24);
    let numInvalid = 0;
    let numValid = 0;
    let numStale = 0;
    let numPartials = 0;

    for (let i = 0; i < last24Hrs.length; i++) {
        const stat = last24Hrs[i];
        numInvalid += stat.numPartialsInvalid;
        numValid += stat.numPartials - stat.numPartialsInvalid - stat.numPartialsStale;
        numStale += stat.numPartialsStale;
        numPartials += stat.numPartials;
    }

    const stalePerc = numStale ? Math.max(0.01, numStale / numPartials) : 0;

    return (
        <div className='partials-dashboard'>
            <div className='stats'>
                <div>
                    <div><Trans>Current Difficulty</Trans></div>
                    <div className='value spacer'>{Intl.NumberFormat(undefined, { maximumFractionDigits: 0 }).format(props.diff)}</div>{props.hasCustomDifficulty ? <span className="warn">STATIC DIFFICULTY</span> : null}
                </div>
                <div>
                    <div><Trans>Harvesters</Trans></div>
                    <div className='value spacer'>{Intl.NumberFormat(undefined, { maximumFractionDigits: 0 }).format(props.numHarvesters)}</div>
                </div>
                <div>
                    <div><Trans>Valid Partials</Trans></div>
                    <div className='value good'>{Intl.NumberFormat(undefined, { style: 'percent', maximumFractionDigits: 0 }).format((numStale || numInvalid) ? Math.min(0.99, numValid / numPartials) : 1)}</div><span className="count">{numValid} / {numPartials}</span>
                </div>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div><Trans>Stale Partials</Trans></div>
                        <InfoIcon tooltip={t`Stale Partials are valid-looking partials that were not credited to you due to various reasons such as being received too late. If the number of stale partials is below 2%, there is no cause for concern and does not require immediate action.`} />
                    </div>
                    <div className={classNames(`value stale`, stalePerc >= 0.03 && 'bad', stalePerc !== 0 && 'warn')}>{Intl.NumberFormat(undefined, { style: 'percent', maximumFractionDigits: 0 }).format(stalePerc)}</div><span className="count">{numStale} / {numPartials}</span>
                </div>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div><Trans>Invalid Partials</Trans></div>
                        <InfoIcon tooltip={t`Invalid Partials are partials that do not adhere to the official pooling protocol specification and thus do not get credited. This is a strong indicator that there is an issue with your farm setup; this number should be zero for any correctly configured farmer.`} />
                    </div>
                    <div className={classNames(`value invalid`, numInvalid > 0 && ' bad')}>{Intl.NumberFormat(undefined, { style: 'percent', maximumFractionDigits: 0 }).format(numInvalid ? Math.max(0.01, numInvalid / numPartials) : 0)}</div><span className="count">{numInvalid} / {numPartials}</span>
                </div>
            </div>
        </div>
    );
};
