import { Trans } from '@lingui/macro';
import QRCode from 'qrcode.react';
import React from "react";
import Modal from 'react-bootstrap/Modal';
import './qrcode-modal.scss';

export interface IQRCodeModalProps {
    launcherId: string;
    show: boolean;
    onHide: () => void;
}

export const QRCodeModal = (props: IQRCodeModalProps) => {
    // const handleQRCodeCopyImage = () => {
    //     const canvas = document.querySelector('.account-page-qrcode-modal canvas') as HTMLCanvasElement;
    //     canvas?.toBlob(function (blob) {
    //         var clipboardData = {};
    //         clipboardData["image/png"] = blob;
    //         var clipboardItem = new window['ClipboardItem'](clipboardData);
    //         navigator.clipboard['write']([clipboardItem]);
    //         handleQRModalHide();
    //     });
    // }

    // const handleQRCodeCopyText = () => {
    //     const canvas = document.querySelector('.account-page-qrcode-modal canvas') as HTMLCanvasElement;
    //     canvas?.toBlob(function (blob) {
    //         var clipboardData = {};
    //         clipboardData["text/plain"] = new Blob([farmStats.launcherId], { type: 'text/plain' });
    //         var clipboardItem = new window['ClipboardItem'](clipboardData);
    //         navigator.clipboard['write']([clipboardItem]);
    //         handleQRModalHide();
    //     });
    // }

    return <Modal className='qrcode-modal' centered show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton>
            <Modal.Title><Trans>Farm Statistics URL</Trans></Modal.Title>
        </Modal.Header>

        <Modal.Body className="qrcode-modal-body">
            <div className="title">{props.launcherId}</div>
            <div className="code">
                <QRCode
                    value={`${document.location.origin}/account/${props.launcherId}`}
                    size={128}
                    bgColor={"#ffffff"}
                    fgColor={"#000000"}
                    level={"L"}
                    includeMargin={false}
                    renderAs={"canvas"}
                    imageSettings={{
                        src: "/favicon.ico",
                        x: null,
                        y: null,
                        height: 24,
                        width: 24,
                        excavate: false,
                    }}
                />
            </div>
        </Modal.Body>

        <Modal.Footer className='qrcode-modal-footer'>
            {/* <Button variant="secondary" onClick={handleQRCodeCopyText}>
                <Trans>Copy as text</Trans>
            </Button>
            <Button variant="primary" onClick={handleQRCodeCopyImage}>
                <Trans>Copy as image</Trans>
            </Button> */}
        </Modal.Footer>
    </Modal>
};