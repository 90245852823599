import { t } from '@lingui/macro';

export const formatNumber = (value?: number) => {
    if (value === undefined) {
        return '';
    } else if (value > 0 && value < 0.0001) {
        return value.toExponential(2);
    } else {
        return value.toFixed(4);
    }
}

export const timeToWin = (value: number) => {
    if (value < 240) {
        var v = Math.ceil(+value.toFixed(0));
        return v === 1 ? t`1 second` : t`${v} seconds`;
    } else if (value < 3600 * 3) {
        var v = Math.ceil(+(value / 60).toFixed(0));
        return v === 1 ? t`1 minute` : t`${v} mins`;
    } else if (value < 86400 * 3) {
        var v = Math.ceil(+(value / 3600).toFixed(0));
        return v === 1 ? t`1 hour` : t`${v} hours`;
    } else {
        var v = Math.ceil(+((value / 86400).toFixed(0)));
        return t`${v} days`;
    }
}