import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import moment from 'moment';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { RootState } from '../../index.reducers';


interface Props {
    range: '2d' | '7d' | '30d'
}

export const NetspaceChart = (props: Props) => {
    const darkMode = useSelector((state: RootState) => state.topLevel.darkMode);
    const { poolStats, history } = useSelector((state: RootState) => state.poolStats);
    const [range, setRange] = useState(props.range);

    let data: { sortKey: number; date: string, na: number, eu: number, asia: number, global: number }[] = [];
    if (poolStats) {
        let i = 0;
        const mod = ((poolStats.recentStats.results.length / 50) | 0) || 1;
        for (const entry of poolStats.recentStats.results) {
            if (i++ % mod !== 0) {
                continue;
            }
            const date = moment.utc(entry.date);
            data.push({
                sortKey: date.valueOf(),
                date: date.local().format('YYYY/MM/DD HH:mm:ss'),
                asia: +(entry.asiaPoolNetSpaceTiB / 1024).toFixed(3),
                eu: +(entry.euPoolNetSpaceTiB / 1024).toFixed(3),
                na: +(entry.naPoolNetSpaceTiB / 1024).toFixed(3),
                global: +(entry.poolNetSpaceTiB / 1024).toFixed(3),
            });
        }
        data = data.sort((a, b) => a.sortKey - b.sortKey);
    }


    let dailyStats: { sortKey: number; date: string, na: number, eu: number, asia: number, global: number }[] = [];
    if (history) {
        var i =0;
        for (var entry of history) {
            if (range === '7d') {
                if (i++ > 7) {
                    break;
                }
            }
            const date = moment.utc(entry.day);
            dailyStats.push({
                sortKey: date.valueOf(),
                date: date.local().format('YYYY/MM/DD HH:mm:ss'),
                asia: +(entry.peakAsiaPoolNetspaceTiB/ 1024).toFixed(3),
                eu: +(entry.peakEuPoolNetspaceTiB / 1024).toFixed(3),
                na: +(entry.peakNaPoolNetspaceTiB / 1024).toFixed(3),
                global: +((entry.peakAsiaPoolNetspaceTiB + entry.peakEuPoolNetspaceTiB + entry.peakNaPoolNetspaceTiB) / 1024).toFixed(3),
            });
        }
        dailyStats = dailyStats.sort((a, b) => a.sortKey - b.sortKey);
    }

    return (
        <>
        <ResponsiveContainer width="100%" height="100%" >
            <AreaChart data={range === '2d' ? data : dailyStats}>
                <defs>
                    <linearGradient id="colorAsia" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="0%" stopColor="#01b000" stopOpacity={0.5} />
                        <stop offset="100%" stopColor="gray" stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id="colorEU" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="0%" stopColor="#01b000" stopOpacity={0.35} />
                        <stop offset="100%" stopColor="#8BC936" stopOpacity={0.1} />
                    </linearGradient>
                    <linearGradient id="colorNA" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="0%" stopColor="#01b000" stopOpacity={0.5} />
                        <stop offset="100%" stopColor="#03b000" stopOpacity={0} />
                    </linearGradient>
                </defs>
                <XAxis dataKey="date" minTickGap={100} tickSize={16} tickFormatter={(date) =>
                    moment.utc(date).format(range === '2d' ? 'HH:mm' : 'MM/DD/YYYY')
                } />
                <YAxis unit=" PiB" />
                <CartesianGrid strokeDasharray="3 3" strokeOpacity="0.3" />
                <Tooltip contentStyle={{ backgroundColor: darkMode ? '#000' : '#FFF', opacity: '0.7' }}
                    content={(data) => {
                        const stats = data?.payload;
                        return stats && stats.length > 3 &&
                            <div style={{ backgroundColor: darkMode ? '#000' : '#FFF', opacity: '0.7', borderWidth: 1, borderStyle: 'solid', borderColor: darkMode ? 'white' : 'silver', padding: 20 }}>
                                <div>{moment.utc(stats[0].payload?.date).format('MM/DD HH:mm:ss')}</div>
                                <div style={{ color: 'green', marginBottom: 10 }}>{t`Global: ${stats[0].payload?.global} PiB`}</div>
                                <div style={{ color: stats[1].color }}>{t`Europe: ${stats[0].payload?.eu} PiB`}</div>
                                <div style={{ color: stats[2].color }}>{t`Asia: ${stats[0].payload?.asia} PiB`}</div>
                                <div style={{ color: stats[3].color }}>{t`Americas: ${stats[0].payload?.na} PiB`}</div>
                            </div>
                    }} />
                <Legend />
                <Area name={t`Global`} stackId="4" unit=" PiB" type="monotone" dataKey="global" stroke="green" fillOpacity={1} fill="url(#colorAsia)" />
                <Area name={t`Europe`} stackId="2" unit=" PiB" type="monotone" dataKey="eu" stroke={darkMode ? "#ACB739" : '#A73489'} fillOpacity={1} fill="transparent" />
                <Area name={t`Asia`} stackId="1" unit=" PiB" type="monotone" dataKey="asia" stroke="#5D836D" fillOpacity={1} fill="transparent" />
                <Area name={t`Americas`} stackId="3" unit=" PiB" type="monotone" dataKey="na" stroke="#03b000" fillOpacity={1} fill="transparent" />
            </AreaChart>
        </ResponsiveContainer>
        <div style={{height: 40, display:'flex', flexDirection: 'row', justifyContent:'center', color: '#03b000', cursor:'pointer'}}>
            <div style={{marginRight:25, fontWeight: range ==='2d' ? 500: 300}} onClick={()=>{setRange('2d')}}>{t`2 days`}</div>
            <div style={{marginRight:25, fontWeight: range ==='7d' ? 500: 300}} onClick={()=>{setRange('7d')}}>{t`1 week`}</div>
            <div style={{marginRight:0, fontWeight: range ==='30d' ? 500: 300}} onClick={()=>{setRange('30d')}}>{t`1 month`}</div>
        </div>
        </>
    );
}
