import { useEffect, useMemo, useState } from "react";

export const RollingNumber = (props: { value: number, decimals?: number, time?: number }) => {
    const [cur, setCur] = useState(0);
    const startTime = useMemo(() => performance.now(), [props]);

    useEffect(() => {
        const handle = requestAnimationFrame(() => {
            if (cur === props.value) {
                return;
            }

            const time = performance.now();
            const dt = time - startTime;
            const perc = dt / (props.time || 1000);

            if (perc >= 1) {
                setCur(props.value);
            } else {
                setCur(props.value * Math.sin(perc * Math.PI / 2) ** 0.5)
            }
        });

        return () => void cancelAnimationFrame(handle);
    }, [cur, props.value, props.time]);

    const digits = typeof props.decimals === 'number' ? props.decimals : 2;
    return <>
        {Intl.NumberFormat(undefined, { maximumFractionDigits: digits, minimumFractionDigits: 0 }).format(cur)}
    </>;
};