import { combineEpics, Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, exhaustMap, filter, map } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { RootState } from "../index.reducers";
import * as API from "../services/api";
import { actions, ActionsType } from "../store";

const accountDetailEpic: Epic<
    ActionsType,
    ActionsType,
    RootState,
    typeof API
> = (action$, store, { getAccountDetails }) => {
    return action$.pipe(
        filter(isActionOf(actions.accountDetailGetAction)),
        exhaustMap((action) => {
            return from(getAccountDetails(action.payload)).pipe(
                map(actions.accountDetailSetAction),
                catchError((error) => of(actions.accountDetailErrorAction(error)))
            );
        })
    );
}

export const accountPartialsEpic: Epic<
    ActionsType,
    ActionsType,
    RootState,
    typeof API
> = (action$, store, { getAccountPartials }) => {
    return action$.pipe(
        filter(isActionOf(actions.accountPartialsGetAction)),
        exhaustMap((action) => {
            return from(getAccountPartials(action.payload)).pipe(
                map(actions.accountPartialsSetAction),
                catchError((error) => of(actions.accountPartialsErrorAction(error)))
            );
        })
    );
}

export const accountRoundsEpic: Epic<
    ActionsType,
    ActionsType,
    RootState,
    typeof API
> = (action$, store, { getAccountRounds }) => {
    return action$.pipe(
        filter(isActionOf(actions.accountRoundsGetAction)),
        exhaustMap((action) => {
            return from(getAccountRounds(action.payload)).pipe(
                map(actions.accountRoundsSetAction),
                catchError((error) => of(actions.accountPartialsErrorAction(error)))
            );
        })
    );
}

export const accountUnclaimedBlocksEpic: Epic<
    ActionsType,
    ActionsType,
    RootState,
    typeof API
> = (action$, store, { getFarmerUnclaimedBlocks }) => {
    return action$.pipe(
        filter(isActionOf(actions.accountUnclaimedBlocksGetAction)),
        exhaustMap((action) => {
            return from(getFarmerUnclaimedBlocks(action.payload)).pipe(
                map(actions.accountUnclaimedBlocksSetAction),
                catchError((error) => of(actions.accountUnclaimedBlocksErrorAction(error)))
            );
        })
    );
}

export const accountBlocksEpic: Epic<
    ActionsType,
    ActionsType,
    RootState,
    typeof API
> = (action$, store, { getFarmerBlocks }) => {
    return action$.pipe(
        filter(isActionOf(actions.accountBlocksGetAction)),
        exhaustMap((action) => {
            return from(getFarmerBlocks(action.payload)).pipe(
                map(actions.accountBlocksSetAction),
                catchError((error) => of(actions.accountBlocksErrorAction(error)))
            );
        })
    );
}

export const accountTransactionsEpic: Epic<
    ActionsType,
    ActionsType,
    RootState,
    typeof API
> = (action$, store, { getAccountTransactions }) => {
    return action$.pipe(
        filter(isActionOf(actions.accountTransactionsGetAction)),
        exhaustMap((action) => {
            return from(getAccountTransactions(action.payload)).pipe(
                map(actions.accountTransactionsSetAction),
                catchError((error) => of(actions.accountTransactionsErrorAction(error)))
            );
        })
    );
}

export const accountStatsEpic: Epic<
    ActionsType,
    ActionsType,
    RootState,
    typeof API
> = (action$, store, { getAccountStats }) => {
    return action$.pipe(
        filter(isActionOf(actions.accountStatsGetAction)),
        exhaustMap((action) => {
            return from(getAccountStats(action.payload)).pipe(
                map(actions.accountStatsSetAction),
                catchError((error) => of(actions.accountTransactionsErrorAction(error)))
            );
        })
    );
}

export const accountPageEpics = combineEpics(
    accountDetailEpic,
    accountPartialsEpic,
    accountRoundsEpic,
    accountTransactionsEpic,
    accountBlocksEpic,
    accountUnclaimedBlocksEpic,
    accountStatsEpic,
);