import { Trans } from '@lingui/macro';
import './privacy-page.scss';
import BackgroundImg from '../images/background-account.png';

export const PrivacyPage = () => {
    return (
        <div className='privacy-page'>
            <h4><Trans>Privacy Statement</Trans></h4>
            <div className="terms-date">
                    June 22, 2021
                </div><br/>
            <div className='privacy-page-content'>
                <p>Privacy Statement relating to the services provided on the website https://pool.space and iOS/Android Mobile Apps ("App").</p>

                <p>The following terminology applies to these Terms and Conditions, Privacy Statement and any or all Agreements: "Farmer", “You” and “Your” refers to you, the person accessing this app and accepting the pool’s terms and conditions. "The Pool", "Pool", “Ourselves”, “We” and "Us", refers to the Chia farming pool service. Any use of the above terminology or other words in the singular, plural, capitalisation and/or he/she or they, are taken as interchangeable and therefore as referring to same.</p>

                <p>By Using the App, or otherwise providing personal information to Us, you signify your agreement to this Privacy Policy and you consent to Our using the personal information provided in accordance with this Privacy Policy. Our related Terms and Conditions set out the terms upon which we are willing to provide our services to you through the App and otherwise.</p>

                <p>WE INTEND TO USE YOUR PERSONAL INFORMATION PRIMARILY TO PROVIDE OUR SERVICES ONLY. WE WILL NEVER SELL OR RENT YOUR PERSONAL INFORMATION TO UNRELATED THIRD PARTIES.</p>

                <p>We use IP addresses and cryptocurrency wallet addresses to analyze trends, administer the site, track user actions, and gather broad demographic information for aggregate use. IP addresses are not linked to personally identifiable information. Additionally, for systems administration, detecting usage patterns and troubleshooting purposes, our web servers automatically log standard access information including browser type, access times/open mail, URL requested, and referral URL. This information is not shared with third parties and is used only within this Pool on a need-to-know basis. Any individually identifiable information related to this data will never be used in any way different to that stated above without your explicit permission.</p>

                <p>We rely on browser cookies to provide security and improve the user experience. These include theme, language selection, google analytics, and DDOS protection cookies.</p>

                <p>Our Mobile apps request access to your camera with the sole purpose of being able to scan a QR code that expedites farm verification avoiding having to manually enter login keys.</p>

                <p>PLEASE NOTE: OUR PRIVACY POLICY CHANGES FROM TIME TO TIME AND CHANGES ARE EFFECTIVE UPON POSTING. PLEASE CHECK BACK FREQUENTLY FOR UPDATES AS IT IS YOUR SOLE RESPONSIBILITY TO BE AWARE OF CHANGES.</p>
    
            </div>
        </div>
    );
};