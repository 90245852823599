import moment from "moment";
import { createReducer } from "typesafe-actions";
import AccountDetail from "../models/account-detail";
import { IAccountStatModel } from "../models/account-stats";
import { IExtraStats } from "../models/extra-stats";
import { FarmerBlockItem } from "../models/farmer-block";
import { UnclaimedFarmerBlockItem } from "../models/farmer-unclaimed-block";
import { IPartial } from "../models/partials";
import { ITransactionModel } from "../models/payouts";
import { IRound } from "../models/rounds";
import { actions, ActionsType } from "../store";

export interface AccountState {
    accountDetail?: AccountDetail;
    chartingData?: { sortKey: number; date: string, value: number, avg: number }[];
    partials?: IPartial[];
    rounds?: IRound[];
    stats?: IAccountStatModel[];
    transactions?: ITransactionModel[];
    blocks?: FarmerBlockItem[];
    unclaimedBlocks?: UnclaimedFarmerBlockItem[];

    roundsContinuationToken?: string;
}

export const accountReducer = createReducer<AccountState, ActionsType>({})
    // AccountDetail
    .handleAction(actions.accountDetailSetAction, (state, action) => {
        const data: { sortKey: number; date: string, value: number, avg: number }[] = [];
        if (action.payload.extraStatsJSON) {
            const extraStats = JSON.parse(action.payload.extraStatsJSON) as IExtraStats;
            if (extraStats?.space?.length) {
                extraStats.space.sort((a, b) => b.date - a.date);

                let avgSamplingLookback = 20;
                for (let i = 0; i < extraStats.space.length - avgSamplingLookback; i++) {
                    const entry = extraStats.space[i];

                    let avg = 0;
                    for (let j = 1; j <= avgSamplingLookback; j++) {
                        avg += extraStats.space[i + j].tib;
                    }
                    avg /= avgSamplingLookback;
                    const value = Math.max(entry.tib, (entry.tib + (entry.tib - avg) / 4));

                    data.push({
                        sortKey: entry.date,
                        date: moment.unix(entry.date).format('YYYY/MM/DD HH:mm:ss'),
                        value: +value.toFixed(3),
                        avg: +value.toFixed(3),
                    });
                }

                avgSamplingLookback = 10;
                for (let i = 0; i < data.length; i++) {
                    const entry = data[i];
                    let avg = 0;
                    for (let j = 1; j <= avgSamplingLookback; j++) {
                        avg += data[i + j]?.value || extraStats.space[i + j]?.tib || data[data.length - 1].value;
                    }
                    avg /= avgSamplingLookback;
                    entry.avg = +avg.toFixed(3);
                }
            }
        }
        data.sort((a, b) => a.sortKey - b.sortKey);

        return ({
            ...state,
            accountDetail: action.payload,
            chartingData: data,
        })
    })
    .handleAction(actions.accountDetailErrorAction, (state, action) => {
        console.error(action.payload);
        return state;
    })

    // Unclaimed Blocks
    .handleAction(actions.accountUnclaimedBlocksSetAction, (state, action) => {
        return ({
            ...state,
            unclaimedBlocks: action.payload.results,
        })
    })
    .handleAction(actions.accountUnclaimedBlocksErrorAction, (state, action) => {
        console.error(action.payload);
        return state;
    })

    // Blocks
    .handleAction(actions.accountBlocksSetAction, (state, action) => {
        return ({
            ...state,
            blocks: action.payload.results,
        })
    })
    .handleAction(actions.accountBlocksErrorAction, (state, action) => {
        console.error(action.payload);
        return state;
    })

    // Transactions
    .handleAction(actions.accountTransactionsSetAction, (state, action) => {
        return ({
            ...state,
            transactions: action.payload.results,
        })
    })
    .handleAction(actions.accountTransactionsErrorAction, (state, action) => {
        console.error(action.payload);
        return state;
    })

    // Partials
    .handleAction(actions.accountPartialsSetAction, (state, action) => {
        return ({
            ...state,
            partials: action.payload.results,
        })
    })
    .handleAction(actions.accountPartialsErrorAction, (state, action) => {
        console.error(action.payload);
        return state;
    })

    // Rounds
    .handleAction(actions.accountRoundsSetAction, (state, action) => {
        return ({
            ...state,
            rounds: action.payload.results,
            roundsContinuationToken: action.payload.continuationToken,
        })
    })
    .handleAction(actions.accountRoundsErrorAction, (state, action) => {
        console.error(action.payload);
        return state;
    })

    // Stats
    .handleAction(actions.accountStatsSetAction, (state, action) => {
        const statsValidDate = '2021-07-30T00';
        const stats = action.payload.results;

        const predownIndex = stats ? stats.findIndex(x => x.creditedDayHour < '2021-08-27T16'): -1;
        const postdownIndex = stats ? stats.findIndex(x => x.creditedDayHour < '2021-08-28T03'): -1;
        if (predownIndex >= 0 && postdownIndex >= 0) {
            const predown = stats[predownIndex];
            const postdown = stats[postdownIndex];
            const avg = (predown.balanceIncreaseInMojo + postdown.balanceIncreaseInMojo) / 2;

            const d = predownIndex - postdownIndex;
            let expectedMoment = moment.utc(postdown.creditedDayHour);
            for (let i = postdownIndex + 1; i <= predownIndex + d + 1; i++) {
                const x = stats[i];
                stats.splice(i, 0, {
                    ...x,
                    balanceIncreaseInMojo: avg,
                });
                i++;
                expectedMoment.subtract({ hours: 1 });
            }
        }

        return {
            ...state,
            stats: action.payload.results && action.payload.results.filter(x => x.creditedDayHour >= statsValidDate),
        };
    })
    .handleAction(actions.accountStatsErrorAction, (state, action) => {
        console.error(action.payload);
        return state;
    })