import { t } from '@lingui/macro';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import Top10Img from '../../images/Top10.svg';
import Top100Img from '../../images/Top100.svg';
import Top10kImg from '../../images/Top10k.svg';
import Top1kImg from '../../images/Top1k.svg';
import './badges.scss';

import TenureMonth1Img from '../../images/TenureMonth1.svg';
import TenureMonth18Img from '../../images/TenureMonth18.svg';
import TenureMonth3Img from '../../images/TenureMonth3.svg';
import TenureMonth6Img from '../../images/TenureMonth6.svg';
import TenureWeek1Img from '../../images/TenureWeek1.svg';
import TenureWeek2Img from '../../images/TenureWeek2.svg';
import TenureWeek3Img from '../../images/TenureWeek3.svg';
import TenureYear1Img from '../../images/TenureYear1.svg';
import TenureYear2Img from '../../images/TenureYear2.svg';

import Fish1Img from '../../images/Fish1.svg';
import Fish2Img from '../../images/Fish2.svg';
import Fish3Img from '../../images/Fish3.svg';
import Fish4Img from '../../images/Fish4.svg';
import Fish5Img from '../../images/Fish5.svg';

import Pool1Anniversary from '../../images/Pool1Anniversary.svg';
import Halving from '../../images/Halving.svg'

import DeveloperImg from '../../images/Developer.svg';
import EarlyAdopterImg from '../../images/EarlyAdopter.svg';
import HelperImg from '../../images/Helper.svg';
import ModeratorImg from '../../images/Moderator.svg';
import TranslatorImg from '../../images/Translator.svg';

export const Badges = (props: { size: number; tenureDate: string; rank: number; badges: string[] }) => {
    if (!props.badges) {
        return <></>;
    }

    var rankImg = '';
    var rankLabel = '';
    if (props.rank > 0) {
        if (props.rank <= 10) {
            rankImg = Top10Img;
            rankLabel = t`Top 10 rank`;
        } else if (props.rank <= 100) {
            rankImg = Top100Img;
            rankLabel = t`Top 100 rank`;
        } else if (props.rank <= 1000) {
            rankImg = Top1kImg;
            rankLabel = t`Top 1K rank`;
        } else if (props.rank <= 10000) {
            rankImg = Top10kImg;
            rankLabel = t`Top 10K rank`;
        }
    }

    var fishImg = '';
    var fishLabel = '';
    if (props.size >= 10) {
        fishImg = Fish1Img;
        fishLabel = t`10+ TiB`
    }
    if (props.size >= 100) {
        fishImg = Fish2Img;
        fishLabel = t`100+ TiB`
    }
    if (props.size >= 500) {
        fishImg = Fish3Img;
        fishLabel = t`500+ TiB`
    }
    if (props.size >= 1024) {
        fishImg = Fish4Img;
        fishLabel = t`1+ PiB`
    }
    if (props.size >= 10240) {
        fishImg = Fish5Img;
        fishLabel = t`10+ PiB`
    }

    var tenureImg = '';
    var tenureLabel = '';
    if (props.badges.indexOf('TenureWeek1') >= 0) {
        tenureImg = TenureWeek1Img;
        tenureLabel = t`1+ week tenure`
    }

    if (props.badges.indexOf('TenureWeek2') >= 0) {
        tenureImg = TenureWeek2Img;
        tenureLabel = t`2+ week tenure`
    }

    if (props.badges.indexOf('TenureWeek3') >= 0) {
        tenureImg = TenureWeek3Img;
        tenureLabel = t`3+ week tenure`
    }

    if (props.badges.indexOf('TenureMonth1') >= 0) {
        tenureImg = TenureMonth1Img;
        tenureLabel = t`1+ month tenure`;
    }

    if (props.badges.indexOf('TenureMonth3') >= 0) {
        tenureImg = TenureMonth3Img;
        tenureLabel = t`3+ month tenure`;
    }

    if (props.badges.indexOf('TenureMonth6') >= 0) {
        tenureImg = TenureMonth6Img;
        tenureLabel = t`6+ month tenure`;
    }

    if (props.badges.indexOf('TenureYear1') >= 0) {
        tenureImg = TenureYear1Img;
        tenureLabel = t`1 year tenure`;
    }

    if (props.badges.indexOf('TenureMonth18') >= 0) {
        tenureImg = TenureMonth18Img;
        tenureLabel = t`1.5+ years tenure`;
    }

    if (props.badges.indexOf('TenureYear2') >= 0) {
        tenureImg = TenureYear2Img;
        tenureLabel = t`2+ years tenure`;
    }

    var earlyAdopterImg = props.badges.indexOf('EarlyAdopter') >= 0 ? EarlyAdopterImg : '';
    var earlyAdopterLabel = t`Early Adopter`;
    var moderatorImg = props.badges.indexOf('Moderator') >= 0 ? ModeratorImg : '';
    var moderatorLabel = t`Mission Control`;
    var helperImg = props.badges.indexOf('Helper') >= 0 ? HelperImg : '';
    var helperLabel = t`Community Helper`;
    var translatorImg = props.badges.indexOf('Translator') >= 0 ? TranslatorImg : '';
    var translatorLabel = t`Translator`;
    var developerImg = props.badges.indexOf('Developer') >= 0 ? DeveloperImg : '';
    var developerLabel = t`Developer API Consumer`;

    var poolFirstAnniversaryImg = new Date(props.tenureDate) < new Date('7/15/2022');
    var poolFirstAnniversaryLabel = t`Year 1 Member`;

    var halvingImg = new Date(props.tenureDate) < new Date('3/7/2024');
    var halvingLabel = t`Halving Survivor`;

    return (
        <span className="badges">
            <OverlayTrigger
                trigger="click"
                key="badges-box-popver"
                placement={"bottom"}
                rootClose
                overlay={
                    <Popover id={`popover-badges-positioned-${"bottom"}`}>
                        <Popover.Content>
                            <div className="badges-popover">
                                <br />
                                {earlyAdopterImg && <div><img src={earlyAdopterImg} alt={earlyAdopterLabel} title={earlyAdopterLabel} /><span>{earlyAdopterLabel}</span></div>}
                                {rankImg && <div><img src={rankImg} alt={rankLabel} title={rankLabel} /><span>{rankLabel}</span><br /><br /></div>}
                                {tenureImg && <div><img src={tenureImg} alt={tenureLabel} title={tenureLabel} /><span>{tenureLabel}</span></div>}
                                {fishImg && <div><img src={fishImg} alt={fishLabel} title={fishLabel} /><span>{fishLabel}</span></div>}
                                {moderatorImg && <div><img src={moderatorImg} alt={moderatorImg} title={moderatorLabel} /><span>{moderatorLabel}</span></div>}
                                {helperImg && <div><img src={helperImg} alt={helperLabel} title={helperLabel} /><span>{helperLabel}</span></div>}
                                {translatorImg && <div><img src={translatorImg} alt={translatorLabel} title={translatorLabel} /><span>{translatorLabel}</span></div>}
                                {developerImg && <div><img src={developerImg} alt={developerLabel} title={developerLabel} /><span>{developerLabel}</span></div>}
                                {poolFirstAnniversaryImg && <div><img src={Pool1Anniversary} alt={poolFirstAnniversaryLabel} title={poolFirstAnniversaryLabel} /><span>{poolFirstAnniversaryLabel}</span></div>}
                                {halvingImg && <div><img src={Halving} alt={halvingLabel} title={halvingLabel} /><span>{halvingLabel}</span></div>}
                            </div>
                        </Popover.Content>
                    </Popover>
                }
            >
                <div style={{ display: 'inline' }}>
                    {earlyAdopterImg && <img src={earlyAdopterImg} alt={earlyAdopterLabel} title={earlyAdopterLabel} />}
                    {rankImg && <img src={rankImg} alt={rankLabel} title={rankLabel} />}
                    {tenureImg && <img src={tenureImg} alt={tenureLabel} title={tenureLabel} />}
                    {fishImg && <img src={fishImg} alt={fishLabel} title={fishLabel} />}
                    {moderatorImg && <img src={moderatorImg} alt={moderatorLabel} title={moderatorLabel} />}
                    {helperImg && <img src={helperImg} alt={helperLabel} title={helperLabel} />}
                    {translatorImg && <img src={translatorImg} alt={translatorLabel} title={translatorLabel} />}
                    {developerImg && <img src={developerImg} alt={developerLabel} title={developerLabel} />}
                    {poolFirstAnniversaryImg && <img src={Pool1Anniversary} alt={poolFirstAnniversaryLabel} title={poolFirstAnniversaryLabel} />}
                    {halvingImg && <img src={Halving} alt={halvingLabel} title={halvingLabel} />}
                </div>
            </OverlayTrigger>
        </span>);
};