import { t, Trans } from '@lingui/macro';
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { SpacePoolTable } from '../components/spacepool-table/spacepool-table';
import { madMaxWinnerIdentity } from '../constants';
import { RootState } from "../index.reducers";
import { actions } from "../store";
import { handleMobileAppNavigation } from '../utils/navigator-util';
import './blocks-page.scss';

interface Props {
    totalBlocks: number;
    autoRefresh?: boolean;
    autoRefreshValueInSecs?: number;
}

export const BlocksPage = (props: Props) => {
    const { accountId } = useParams<{ accountId: string }>();
    const dispatch = useDispatch();
    const getData = () => {
        dispatch(actions.accountBlocksGetAction({ launcherId: accountId }));
    };

    useEffect(() => {
        getData();
        if (props.autoRefresh) {
            const interval = setInterval(getData, (props.autoRefreshValueInSecs || 60) * 1000);
            return () => clearInterval(interval);
        }
    }, [dispatch, accountId, props.autoRefresh]);

    const { blocks } = useSelector((state: RootState) => state.account);

    if (!blocks) {
        return null;
    }

    if (blocks.length === 0) {
        return <div><Trans>You haven't won any blocks yet.</Trans></div>
    } else {
        var title = t`You have found ${blocks.length} blocks`;
        if (blocks.length === 1) {
            title = t`You have found 1 block`;
        } else if (props.totalBlocks > 50) {
            title = t`Last 50 blocks found (total ${props.totalBlocks})`;
        }
        return (
            <div className='blocks-page'>
                <div className='section-header'><Trans>{title}</Trans></div>
                <br/><div><Trans>According to the Chia pooling protocol, 1/8 of the block reward is given directly to the farmer who finds a block. This reward does not go through Space Pool. If you can't find it, refer to this <a onClick={(ev: any) => handleMobileAppNavigation(ev)} target='_blank' rel="noreferrer" href='https://blog.pool.space/how-to-find-your-0-25xch-after-winning-a-block-in-the-pool-fc61d0bee5c5'>post</a>.</Trans></div><br/>
                <SpacePoolTable
                    headerLine={true}

                    headers={[
                        {
                            label: <Trans>Date</Trans>,
                        },
                        {
                            label: <Trans>Block #</Trans>,
                        },
                        {
                            label: <Trans>Hash</Trans>,
                            flexible: true,
                        }
                    ]}
                    rows={
                        blocks.map((row, i) => [
                            Intl.DateTimeFormat(undefined, { dateStyle: 'short', timeStyle: 'short' }).format(new Date(row.dateTimeUtc)),
                            row.winnerIdentity !== madMaxWinnerIdentity ? <span><a target='_blank' onClick={(ev: any) => handleMobileAppNavigation(ev)} href={`https://xch.space/blocks/${row.blockNumber}`}>{row.blockNumber}</a></span> :
                                <span> <a target='_blank' onClick={(ev: any) => handleMobileAppNavigation(ev)} href={`https://xch.space/blocks/${row.blockNumber}`}>{row.blockNumber}</a>
                                    <OverlayTrigger
                                        trigger="click"
                                        key="gigahorse-box-popver"
                                        placement={"bottom"}
                                        rootClose
                                        overlay={
                                            <Popover id={`gigahorse-positioned-${"bottom"}`}>
                                                <Popover.Content>
                                                    <div>
                                                        <Trans>This farmer block reward was claimed by Gigahorse as a fee. See <a href="https://github.com/madMAx43v3r/chia-gigahorse">madMAx43v3r's GitHub page</a> for more information about Gigahorse fees.</Trans>
                                                    </div>
                                                </Popover.Content>
                                            </Popover>
                                        }>
                                        <span style={{ cursor: 'pointer' }}> 🐎</span>
                                    </OverlayTrigger></span>,
                            row.headerHash
                        ])
                    }
                />
            </div>
        );
    }
};

