import { combineEpics, Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, exhaustMap, filter, map } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { RootState } from "../../index.reducers";
import * as API from "../../services/api";
import { actions, ActionsType } from "../../store";


const _poolStatsEpic: Epic<
    ActionsType,
    ActionsType,
    RootState,
    typeof API
> = (action$, store, { getPoolStats }) => {
    return action$.pipe(
        filter(isActionOf(actions.poolStatsGetAction)),
        exhaustMap((action) => {
            return from(getPoolStats()).pipe(
                map(actions.poolStatsSetAction),
                catchError((error) => of(actions.poolStatsErrorAction(error)))
            );
        }),
    );
}

const poolStatsHistoryEpic: Epic<
    ActionsType,
    ActionsType,
    RootState,
    typeof API
> = (action$, store, { getPoolStatsHistory }) => {
    return action$.pipe(
        filter(isActionOf(actions.poolStatHistoryGetAction)),
        exhaustMap((action) => {
            return from(getPoolStatsHistory()).pipe(
                map(x => actions.poolStatHistorySetAction(x.results)),
                catchError((error) => of(actions.poolStatsErrorAction(error)))
            );
        }),
    );
}

export const poolStatsEpic = combineEpics(
    _poolStatsEpic,
    poolStatsHistoryEpic,
);