import { t, Trans } from '@lingui/macro';
import classNames from 'classnames';
import moment from 'moment';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { SpacePoolTable } from '../components/spacepool-table/spacepool-table';
import { InfoIcon } from '../info-icon/info-icon';
import './discount-page.scss';

export const DiscountPage = (props: { baseFee?: number, rank: number, tenureStartDate: string }) => {
    let rankP = 0;
    let rankDiscount = 0;
    let baseFee = 1;
    let customBaseFee = false;

    if (props.baseFee) {
        baseFee = props.baseFee;
        customBaseFee = true;
    }

    if (props.rank > 0) {
        if (props.rank > 10000) {
            rankP = (1 - (props.rank / 50000)) * 0.3;
        } else if (props.rank > 1000) {
            rankP = (1 - (props.rank / 10000)) * 0.35 + 0.3;
            rankDiscount = 0.03;
        } else if (props.rank > 100) {
            rankP = (1 - (props.rank / 1000)) * 0.2 + 0.65;
            rankDiscount = 0.05;
        } else if (props.rank > 10) {
            rankP = (1 - (props.rank / 100)) * 0.15 + 0.85;
            rankDiscount = 0.07;
        } else {
            rankP = 0.995;
            rankDiscount = 0.1;
        }
    }

    const tenureDays = (moment().valueOf() - moment.utc(props.tenureStartDate).valueOf()) / 1000 / 60 / 60 / 24;
    const tenureDiscount = +(Math.min(1, tenureDays / 730) * 0.15).toFixed(4);

    return (
        <div className='discount-page'>
            <_DiscountProgressBar
                header={<Trans>Rank Discount: <span className='accent-color'>{(rankDiscount * 100).toFixed(2)}%</span></Trans>}
                headerExplanation={t`Discounts up to 10% based on rank (by PPLNS points) for a given round. Your rank is ${props.rank}. Note that your rank will fluctuate between block rewards, the rank shown here is your rank from the most recent block reward.`}
                progress={rankP}
                valueLabel={props.rank > 0 ? Intl.NumberFormat().format(props.rank) : t`N/A`}
                breakpoints={[{ p: 0.3, label: 10000 }, { p: 0.65, label: 1000 }, { p: 0.85, label: 100 }, { p: 0.995, label: 10 }]}
            />

            <_DiscountProgressBar
                header={<Trans>Tenure Discount: <span className='accent-color'>{(tenureDiscount * 100).toFixed(2)}%</span></Trans>}
                headerExplanation={t`Discounts up to 15% based on how long you have been with Space Pool. Your tenure start date is ${Intl.DateTimeFormat().format(new Date(props.tenureStartDate))}.`}
                progress={tenureDays / 730}
                valueLabel={<Trans>{tenureDays.toFixed(1)} days</Trans>}
                breakpoints={[{ p: 0.995, label: 730 }]}
            />

            <SpacePoolTable
                headers={[
                    {
                        label: 'Summary',
                    },
                    {
                        label: <div>&nbsp;</div>,
                        extraClassName: 'value-column',
                    }
                ]}

                rows={[
                    ['Base Fee', <div style={{ display: 'flex', justifyContent: 'right' }}>{`${(baseFee.toFixed(2))}%`} {customBaseFee && <InfoIcon tooltip={t`This account owner has chosen to use a custom pool fee. Check account settings under the advanced tab for more details.`} />}</div>],
                    ['Rank', <span className='accent-color'>{`-${rankDiscount}%`}</span>],
                    ['Tenure', <span className='accent-color'>{`-${tenureDiscount}%`}</span>],
                    ['Your Fee', <span className={classNames('total-fee')}>{(baseFee - (rankDiscount + tenureDiscount)).toFixed(4)}%</span>]
                ]}
            />
        </div>
    );
};

const _DiscountProgressBar = (props: { header: any; headerExplanation: string; valueLabel: any; progress: number; breakpoints: any[]; }) => {
    return (
        <div className='discount-progress-bar'>
            <div className='header-container'>
                <div className='header-label'>{props.header}</div>
                <OverlayTrigger
                    trigger="click"
                    key={"discount-explanation-" + Math.random()}
                    placement={"bottom"}
                    rootClose
                    overlay={
                        <Popover id={`popover-positioned-${"bottom"}`}>
                            <Popover.Content>
                                <div className="pending-content">
                                    {props.headerExplanation}
                                </div>
                            </Popover.Content>
                        </Popover>
                    }
                >
                    <div className="pending-info">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                        </svg>
                    </div>
                </OverlayTrigger>
            </div>

            <div className='progress-container'>
                <div className='fill' style={{ width: Math.min(100, props.progress * 100) + '%' }}>
                    <div className='progress-pip'>
                        <div className={classNames('progress-label', props.progress > 0.5 && 'over-half')}><Trans>You: {props.valueLabel}</Trans></div>
                    </div>
                </div>
                {
                    props.breakpoints.map((x, i) =>
                        <div key={i} className="breakpoint" style={{ left: (x.p * 100) + '%' }}>
                            <div className={classNames('breakpoint-label', x.p <= props.progress && 'reached')}>{Intl.NumberFormat().format(x.label)}</div>
                        </div>
                    )
                }
            </div>
        </div>
    );
};