import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { RootState } from '../../index.reducers';
import { SpacePoolTable } from '../spacepool-table/spacepool-table';
import './recent-share-stats.scss';

export const RecentShareStats = (props: { launcherId: string }) => {
    const { rounds } = useSelector((state: RootState) => state.account);

    if (!rounds) {
        return <div />;
    }

    const stats = rounds.sort((a, b) => b.block - a.block).slice(0, 50);
    return (
        <div className='recent-share-stats'>
            <SpacePoolTable
                headerLine={true}
                rowLine={true}
                headers={[
                    { label: <Trans>Block #</Trans> },
                    { label: <Trans>Time</Trans>, extraClassName: 'hide-for-space' },
                    { label: <Trans>Reward (XCH)</Trans> },
                    { label: <Trans>Block Reward %</Trans>, extraClassName: 'hide-for-space' },
                    { label: <Trans>Fees (XCH)</Trans>, extraClassName: 'hide-for-space' },
                    { label: <Trans>Fee %</Trans> },
                ]}
                rows={stats.map((row, i) => [
                    <div className={classNames(row.isWinner && 'winner')}>{row.block}</div>,
                    new Intl.DateTimeFormat(undefined, { dateStyle: 'short', timeStyle: 'short' }).format(new Date(row.creditedDate)),
                    new Intl.NumberFormat(undefined, { minimumSignificantDigits: 2, minimumFractionDigits: 6, maximumSignificantDigits: 5 }).format(+row.mojo / 10 ** 12),
                    `${(row.share * 100).toFixed(6)}%`,
                    new Intl.NumberFormat(undefined, { minimumFractionDigits: 6, maximumSignificantDigits: 5 }).format(+row.feesWithheldInMojo / 10 ** 12),
                    `${Intl.NumberFormat(undefined, { style: 'percent', maximumFractionDigits: 4 }).format((row.feesWithheldInMojo / (row.mojo + row.feesWithheldInMojo)))}`,
                ])}
            />
        </div>
    );
};