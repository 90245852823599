import AccountDetail from "../models/account-detail";
import { IAccountStatsResp } from "../models/account-stats";
import FarmerBlocks from "../models/farmer-block";
import UnclaimedFarmerBlocks from "../models/farmer-unclaimed-block";
import Leaderboard from '../models/leaderboard';
import { PartialsResp } from "../models/partials";
import { ITransactionsResp } from "../models/payouts";
import PoolStats, { IPoolStatHistory } from '../models/pool-stats';
import PoolHistory from "../models/PoolHistory";
import quote from "../models/quote";
import RecentlyFarmed from "../models/recently-farmed";
import { RoundsResp } from "../models/rounds";

export const getAPIBaseUrl = (): string => {
    return 'https://api-mainnet.pool.space';
}

export const getAccountAPIBaseUrl = (): string => {
    return 'https://api-account.pool.space';
}

export const getFarmerBlocks = (payload: { launcherId: string }): Promise<FarmerBlocks> => {
    return fetch(
        `${getAPIBaseUrl()}/api/farms/${payload.launcherId}/blocks`
    ).then((response) => response.json());
};

export const getFarmerUnclaimedBlocks = (payload: { launcherId: string }): Promise<UnclaimedFarmerBlocks> => {
    return fetch(
        `${getAPIBaseUrl()}/api/farms/${payload.launcherId}/unclaimedblocks`
    ).then((response) => response.json());
};

export const getRecentlyFarmed = (payload: { continuationToken }): Promise<RecentlyFarmed> => {
    return fetch(
        `${getAPIBaseUrl()}/api/rounds`
        + (payload.continuationToken ? `?pageSize=100` : '')
    ).then((response) => response.json());
};

export const getQuote = (): Promise<quote> => {
    return fetch(
        `${getAPIBaseUrl()}/api/quote`
    ).then((response) => response.json());
};

export const getPoolStats = (): Promise<PoolStats> => {
    return fetch(
        `${getAPIBaseUrl()}/api/pool`
    ).then((response) => response.json());
};

export const getPoolStatsHistory = (): Promise<{ results: IPoolStatHistory[] }> => {
    return fetch(
        `${getAPIBaseUrl()}/api/pool/history`
    ).then((response) => response.json());
};

export const getAccountDetails = (payload: { launcherId: string }): Promise<AccountDetail> => {
    return fetch(
        `${getAPIBaseUrl()}/api/farms/${payload.launcherId}`
    ).then((response) => response.json());
};

export const getAccountTransactions = (payload: { launcherId: string }): Promise<ITransactionsResp> => {
    return fetch(
        `${getAPIBaseUrl()}/api/farms/${payload.launcherId}/transactions`
    ).then((response) => response.json());
}

export const getAccountStats = (payload: { launcherId: string }): Promise<IAccountStatsResp> => {
    return fetch(
        `${getAPIBaseUrl()}/api/farms/${payload.launcherId}/stats`
    ).then((response) => response.json());
}

export const getAccountPartials = (payload: { launcherId: string }): Promise<PartialsResp> => {
    return fetch(
        `${getAPIBaseUrl()}/api/farms/${payload.launcherId}/partials`
    ).then((response) => response.json());
}

export const getAccountRounds = (payload: { launcherId: string }): Promise<RoundsResp> => {
    return fetch(
        `${getAPIBaseUrl()}/api/farms/${payload.launcherId}/rounds`
    ).then((response) => response.json());
}

export const getLeaderboard = (pageSize?: number): Promise<Leaderboard> => {
    return fetch(
        `${getAPIBaseUrl()}/api/farms${typeof pageSize === 'number' ? pageSize : ''}`
    ).then((response) => response.json());
};

export const getPoolHistory = (): Promise<PoolHistory> => {
    return fetch(
        `${getAPIBaseUrl()}/api/pool/history`
    ).then((response) => response.json());
};

export const postAccountAlias = (launcherId: string, loginToken: string, alias: string): Promise<boolean> => {
    return fetch(
        `${getAPIBaseUrl()}/api/account/${launcherId}/alias`,
        {
            method: 'POST',
            body: JSON.stringify({
                alias,
                loginToken,
            }),
        }
    ).then((response) => response.json()).then(resp => !!resp.success);
};
