import { Epic } from "redux-observable";
import { from, of } from "rxjs";
import { exhaustMap, filter, map, catchError } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";

import { RootState } from "../../index.reducers";
import { actions, ActionsType } from "../../store";
import * as API from "../../services/api";

export const recentlyFarmedEpic: Epic<
  ActionsType,
  ActionsType,
  RootState,
  typeof API
> = (action$, store, { getRecentlyFarmed }) =>
  action$.pipe(
    filter(isActionOf(actions.recentlyFarmedGetAction)),
    exhaustMap((action) =>
      from(getRecentlyFarmed(action.payload)).pipe(
        map(actions.recentlyFarmedSetAction),
        catchError((error) => of(actions.recentlyFarmedErrorAction(error)))
      )
    )
  );

export default recentlyFarmedEpic;