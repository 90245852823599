import { combineEpics, Epic } from "redux-observable";
import { EMPTY } from "rxjs";
import { filter, mergeMap } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { accountPageEpics } from './account-page/acount-page.epics';
import { RootState } from "./index.reducers";
import { chiaQuoteEpic } from "./landing-page/epics/chia-quote.epic";
import { poolStatsEpic } from './landing-page/epics/pool-stats.epic';
import { recentlyFarmedEpic } from "./landing-page/epics/recently-farmed.epic";
import leaderboardEpic from "./leaderboard-page/epics/leaderboard.epic";
import statisticsEpic from "./statistics-page/epics/statistics.epic";
import { actions, ActionsType } from "./store";
import { setCookie } from "./utils/cookie-manager";

export const darkModeCookieEpic: Epic<ActionsType, ActionsType, RootState> = action$ => {
    return action$.pipe(
        filter(isActionOf(actions.setDarkModeAction)),
        mergeMap(action => {
            setCookie('darkMode', action.payload.darkMode ? '1' : '0');
            return EMPTY;
        }),
    );
}

const epics = combineEpics(...[
    recentlyFarmedEpic,
    chiaQuoteEpic,
    poolStatsEpic,
    leaderboardEpic,
    statisticsEpic,
    accountPageEpics,
    darkModeCookieEpic,
]);

export default epics;
