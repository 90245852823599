import { t, Trans } from '@lingui/macro';
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Bar, CartesianGrid, ComposedChart, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { RootState } from "../index.reducers";
import { actions } from "../store";
import './statistics-page.scss';
import moment from 'moment';
import { LuckInfoIcon } from '../components/luck-info-icon/luck-info-icon';
import { NetspaceChart } from '../components/netspace-chart/netspace-chart';
import { MempoolChart } from '../components/mempool-chart';
import { MempoolInfoIcon } from '../components/mempool-info-icon';
import { ChiaNetspaceChart } from '../components/chia-netspace-chart';
import { MempoolWeightChart } from '../components/mempool-weight-chart';
import { MempoolWeightInfoIcon } from '../components/mempool-weight-info-icon';
  
export const StatisticsPage = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.statisticsGetAction());
        dispatch(actions.poolStatsGetAction());
        dispatch(actions.poolStatHistoryGetAction());
    }, [dispatch]);

    const darkMode = useSelector((state: RootState) => state.topLevel.darkMode);
    const { statistics } = useSelector((state: RootState) => state.statistics);

    if (!statistics || statistics?.results.length == 0) {
        return null;
    }

    var data = statistics?.results.slice().reverse();
    for (var i = 0; i<data.length; i++) {
        data[i] = Object.assign({}, data[i]);
        data[i].averageLuck = Math.floor(data[i].averageLuck * 100);
        data[i].peakXchUsd = +(data[i].peakXchUsd).toFixed(2);
        data[i].normalLuck = 100;
    }
    return (
        <div className='statistics-page'>
            <div className="title">
                <h4><Trans>Statistics</Trans></h4>
            </div>
            <h4 className="sub-title"><Trans>Pool Netspace</Trans></h4>
            <div style={{ width: "100%", height: 400, paddingBottom: '30px' }}>
                <NetspaceChart range="30d"></NetspaceChart>
            </div>
            <h4 className="sub-title"><Trans>Blocks Found</Trans></h4>
            <div style={{ width: "100%", height: 400, paddingBottom: '30px' }}>
                <span style={{ display: 'block', clear: 'both' }}></span>
                <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                    width={500}
                    height={400}
                    data={data}
                    margin={{
                        top: 20,
                        right: 20,
                        bottom: 20,
                        left: 20,
                    }}
                    >
                        <XAxis dataKey="day" minTickGap={80} tickSize={16} tickFormatter={(date) => moment.utc(date).format('MM/DD/YYYY')} />
                        <YAxis />
                        <CartesianGrid strokeDasharray="3 3" strokeOpacity="0.3" />
                        <Tooltip contentStyle={{ backgroundColor: darkMode ? '#000' : '#FFF', opacity: '0.7' }} />
                        <Legend />
                        <Bar name={t`Blocks`} unit=" Blocks" dataKey="blocksFound" fill="#03c000" />
                   </ComposedChart>
                </ResponsiveContainer>
            </div>
            <h4 className="sub-title"><span style={{float:'left', marginRight: 10}}><Trans>Pool Luck</Trans></span><LuckInfoIcon /></h4>
            <div style={{ width: "100%", height: 400, paddingBottom: '10px' }}>
                <span style={{ display: 'block', clear: 'both' }}></span>
                
                <ResponsiveContainer width="100%" height="100%">
                <LineChart
                    width={500}
                    height={400}
                    data={data}
                    margin={{
                        top: 20,
                        right: 20,
                        bottom: 20,
                        left: 20,
                    }}
                    >
                        <XAxis dataKey="day" minTickGap={80} tickSize={16} tickFormatter={(date) => moment.utc(date).format('MM/DD/YYYY')} />
                        <YAxis  />
                        <CartesianGrid strokeDasharray="3 3" strokeOpacity="0.3" />
                        <Tooltip contentStyle={{ backgroundColor: darkMode ? '#000' : '#FFF', opacity: '0.7' }} />
                        <Legend />
                        <Line type="monotone" name="100% Luck" dataKey="normalLuck" dot={<></>} stroke="#03c000"   />
                        <Line type="monotone" name={t`Day Luck`} unit=" Luck" dataKey="averageLuck" stroke={darkMode ? "#ACB739" : "#A73489"}  />
                   </LineChart>
                </ResponsiveContainer>
            </div>
            <h4 className="sub-title"><span style={{float:'left', marginRight: 10}}><Trans>Chia Mempool Size</Trans></span><MempoolInfoIcon /></h4>
            <div style={{ width: "100%", height: 400, paddingBottom: '30px' }}>
                <MempoolChart/>
            </div>
            <h4 className="sub-title"><span style={{float:'left', marginRight: 10}}><Trans>Chia Mempool Weight</Trans></span><MempoolWeightInfoIcon /></h4>
            <div style={{ width: "100%", height: 400, paddingBottom: '30px' }}>
                <MempoolWeightChart/>
            </div>
            <h4 className="sub-title"><Trans>Chia Netspace</Trans></h4>
            <div style={{ width: "100%", height: 400, paddingBottom: '30px' }}>
                <ChiaNetspaceChart></ChiaNetspaceChart>
            </div>
            <h4 className="sub-title"><Trans>XCH Price</Trans></h4>
            <div style={{ width: "100%", height: 400, paddingBottom: '10px' }}>
                <span style={{ display: 'block', clear: 'both' }}></span>
                
                <ResponsiveContainer width="100%" height="100%">
                <LineChart
                    width={500}
                    height={400}
                    data={data}
                    margin={{
                        top: 20,
                        right: 20,
                        bottom: 20,
                        left: 20,
                    }}
                    >
                        <XAxis dataKey="day" minTickGap={80} tickSize={16} tickFormatter={(date) => moment.utc(date).format('MM/DD/YYYY')} />
                        <YAxis  />
                        <CartesianGrid strokeDasharray="3 3" strokeOpacity="0.3" />
                        <Tooltip contentStyle={{ backgroundColor: darkMode ? '#000' : '#FFF', opacity: '0.7' }} />
                        <Legend />
                        <Line type="monotone" name={t`XCH USD Price`} unit=" USD" dataKey="peakXchUsd" stroke="#03c000"  />
                   </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

