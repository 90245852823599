export interface RecentlyFarmedItem {
    blockNumber: number;
    confirmed: boolean;
    dateTimeUtc: string;
    headerHash: string;
    luck: number;
    reward: number;
    winnerLauncherId: string;
    winnerIdentity: string;
}
export default class RecentlyFarmed {
    public continuationToken!: string;
    public results!: Array<RecentlyFarmedItem>;

    constructor(args?: {}) {
        Object.assign(this, args);
    }
}
