import { t } from '@lingui/macro';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { RootState } from '../../index.reducers';

export const PartialsChart = () => {
    const darkMode = useSelector((state: RootState) => state.topLevel.darkMode);
    const stats = useSelector((state: RootState) => state.account.stats);

    if (!stats) {
        return null;
    }

    const chartData: { date: string; numValid: number; numStale: number; numInvalid: number; numPoints: number; }[] = [];

    const tickKeys: string[] = [];
    const numAgg = 6;
    for (let i = stats.length - 1; i >= numAgg; i -= numAgg) {
        const data: (typeof chartData)[0] = {
            date: moment.utc(stats[i].creditedDayHour).local().format('YYYY-MM-DD HH:mm:00'),
            numInvalid: 0,
            numPoints: 0,
            numValid: 0,
            numStale: 0,
        };

        if (!tickKeys.length || moment(tickKeys[tickKeys.length - 1]).days() !== moment(data.date).days()) {
            tickKeys.push(data.date);
        }

        for (let j = 0; j < numAgg; j++) {
            const stat = stats[i - j];
            if (!stat.numPartials) {
                continue;
            }

            if (stat.creditedDayHour < '2021-08-17T12') {
                stat.numPartialsInvalid = 0;
            }
            const ppp = stat.numPoints / stat.numPartials;
            data.numInvalid += stat.numPartialsInvalid * ppp;
            data.numValid += (stat.numPartials - stat.numPartialsInvalid - stat.numPartialsStale) * ppp;
            data.numPoints += stat.numPoints * ppp;
            data.numStale += stat.numPartialsStale * ppp;
        }
        chartData.push(data);
    }

    let hasInvalids = false;
    for (const data of chartData) {
        data.numInvalid = Math.round(data.numInvalid);
        data.numValid = Math.round(data.numValid);
        data.numPoints = Math.round(data.numPoints);
        data.numStale = Math.round(data.numStale);

        hasInvalids = hasInvalids || data.numInvalid > 0;
    }
    const numFormatter = Intl.NumberFormat();
    return (
        <div style={{ width: '100%', height: '100%' }}>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart data={chartData}>
                    <XAxis dataKey="date" minTickGap={100} tickSize={16} ticks={tickKeys} tickFormatter={(date) => moment(date).format('YYYY-MM-DD')} />
                    <YAxis tickFormatter={numFormatter.format} tickMargin={-1} />
                    <CartesianGrid strokeDasharray="3 3" strokeOpacity="0.3" />
                    <Tooltip contentStyle={{ backgroundColor: darkMode ? '#000' : '#FFF', opacity: 0.7 }} formatter={numFormatter.format as any} />
                    <Legend />
                    {/* <Bar name={t`Points`} stackId='1' type="monotone" dataKey="numPoints" fill='#03c000' /> */}
                    {hasInvalids && <Bar name={t`Invalid`} stackId='1' type="monotone" dataKey="numInvalid" fill='red' />}
                    <Bar name={t`Stale`} stackId='1' type="monotone" dataKey="numStale" fill={darkMode ? 'yellow' : 'orange'} />
                    <Bar name={t`Valid`} stackId='1' type="monotone" dataKey="numValid" fill='#03c000' />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}
