import { NavDropdown } from "react-bootstrap";
import { setCookie } from "../../utils/cookie-manager";
import './currency-picker.scss';

export const currencyCookieName = 'spCurrencyKey';
export const currencies = [
    {
        key: 'ARS',
        label: 'Argentine Peso (A$)',
    },
    {
        key: 'AUD',
        label: 'Australian Dollar ($)',
    },
    {
        key: 'BRL',
        label: 'Brazilian Real (R$)',
    },
    {
        key: 'CAD',
        label: 'Canadian Dollar ($)',
    },
    {
        key: 'CHF',
        label: 'Swiss Franc (CHF)',
    },
    {
        key: 'CNY',
        label: 'Chinese Yuan (¥)',
    },
    {
        key: 'EUR',
        label: 'Euro (€)',
    },
    {
        key: 'GBP',
        symbol: '£',
        label: 'Pound Sterling (£)',
    },
    {
        key: 'HKD',
        label: 'Hong Kong Dollar ($)',
    },
    {
        key: 'IDR',
        label: 'Indonesian Rupiah (Rp)',
    },
    {
        key: 'INR',
        label: 'Indian Rupee (₹)',
    },
    {
        key: 'JPY',
        label: 'Japanese Yen (¥)',
    },
    {
        key: 'KZT',
        label: 'Kazakhstani Tenge (₸)',
    },
    {
        key: 'MXN',
        label: 'Mexican Peso ($)',
    },
    {
        key: 'MYR',
        label: 'Malaysian Ringgit (RM)',
    },
    {
        key: 'PHP',
        label: 'Philippine Peso (₱)',
    },
    {
        key: 'RUB',
        label: 'Russian Ruble (₽)',
    },
    {
        key: 'SAR',
        label: 'Saudi Arabian Riyal (﷼)',
    },
    {
        key: 'SEK',
        label: 'Swedish Krona (kr)',
    },
    {
        key: 'SGD',
        label: 'Singapore Dollar (S$)',
    },
    {
        key: 'THB',
        label: 'Thai Baht (฿)',
    },
    {
        key: 'TRY',
        label: 'Turkish Lira (₺)',
    },
    {
        key: 'TWD',
        label: 'New Taiwan Dollar (NT$)',
    },
    {
        key: 'USD',
        label: 'US Dollar ($)',
    },
];

var getCurrencyFromKey = (key: string): { key: string } => {
    for (let entry of currencies) {
        if (entry.key === key) {
            return entry;
        }
    }
    return currencies[0];
};

const onCurrencyClick = (ev: any) => {
    var newCurrency = ev.target.dataset.key;
    var currencyCookieInfo = getCurrencyFromKey(newCurrency);
    setCookie(currencyCookieName, currencyCookieInfo.key);
};

export const CurrencyPicker = (props: { value: string }) => {
    return (
        <div onClick={() => { }} style={{}}>
            <NavDropdown title={props.value} id="collasible-curr-picker-dropdown">
                {currencies && currencies.map((entry, i) =>
                    <NavDropdown.Item key={entry.key} href={document.location.href} data-key={entry.key} onClick={onCurrencyClick}>{entry.label}</NavDropdown.Item>
                )}
            </NavDropdown>
        </div>
    );

};