import { Trans } from "@lingui/macro";
import { IPartial } from "../../models/partials";
import { SpacePoolTable } from "../spacepool-table/spacepool-table";

export interface IHarvestersSummaryProps {
    partials: IPartial[];
}

export const HarvestersSummary = (props: IHarvestersSummaryProps) => {
    const harvestersMap = new Map<string, { good: number, bad: number }>();

    for (const partial of props.partials) {
        let entry = harvestersMap.get(partial.harvesterId);
        if (!entry) {
            entry = {
                bad: 0,
                good: 0,
            };
            harvestersMap.set(partial.harvesterId, entry);
        }

        partial.error ? entry.bad++ : entry.good++;
    }

    const harvesters: any[][] = [];
    for (const [harvesterId, entry] of harvestersMap) {
        harvesters.push([harvesterId, entry.good, entry.bad]);
    }

    return (
        <SpacePoolTable
            headerLine={true}

            headers={[
                {
                    label: <Trans>Harvester Id</Trans>,
                    flexible: true,
                },
                {
                    label: <Trans>Valid</Trans>,
                },
                {
                    label: <Trans>Invalid</Trans>,
                },
            ]}

            rows={harvesters}
        />
    );
};