import { Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, exhaustMap, filter, map } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { RootState } from "../../index.reducers";
import * as API from "../../services/api";
import { actions, ActionsType } from "../../store";


export const leaderboardEpic: Epic<
  ActionsType,
  ActionsType,
  RootState,
  typeof API
> = (action$, store, { getLeaderboard }) => {
  return action$.pipe(
    filter(isActionOf(actions.leaderboardGetAction)),
    exhaustMap((action) => {
      return from(getLeaderboard(action.payload.pageSize)).pipe(
        map(actions.leaderboardSetAction),
        catchError((error) => of(actions.leaderboardErrorAction(error)))
      );
    }
    )
  );
}

export default leaderboardEpic;