import { t, Trans } from '@lingui/macro';
import classNames from 'classnames';
import moment from 'moment';
import QRCode from 'qrcode.react';
import { useEffect, useState } from "react";
import { Tab, Tabs } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { IAccountSettings } from '../../models/account-settings';
import { getAccountAPIBaseUrl } from '../../services/api';
import { actions } from '../../store';
import { handleMobileAppNavigation, isEmbedMode, mobilePostMessage } from '../../utils/navigator-util';
import { CopyIcon } from '../copy-icon/copy-icon';
import { SpacePoolTable } from '../spacepool-table/spacepool-table';
import { ToggleSwitch } from '../toggle-switch/toggle-switch';
import './account-settings-modal.scss';

export interface IAccountSettingsModalProps {
    alias: string;
    joinDate: string;
    launcherId: string;
    loginToken: string;
    show: boolean;
    onHide: () => void;
}

export const AccountSettingsModal = (props: IAccountSettingsModalProps) => {
    const showQRCodeLabel = t`Show QR code`;
    const hideQRCodeLabel = t`Hide QR code`;
    const pphDefault = 18.12345; // Using decimal to determine if it was changed by the user. Tick marks have non-decimal values.
    const customFeeDefault = 1.00001; // Using arbitrary decimal value to determine if it was changed by the user
    const [settingsVersion, setSettingsVersion] = useState(1);
    const [alias, setAlias] = useState(props.alias);
    const [developerReason, setDeveloperReason] = useState('');
    const [partialsPerHour, setPartialsPerHour] = useState(pphDefault);
    const [customFee, setCustomFee] = useState(customFeeDefault);
    const [developerRequestsPerMinute, setDeveloperRequestsPerMinute] = useState(0);
    const [developerPublicUse, setDeveloperPublicUse] = useState(false);
    const [developerNumUsers, setDeveloperNumUsers] = useState(0);
    const [developerDiscordId, setDeveloperDiscordId] = useState('');
    const [discordId, setDiscordId] = useState('');
    const [discordWebhook, setDiscordWebhook] = useState('');
    const [payoutAddress, setPayoutAddress] = useState('');
    const [payoutCooldownEnabled, setPayoutCooldownEnabled] = useState(false);
    const [qrVisibilityLabel, setQrVisibilityLabel] = useState(showQRCodeLabel);
    const [minPayout, setMinPayout] = useState(0);
    const [enableDiscordNotifs, setEnableDiscordNotifs] = useState(true);
    const [enableDiscordWebhookNotifs, setEnableDiscordWebhookNotifs] = useState(true);
    const [enableMobileNotifs, setEnableMobileNotifs] = useState(true);
    const [enableFarmOfflineNotif, setEnableFarmOfflineNotif] = useState(false);
    const [enableSystemNotif, setEnableSystemNotif] = useState(false);
    const [enableInvalidPartialsNotif, setEnableInvalidPartialsNotif] = useState(false);
    const [enableBlockWinNotif, setEnableBlockWinNotif] = useState(false);
    const [enablePayoutNotif, setEnablePayoutNotif] = useState(false);
    const [loginToken, setLoginToken] = useState(props.loginToken || '');
    const [canSave, setCanSave] = useState(!!loginToken);
    const [isBusy, setIsBusy] = useState(false);
    const [validationError, setValidationError] = useState('');
    const [devAPIKey, setDevAPIKey] = useState('');
    const [validationErrorDevInfo, setValidationErrorDevInfo] = useState(false);
    const [validationErrorAlias, setValidationErrorAlias] = useState(false);
    const [validationErrorDiscordId, setValidationErrorDiscordId] = useState(false);
    const [validationErrorDiscordWebhook, setValidationErrorDiscordWebhook] = useState(false);
    const [validationErrorPayoutAddress, setValidationErrorPayoutAddress] = useState(false);
    const [validationErrorMinPayout, setValidationErrorMinPayout] = useState(false);
    const [settings, setSettings] = useState({} as IAccountSettings);
    const [invalidToken, setInvalidToken] = useState(false);
    const [redeemPromotion, setRedeemPromotion] = useState(false);
    const [enterDevInfo, setEnterDevInfo] = useState(false);
    const [showDevKey, setShowDevKey] = useState(false);
    const [selectedExportDate, setSelectedExportDate] = useState(moment.utc(props.joinDate).format('YYYY-MM'));
    const [validationDevErrorReason, setValidationDevErrorReason] = useState(false);
    const [validationDevErrorRequests, setValidationDevErrorRequests] = useState(false);
    const [validationDevErrorUsers, setValidationDevErrorUsers] = useState(false);
    const dispatch = useDispatch();

    // Partials settings
    const [origCustomDifficulty, setOrigCustomDifficulty] = useState<number>();
    const [customDifficulty, setCustomDifficulty] = useState<number>();
    const [customDifficultyPreviewSizeTib, setCustomDifficultyPreviewSizeTib] = useState(100);

    let exportOptionCurrent = moment.utc(props.joinDate).format('YYYY-MM');
    const exportOptionEnd = moment().format('YYYY-MM');

    const exportOptions: string[] = [];
    while (exportOptionCurrent <= exportOptionEnd) {
        exportOptions.push(exportOptionCurrent);
        exportOptionCurrent = moment.utc(exportOptionCurrent).add({ months: 1 }).format('YYYY-MM');
    }

    useEffect(() => {
        setAlias(props.alias);
        setLoginToken(props.loginToken);
    }, [props.alias, props.launcherId, props.show]);

    useEffect(() => {
        setInvalidToken(false);
        setRedeemPromotion(false);
        (async () => {
            const resp = await fetch(`${getAccountAPIBaseUrl()}/api/account/${props.launcherId}/settings?loginToken=${loginToken}`, {
                headers: {
                    'Cache-Control': 'no-cache',
                },
            });
            if (!resp.ok) {
                setInvalidToken(true);
                if (isEmbedMode()) {
                    mobilePostMessage(JSON.stringify(
                        {
                            navigateTo: 'Settings',
                            accountId: props.launcherId,
                            alias: props.alias,
                            loginToken: ''
                        }), '*');
                }
                return;
            }

            const settings: IAccountSettings = await resp.json();
            setSettings(settings);

            if (settings.hasDeveloperAccount && !isEmbedMode()) {
                const developerResponse = await fetch(`${getAccountAPIBaseUrl()}/api/account/${props.launcherId}/developer?loginToken=${loginToken}`, {
                    headers: {
                        'Cache-Control': 'no-cache',
                    },
                });
                if (developerResponse.ok) {
                    var key = await developerResponse.json();
                    setDevAPIKey(key.developerApiKey);
                }
            }

            if (isEmbedMode()) {
                mobilePostMessage(JSON.stringify(
                    {
                        navigateTo: 'Settings',
                        accountId: props.launcherId,
                        alias: props.alias,
                        loginToken: loginToken,
                        enableMobileNotifs: enableMobileNotifs
                    }), '*');
            }

        })();
    }, [loginToken]);

    useEffect(() => {
        setSettingsVersion(moment().valueOf());

        setDiscordId(settings.discordId || discordId);

        setDiscordWebhook(settings.discordWebhook || discordWebhook);

        setPayoutAddress(settings.payoutAddress || payoutAddress);

        const payoutCooldownHours = typeof settings.payoutCooldownHours === 'number' ? settings.payoutCooldownHours : 0;
        setPayoutCooldownEnabled((Number.isFinite(payoutCooldownHours) && payoutCooldownHours > 0) || payoutCooldownEnabled);

        const pph = typeof settings.partialsPerHour === 'number' ? settings.partialsPerHour : pphDefault;
        setPartialsPerHour(pph);

        const customDiff = settings.customDifficulty || undefined;
        setOrigCustomDifficulty(customDiff);
        setCustomDifficulty(customDiff);

        const customFee = typeof settings.customFee === 'number' ? settings.customFee : customFeeDefault;
        setCustomFee(customFee);

        setMinPayout(settings.minPayoutBalanceXch || minPayout);

        const enableDiscordFromSettings = settings.notificationSettings?.mediumSettings?.enableDiscord;
        setEnableDiscordNotifs(typeof enableDiscordFromSettings === 'boolean' ? enableDiscordFromSettings : enableDiscordNotifs);

        const enableDiscordWebhookNotifsFromSettings = settings.notificationSettings?.mediumSettings?.enableDiscordWebhook;
        setEnableDiscordWebhookNotifs(typeof enableDiscordWebhookNotifsFromSettings === 'boolean' ? enableDiscordWebhookNotifsFromSettings : enableDiscordNotifs);

        const setEnableMobileNotifsFromSettings = settings.notificationSettings?.mediumSettings?.enablePush;
        setEnableMobileNotifs(typeof setEnableMobileNotifsFromSettings === 'boolean' ? setEnableMobileNotifsFromSettings : enableMobileNotifs);

        const enableSystemNotifFromSettings = settings.notificationSettings?.typeSettings?.system;
        setEnableSystemNotif(typeof enableSystemNotifFromSettings === 'boolean' ? enableSystemNotifFromSettings : enableSystemNotif);

        const enableFarmOfflineFromSettings = settings.notificationSettings?.typeSettings?.farmOffline;
        setEnableFarmOfflineNotif(typeof enableFarmOfflineFromSettings === 'boolean' ? enableFarmOfflineFromSettings : enableFarmOfflineNotif);

        const enableInvalidPartialsNotifFromSettings = settings.notificationSettings?.typeSettings?.invalidPartials;
        setEnableInvalidPartialsNotif(typeof enableInvalidPartialsNotifFromSettings === 'boolean' ? enableInvalidPartialsNotifFromSettings : enableInvalidPartialsNotif);

        const enableBlockWinNotifFromSettings = settings.notificationSettings?.typeSettings?.blockWin;
        setEnableBlockWinNotif(typeof enableBlockWinNotifFromSettings === 'boolean' ? enableBlockWinNotifFromSettings : enableBlockWinNotif);

        const enablePayoutFromSettings = settings.notificationSettings?.typeSettings?.payout;
        setEnablePayoutNotif(typeof enablePayoutFromSettings === 'boolean' ? enablePayoutFromSettings : enablePayoutNotif);
    }, [settings]);

    useEffect(() => {
        setCanSave(!validationErrorDiscordWebhook && !validationErrorDevInfo && !validationErrorAlias && !validationErrorMinPayout &&
            !validationErrorPayoutAddress && !validationErrorDiscordId && !!loginToken);
    }, [validationErrorDiscordWebhook, validationErrorDevInfo, validationErrorAlias, validationErrorDiscordId, validationErrorMinPayout, validationErrorPayoutAddress, loginToken]);

    const handleCancelClicked = async () => {
        mobilePostMessage(JSON.stringify(
            {
                navigateTo: 'Stats',
                accountId: props.launcherId
            }), '*');
    };

    const handleSaveClicked = async () => {
        if (!loginToken) {
            return;
        }

        if (showDevKey) {
            setShowDevKey(false);
            return;
        }

        if (!isEmbedMode() && payoutAddress && settings.payoutAddress !== payoutAddress) {
            try {
                const response = window.confirm(t`Press OK to update your payout address or Cancel and select 'Test with faucet' to validate it first. Keep in mind that Payout Address can only be changed once an hour.`);
                if (response === false) {
                    return;
                }
            } catch {

            }
        }

        setIsBusy(true);

        let success = false;

        if (enterDevInfo) {
            if (!developerReason || !developerRequestsPerMinute || !developerNumUsers) {
                setValidationError(t`All the fields above are required to process your request.`);
                setIsBusy(false);
                return;
            }

            const devResult = await fetch(
                `${getAccountAPIBaseUrl()}/api/account/${props.launcherId}/developer`,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        "loginToken": loginToken,
                        "reason": developerReason,
                        "requestsPerMinute": developerRequestsPerMinute,
                        "publicUse": developerPublicUse,
                        "numUsers": developerNumUsers,
                        "discordId": developerDiscordId || discordId
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            if (!devResult.ok) {
                const json = await devResult.json();
                switch (json.error_code) {
                    case 4020:
                        setValidationError(t`Please make this request manually on discord (#troubleshooting channel) due to request requirements.`);
                }
                setIsBusy(false);
                return;
            } else {
                var resp = await devResult.json();
                setDevAPIKey(resp.developerApiKey);
                setShowDevKey(true);
                setIsBusy(false);
                setEnterDevInfo(false);
                return;
            }

        }

        for (let i = 0; i < 10; i++) {
            try {
                if (alias !== props.alias) {
                    const result = await fetch(
                        `${getAccountAPIBaseUrl()}/api/account/${props.launcherId}/alias`,
                        {
                            method: 'POST',
                            body: JSON.stringify({
                                alias,
                                loginToken: loginToken,
                            }),
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        }
                    );

                    if (!result.ok) {
                        throw 'retrying';
                    }
                }
                success = true;
                break;
            } catch {
                if (!isBusy) {
                    return;
                }
                await new Promise(resolve => {
                    setTimeout(resolve, 2500);
                });
            }
        }

        if (success) {
            const result = await fetch(
                `${getAccountAPIBaseUrl()}/api/account/${props.launcherId}/settings`,
                {
                    method: 'PUT',
                    body: JSON.stringify({
                        settings: {
                            customDifficulty,
                            discordId: discordId || '',
                            discordWebhook: discordWebhook || '',
                            minPayoutBalanceXch: isEmbedMode() ? undefined : minPayout,
                            payoutAddress: isEmbedMode() ? undefined : (payoutAddress || undefined),
                            payoutCooldownHours: isEmbedMode() ? undefined : (payoutCooldownEnabled ? 24 : 0),
                            partialsPerHour: partialsPerHour === pphDefault ? undefined : partialsPerHour,
                            customFee: customFee === customFeeDefault ? undefined : customFee,
                            notificationSettings: {
                                typeSettings: {
                                    payout: enablePayoutNotif,
                                    farmOffline: enableFarmOfflineNotif,
                                    invalidPartials: enableInvalidPartialsNotif,
                                    system: enableSystemNotif,
                                    blockWin: enableBlockWinNotif,
                                },
                                mediumSettings: {
                                    enablePush: enableMobileNotifs,
                                    enableDiscord: !!discordId && enableDiscordNotifs,
                                    enableDiscordWebhook: !!discordWebhook && enableDiscordWebhookNotifs,
                                }
                            }
                        } as IAccountSettings,
                        loginToken,
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            success = result.ok;

            if (!success) {
                const json = await result.json();
                switch (json.error_code) {
                    case 4012: // Payout instructions update too frequent
                        setValidationErrorPayoutAddress(true);
                        setValidationError(t`Payout address can only be updated once an hour.`);
                        break;
                    case 4004: // Bad login token
                        setValidationError(t`Payout related settings can only be changed from the website.`);
                        break;
                    case 4005: // Login token expired
                        setValidationError(isEmbedMode() ? t`Account linking with the app has expired. Please re-link your account with QR code.` :
                            t`Login link expired. Please retrieve a new one and try again`);
                        break;
                    case 4030: // Custom diff update error
                        setValidationError(t`Static difficulty could not be applied. If you have recently changed your static difficulty, please wait an hour before trying again. You can always change to PPH mode.`);
                        break;
                }
            }
        }

        setIsBusy(false);

        if (!success) {
            return;
        }

        if (isEmbedMode()) {
            mobilePostMessage(JSON.stringify(
                {
                    navigateTo: 'Stats',
                    accountId: props.launcherId
                }), '*');
        } else {
            props.onHide();
            dispatch(actions.accountDetailGetAction({ launcherId: props.launcherId }));
        }
    };

    const handleAliasInputChanged = async (checkAlias: string) => {
        setAlias(checkAlias);

        const resp = await fetch(
            `${getAccountAPIBaseUrl()}/api/validation/alias?checkAlias=${checkAlias}`,
        );

        if (resp.ok) {
            setValidationErrorAlias(false);
            return;
        }

        // {"error_code":4009,"error_message":"Invalid alias length"}
        const json = await resp.json();
        switch (json.error_code) {
            case 4007: // alias taken
                setValidationErrorAlias(props.alias !== checkAlias);
                break;

            case 4008: // invalid alias
            case 4009: // invalid length
                setValidationErrorAlias(true);
                break;
        }
    };

    const handlePromotionNavigation = (ev: any) => {
        isEmbedMode() ?
            handleMobileAppNavigation(ev, 'https://amzn.to/3kE2nIP')
            :
            window.location.href = "https://amzn.to/3kE2nIP"
    }

    const handleMinPayoutChanged = (amount: string) => {
        const number = parseFloat(amount);
        const isError = isNaN(number) || number < 0.01 || number > 1;
        setValidationErrorMinPayout(isError);
        setMinPayout(isNaN(number) ? 0 : number);
        if (isError) {
            setValidationError(t`Payout must be a value between 0.01 and 1`);
        } else {
            setValidationError('');
        }
    }

    const onQRVisibilityToggle = () => {
        if (qrVisibilityLabel === showQRCodeLabel) {
            setQrVisibilityLabel(hideQRCodeLabel);
        } else {
            setQrVisibilityLabel(showQRCodeLabel);
        }
    };

    const handlePayoutAddressChanged = (address: string) => {
        setValidationErrorPayoutAddress(!!address && (address.indexOf('xch') !== 0 || address.length !== 62));
        setPayoutAddress(address);
    }

    const handleDiscordIdChanged = (id: string) => {
        const idNum = +id;
        setValidationErrorDiscordId(!!id.length && (!idNum || idNum < 1000000));
        setDiscordId(id);
    };

    const handleDiscordWebhookChanged = (url: string) => {
        setValidationErrorDiscordWebhook(!!url && !url.startsWith('https://discord.com/api/webhooks/'));
        setDiscordWebhook(url)
    }

    const handleExportDownload = async () => {
        const resp = await fetch(`https://pool.space/api/account/${props.launcherId}/payouts?yearMonth=${selectedExportDate}&loginToken=${loginToken}`);
        if (resp.ok) {
            window.open(`https://pool.space/api/account/${props.launcherId}/payouts?yearMonth=${selectedExportDate}&loginToken=${loginToken}`);
        } else {
            window.alert(t`No payouts found for ${selectedExportDate}`);
        }
    };

    const handleOnHide = () => {
        setIsBusy(false);
        props.onHide();
    };

    if (invalidToken && isEmbedMode()) {
        return <></>;
    }


    return <Modal className='account-settings-modal' centered backdrop={isBusy ? 'static' : undefined} show={props.show} onHide={handleOnHide}>
        <Modal.Header>
            <Modal.Title>{
                enterDevInfo ?
                    <Trans>Developer API Key request</Trans>
                    : redeemPromotion ?
                        'Redeem Promotion' :
                        <Trans>Account Settings</Trans>}
            </Modal.Title>
        </Modal.Header>

        <Modal.Body className="account-settings-modal-body">
            {
                showDevKey ?
                    <div>
                        <Trans>Congrats! Your Developer API Key is:</Trans><br />
                        <span style={{ wordBreak: 'break-word', color: 'gray' }}>{devAPIKey}</span>
                        <CopyIcon text={devAPIKey} /> <br /><br />
                        <Trans>Hint: Click on the pad icon above to copy it to your clipboard.</Trans>
                    </div>
                    :
                    enterDevInfo ?
                        <SpacePoolTable
                            headers={[
                                {
                                    label: ' ',
                                },
                                {
                                    label: '',
                                    flexible: true,
                                    extraClassName: 'input-value',
                                },
                            ]}
                            rows={[
                                [
                                    <div className='input-label'><Trans>Reason</Trans></div>,
                                    <input
                                        key="devKeyReason"
                                        className={classNames(validationDevErrorReason ? 'validation-error' : '')}
                                        placeholder={t`Enter the reason for the request`}
                                        disabled={isBusy} defaultValue={""}
                                        onChange={e => {
                                            const val = (e.currentTarget as HTMLInputElement).value.trim() ?? '';
                                            const isInvalid = val.length < 10;
                                            setValidationDevErrorReason(isInvalid);
                                            setValidationError(isInvalid ? t`Enter at least 10 characters for the reason` : '');
                                            if (!isInvalid) {
                                                setDeveloperReason(val);
                                            }

                                        }}
                                    />
                                ],
                                [
                                    <div className='input-label'><Trans>Requests per minute</Trans></div>,
                                    <input
                                        key="devKeyRequests"
                                        className={classNames(validationDevErrorRequests ? 'validation-error' : '')}
                                        placeholder={t`e.g. 3`}
                                        disabled={isBusy} defaultValue={""}
                                        onChange={e => {
                                            const val = (e.currentTarget as HTMLInputElement).value.trim();
                                            const valInt = parseFloat(val);
                                            const isNum = !isNaN(valInt);
                                            const isInvalid = !isNum || valInt <= 0;
                                            setValidationDevErrorRequests(isInvalid);
                                            setValidationError(isInvalid ? t`Invalid number of requests per minute. Must be greater than 0` : '');
                                            if (!isInvalid) {
                                                setDeveloperRequestsPerMinute(valInt);
                                            }
                                        }}
                                    />
                                ],
                                [
                                    <div className='input-label'><Trans>Public use?</Trans></div>,
                                    <ToggleSwitch
                                        label={t``} onChange={setDeveloperPublicUse} />,
                                ],
                                [
                                    <div className='input-label'><Trans>Number of users</Trans></div>,
                                    <input
                                        key="devKeyNumUsers"
                                        className={classNames(validationDevErrorUsers ? 'validation-error' : '')}
                                        placeholder={t`e.g. 1`}
                                        disabled={isBusy} defaultValue={""}
                                        onChange={e => {
                                            const val = (e.currentTarget as HTMLInputElement).value.trim();
                                            const valInt = parseInt(val);
                                            const isNum = !isNaN(valInt);
                                            const isInvalid = !isNum || valInt < 1;
                                            setValidationDevErrorUsers(isInvalid);
                                            setValidationError(isInvalid ? t`Invalid number of user. Must be at least 1` : '');
                                            if (!isInvalid) {
                                                setDeveloperNumUsers(valInt);
                                            }
                                        }}
                                    />
                                ],
                                [
                                    <div className='input-label'><Trans>Discord Id</Trans><br /><a onClick={(ev: any) => handleMobileAppNavigation(ev)} rel="noreferrer" target="_blank" style={{ fontSize: 13 }} href="https://blog.pool.space/enabling-health-notifications-on-discord-for-your-chia-farm-in-space-pool-da9bb1368260"><Trans>How to get it?</Trans></a></div>,
                                    <input
                                        key="devKeyDiscord"
                                        className={classNames(validationErrorDiscordId ? 'validation-error' : '')}
                                        placeholder={t`e.g. 439149696196018170`}
                                        disabled={isBusy} defaultValue={discordId}
                                        onChange={e => {
                                            const val = (e.currentTarget as HTMLInputElement).value.trim();
                                            const valInt = parseInt(val);
                                            const isNum = !isNaN(valInt);
                                            var isInvalid = !isNum || valInt < 1000000;
                                            setValidationErrorDiscordId(isInvalid);
                                            setValidationError(isInvalid ? t`Invalid discord ID` : '');
                                            if (!isInvalid) {
                                                setDeveloperDiscordId(val);
                                            }
                                        }}
                                    />
                                ],
                            ]}
                        />
                        :
                        redeemPromotion ?
                            <div className='invalid-token'>
                                Use the promo code: SPACEPOOL at the Amazon checkout to receive the $139 discounted price
                            </div>
                            : invalidToken
                                ? <div className='invalid-token'>
                                    <Trans>Your login token has expired - please get a new link from the Chia GUI or CLI.</Trans>
                                </div> :
                                <div>
                                    <Tabs defaultActiveKey="general" onSelect={(value) => { }}>
                                        <Tab eventKey="general" title={t`General`}>
                                            <SpacePoolTable
                                                headers={[
                                                    {
                                                        label: ' ',
                                                    },
                                                    {
                                                        label: '',
                                                        flexible: true,
                                                        extraClassName: 'input-value',
                                                    },
                                                ]}
                                                rows={[
                                                    isEmbedMode() ? [] : [
                                                        <div className='input-label' style={{ marginBottom: 100, width: 150 }}><Trans>Pool Login QR Code <b>(do not share)</b></Trans></div>,
                                                        <div style={{ marginBottom: 20 }}>
                                                            <div style={{ fontSize: 14, color: '#03b000' }} onClick={onQRVisibilityToggle}>{qrVisibilityLabel}</div>
                                                            <div style={{ marginBottom: -20, opacity: qrVisibilityLabel === hideQRCodeLabel ? 1 : 0.05 }}>
                                                                <QRCode
                                                                    value={`${qrVisibilityLabel === hideQRCodeLabel ? document.location.href : 'https://pool.space'}`}
                                                                    size={128}
                                                                    bgColor={"#ffffff"}
                                                                    fgColor={"#000000"}
                                                                    level={"L"}
                                                                    includeMargin={false}
                                                                    renderAs={"canvas"}
                                                                    imageSettings={{
                                                                        src: "/favicon.ico",
                                                                        x: null,
                                                                        y: null,
                                                                        height: 24,
                                                                        width: 24,
                                                                        excavate: false,
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>,
                                                    ],
                                                    [
                                                        <div className='input-label'><Trans>Account Alias</Trans></div>,
                                                        <input
                                                            key={settingsVersion}
                                                            className={classNames(validationErrorAlias ? 'validation-error' : '')}
                                                            placeholder={t`Leave blank to remove your alias`}
                                                            disabled={isBusy} defaultValue={alias}
                                                            onChange={e => handleAliasInputChanged((e.currentTarget as HTMLInputElement).value.trim())}
                                                        />,
                                                    ],
                                                    [
                                                        <div className='input-label'><Trans>Discord ID</Trans><br /><a onClick={(ev: any) => handleMobileAppNavigation(ev)} rel="noreferrer" target="_blank" style={{ fontSize: 13 }} href="https://blog.pool.space/enabling-health-notifications-on-discord-for-your-chia-farm-in-space-pool-da9bb1368260"><Trans>How do I get it?</Trans></a></div>,
                                                        <input
                                                            key={settingsVersion}
                                                            className={classNames(validationErrorDiscordId ? 'validation-error' : '')}
                                                            placeholder={t`Leave blank to remove Discord notifications`}
                                                            disabled={isBusy} defaultValue={discordId}
                                                            onChange={e => handleDiscordIdChanged((e.currentTarget as HTMLInputElement).value.trim())}
                                                        />,
                                                    ],
                                                    [
                                                        <div className='input-label'><Trans>Discord Webhook</Trans><br /><a onClick={(ev: any) => handleMobileAppNavigation(ev)} rel="noreferrer" target="_blank" style={{ fontSize: 13 }} href="https://blog.pool.space/enabling-webhook-notifications-on-discord-for-your-chia-farm-in-space-pool-7061e3f8cfe7"><Trans>How do I get it?</Trans></a></div>,
                                                        <input
                                                            key={settingsVersion}
                                                            style={{ marginTop: 25 }}
                                                            className={classNames(validationErrorDiscordWebhook ? 'validation-error' : '')}
                                                            placeholder={t`Leave blank to remove Discord webhook`}
                                                            disabled={isBusy} defaultValue={discordWebhook}
                                                            onChange={e => handleDiscordWebhookChanged((e.currentTarget as HTMLInputElement).value.trim())}
                                                        />,
                                                    ],
                                                    [
                                                        <div className='input-label'><Trans>Min. Payout (XCH)</Trans></div>,
                                                        <input
                                                            key={settingsVersion}
                                                            style={{ marginTop: 26 }}
                                                            className={classNames(validationErrorMinPayout ? 'validation-error' : '')}
                                                            placeholder={t`Enter a minimum payout value`}
                                                            disabled={isBusy || isEmbedMode()} defaultValue={minPayout}
                                                            onChange={e => handleMinPayoutChanged((e.currentTarget as HTMLInputElement).value.trim())}
                                                        />,
                                                    ],
                                                    [
                                                        <div className='input-label'><Trans>Payout Address</Trans>
                                                            <br /><a onClick={(ev: any) => handleMobileAppNavigation(ev)} rel="noreferrer" target="_blank" style={{ fontSize: 13 }} href={`https://faucet.chia.net/?address=${payoutAddress}`}><Trans>Test with faucet</Trans></a></div>,
                                                        <input
                                                            key={settingsVersion}
                                                            className={classNames(validationErrorPayoutAddress ? 'validation-error' : '')}
                                                            placeholder={t`Enter a payout wallet address`}
                                                            title={settings.payoutPuzzleHash}
                                                            disabled={isBusy || isEmbedMode()} defaultValue={payoutAddress}
                                                            onChange={e => handlePayoutAddressChanged((e.currentTarget as HTMLInputElement).value.trim())}
                                                        />
                                                    ],
                                                    isEmbedMode() ? [] : [
                                                        <div style={{}}><Trans>Developer API Key</Trans><br /><a onClick={(ev: any) => handleMobileAppNavigation(ev)} rel="noreferrer" target="_blank" style={{ fontSize: 13 }} href="https://blog.pool.space/how-to-get-started-with-space-pool-developer-apis-915b153b2c40"><Trans>What is this?</Trans></a></div>,
                                                        <div>{
                                                            devAPIKey ?
                                                                <div style={{ marginTop: 20 }}><div style={{ float: 'left', marginRight: 5 }}>{`${devAPIKey.substr(0, 5)}...`}</div> <CopyIcon text={devAPIKey} /></div> :
                                                                <div style={{ marginTop: 20 }}><a onClick={(ev: any) => { setEnterDevInfo(true) }} href="#"><Trans>Request API key</Trans></a></div>
                                                        }</div>
                                                    ]
                                                ]}
                                            />

                                            <div className='notification-medium-settings'>
                                                <SpacePoolTable
                                                    headers={[
                                                        {
                                                            label: '',
                                                        },
                                                    ]}
                                                    rows={[
                                                        [
                                                            <ToggleSwitch disabled={isEmbedMode()} label={t`24-Hour Payout Cooldown`} forceState={payoutCooldownEnabled} onChange={setPayoutCooldownEnabled} />,
                                                        ],
                                                    ]}
                                                />
                                            </div>


                                            {
                                                !isEmbedMode() && <div className='payouts-export'>
                                                    <SpacePoolTable
                                                        headers={[
                                                            {
                                                                label: <Trans>Export Payouts to CSV</Trans>,
                                                            },
                                                            {
                                                                label: <div>&nbsp;</div>,
                                                                flexible: true,
                                                            },
                                                        ]}
                                                        rows={[
                                                            [
                                                                <div style={{ height: '2em', display: 'flex', alignItems: 'center' }}>
                                                                    <select value={selectedExportDate} style={{ width: '100%' }} onChange={e => setSelectedExportDate(exportOptions[(e.currentTarget as HTMLSelectElement).selectedIndex])}>
                                                                        {
                                                                            exportOptions.map((x, i) => <option key={i} value={x}>{x}</option>)
                                                                        }
                                                                    </select>
                                                                </div>,
                                                                <Button style={{ height: '2em', paddingTop: '0.2em', width: '100%' }} onClick={handleExportDownload}>Download</Button>
                                                            ],
                                                        ]}
                                                    />
                                                </div>
                                            }
                                            {
                                                <div className='explorer-login'>
                                                    <SpacePoolTable
                                                        headers={[
                                                            {
                                                                label: <Trans>Space Explorer</Trans>,
                                                            },
                                                            {
                                                                label: <div>&nbsp;</div>,
                                                                flexible: true,
                                                            },
                                                        ]}
                                                        rows={[
                                                            [
                                                                <div style={{ marginTop: -10 }}>
                                                                    <a target='_blank' href={`https://xch.space/?spLoginToken=${props.loginToken}&launcherId=${props.launcherId}`}>Login</a>
                                                                </div>,
                                                                <></>
                                                            ],
                                                        ]}
                                                    />
                                                </div>
                                            }
                                        </Tab>
                                        <Tab eventKey="notifications" title={t`Notifications`}>
                                            <div className='notification-medium-settings'>
                                                <SpacePoolTable
                                                    headers={[
                                                        {
                                                            label: <Trans>Devices to Notify</Trans>,
                                                        },
                                                    ]}
                                                    rows={[
                                                        [
                                                            <ToggleSwitch disabled={validationErrorDiscordId || !discordId} label="Discord" forceState={enableDiscordNotifs} onChange={setEnableDiscordNotifs} />,
                                                        ],
                                                        [
                                                            <ToggleSwitch disabled={validationErrorDiscordWebhook || !discordWebhook} label="Discord Webhook" forceState={enableDiscordWebhookNotifs} onChange={setEnableDiscordWebhookNotifs} />,
                                                        ],
                                                        [
                                                            <ToggleSwitch label={t`Mobile Devices Push`} forceState={enableMobileNotifs} onChange={setEnableMobileNotifs} />
                                                        ],
                                                    ]}
                                                />
                                            </div>

                                            <div className='notification-type-settings'>
                                                <SpacePoolTable
                                                    headers={[
                                                        {
                                                            label: <Trans>Notification Types</Trans>,
                                                        },
                                                    ]}
                                                    rows={[
                                                        [
                                                            <ToggleSwitch label={t`Account changes`} description={t`Be notified when new devices are linked or payout address / min payout amount are changed`} forceState={enableSystemNotif} onChange={setEnableSystemNotif} />
                                                        ],
                                                        [
                                                            <ToggleSwitch label={t`Farm Offline (min. 10 TiB plot size)`} forceState={enableFarmOfflineNotif} onChange={setEnableFarmOfflineNotif} />
                                                        ],
                                                        [
                                                            <ToggleSwitch label={t`Payout`} forceState={enablePayoutNotif} onChange={setEnablePayoutNotif} />
                                                        ],
                                                        [
                                                            <ToggleSwitch label={t`Invalid Partials`} forceState={enableInvalidPartialsNotif} onChange={setEnableInvalidPartialsNotif} />
                                                        ],
                                                        [
                                                            <ToggleSwitch label={t`Block wins`} forceState={enableBlockWinNotif} onChange={setEnableBlockWinNotif} />
                                                        ],
                                                    ]}
                                                />
                                            </div>
                                        </Tab>
                                        <Tab eventKey='difficulty' title='Difficulty'>
                                            <div className='advanced-tab'>
                                                <div className='adv-settings-row'>
                                                    <div className='label'><Trans>Difficulty Mode</Trans></div>
                                                    <div className='input'>
                                                        <input name='difficultyMode' id='pph' type='radio' checked={!customDifficulty} onClick={() => setCustomDifficulty(undefined)} />
                                                        <label style={{ marginLeft: '4px' }} htmlFor='pph'>Partials Per Hour (PPH)</label>
                                                        <input name='difficultyMode' style={{ marginLeft: '12px' }} id='cd' type='radio' checked={!!customDifficulty} onClick={() => setCustomDifficulty(origCustomDifficulty || 5)} />
                                                        <label style={{ marginLeft: '4px' }} htmlFor='cd'>Static Difficulty</label>
                                                    </div>
                                                </div>

                                                {
                                                    !customDifficulty
                                                        ? <>
                                                            <div className='adv-settings-row'>
                                                                <div className='label'><Trans>Partials Per Hour</Trans></div>
                                                                <div className='value'>{partialsPerHour.toFixed(0)}</div>
                                                                <div className='input'>
                                                                    <input type="range" min="2" max="24" value={partialsPerHour} onChange={(ev) => { setPartialsPerHour(parseInt(ev.target.value)) }} className="slider"></input>
                                                                    <div className="ticks">
                                                                        <span className="tick">2</span>
                                                                        <span className="tick">4</span>
                                                                        <span className="tick">6</span>
                                                                        <span className="tick">8</span>
                                                                        <span className="tick">10</span>
                                                                        <span className="tick">12</span>
                                                                        <span className="tick">14</span>
                                                                        <span className="tick">16</span>
                                                                        <span className="tick">18</span>
                                                                        <span className="tick">20</span>
                                                                        <span className="tick">22</span>
                                                                        <span className="tick">24</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='reset-link' onClick={() => setPartialsPerHour(18)}>Reset PPH</div>
                                                            <div><b>Warning</b>: Higher PPH may result in smoother estimates but requires more disk lookups and power usage while a lower PPH may result in the opposite. Changes may take up to 30 minutes to apply.</div>
                                                            {partialsPerHour < 5 &&
                                                                <div>
                                                                    <div style={{ color: 'red', fontWeight: 'bold' }}><b>Warning</b>: Setting PPH to less than 5 may affect account statistics, notifications, and pool rewards. Only set this if you really know what you are doing.</div>
                                                                </div>
                                                            }
                                                        </>
                                                        : <>
                                                            <div className='adv-settings-row'>
                                                                <div className='label'><Trans>Static Difficulty</Trans></div>
                                                                <div className='input'>
                                                                    <input type='number' min={5} max={1000000} style={{ width: '100%' }} defaultValue={customDifficulty} onChange={e => setCustomDifficulty(Math.max(5, +e.currentTarget.value))} onBlur={e => e.currentTarget.value = `${Math.max(5, +e.currentTarget.value)}`} />
                                                                </div>
                                                            </div>

                                                            <div style={{ marginTop: '16px' }}>
                                                                <div style={{ fontSize: 'large', fontWeight: 'bold' }}>Preview</div>
                                                                <div>A static difficulty of <b>{customDifficulty}</b> and a hypothetical farm size of <input type='number' min={10} defaultValue={customDifficultyPreviewSizeTib} onChange={e => setCustomDifficultyPreviewSizeTib(Math.max(10, +e.currentTarget.value))} style={{ width: '80px' }} /> TiB will result in <b>approximately {_calculatePPH(customDifficultyPreviewSizeTib, customDifficulty)}</b> partials per hour.</div>
                                                            </div>

                                                            <div style={{ marginTop: '48px', fontWeight: 'bold' }}>By setting a static difficulty you agree to the following:</div>
                                                            <div style={{ marginLeft: '16px', marginTop: '4px', color: 'red' }}>- <b>Static difficulty mode is a user-convenience feature that Space Pool may suspend or disable at any moment without warning</b></div>
                                                            <div style={{ marginLeft: '16px', marginTop: '4px' }}>- Space Pool <b>WILL NOT</b> provide technical support for accounts with a static difficulty set</div>
                                                            <div style={{ marginLeft: '16px', marginTop: '4px' }}>- Choosing a difficulty outside of your farm's capability <b>WILL</b> result in poor estimates and potential loss of pool rewards</div>
                                                            <div style={{ marginLeft: '16px', marginTop: '4px' }}>- Space Pool <b>MAY</b> change or ignore your static difficulty if it is deemed unsupported given your farm size</div>
                                                            <div style={{ marginLeft: '16px', marginTop: '4px' }}>- Static difficulty may be changed <b>once per hour</b>, however, you can change to PPH mode at any time</div>
                                                        </>
                                                }
                                            </div>
                                        </Tab>
                                        <Tab eventKey="advanced" title={t`Fees`}>
                                            <div className='advanced-tab'>
                                                <div className='adv-settings-row'>
                                                    <div className='label'><Trans>Pool Fee</Trans></div>
                                                    <div className='value'>{customFee.toFixed(1)}%</div>
                                                    <div className='input'>
                                                        <input type="range" min="1" max="9" step="0.1" value={customFee} onChange={(ev) => { setCustomFee(parseFloat(ev.target.value)) }} className="slider"></input>
                                                        <div className="ticks">
                                                            <span className="tick">1%</span>
                                                            <span className="tick">3%</span>
                                                            <span className="tick">5%</span>
                                                            <span className="tick">7%</span>
                                                            <span className="tick">9%</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='reset-link' onClick={() => setCustomFee(1)}>Reset Fees</div>
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
            }
        </Modal.Body>

        <Modal.Footer className='account-settings-modal-footer'>
            {isBusy && <div><Trans>Applying Settings...</Trans></div>}
            {!!validationError && <div style={{ color: 'red' }}>{validationError}</div>}
            {isEmbedMode() && <Button style={{ marginRight: 10 }} onClick={handleCancelClicked}>
                <Trans>Cancel</Trans>
            </Button>}
            {
                invalidToken
                    ? <Button onClick={handleOnHide}><Trans>Close</Trans></Button>
                    :
                    redeemPromotion ?
                        <Button onClick={handlePromotionNavigation}><Trans>OK</Trans></Button>
                        :
                        <Button disabled={!canSave || isBusy} onClick={handleSaveClicked}>
                            {showDevKey ? <Trans>Back</Trans> : <Trans>Save</Trans>}
                        </Button>
            }
        </Modal.Footer>
    </Modal>
};

const _calculatePPH = (farmSizeTib: number, diff) => {
    // 10 partials per day for 1 K32 plot (0.09897 TiB)

    return (20 * (farmSizeTib / 0.09897) / 24 / diff).toFixed(1);
};