import { createReducer } from "typesafe-actions";
import { actions, ActionsType } from "../store";
import Leaderboard from "../models/leaderboard";

export const initialState = {};

export interface LeaderboardState {
  leaderboard?: Leaderboard
}

export const leaderboardReducer = createReducer<LeaderboardState, ActionsType>(
  initialState
)
.handleAction(actions.leaderboardSetAction, (state, action) => { 
  return ({
  ...state,
  leaderboard: new Leaderboard(action.payload),
})})
.handleAction(actions.leaderboardErrorAction, (state, action) => {
  console.error(action.payload);
  return state;
});
