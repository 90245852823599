import { t } from '@lingui/macro';

export const getAge = (time: string) => {
    var date = new Date((time || "")),
        diff = (((new Date()).getTime() - date.getTime()) / 1000),
        day_diff = Math.floor(diff / 86400);

    if (isNaN(day_diff) || day_diff < 0) {
        return;
    }

    if (diff < 50) {
        return t`just now`;
    } else if (diff < 120) {
        return t`1 minute ago`;
    } else if (diff < 7200) {
        return t`${Math.floor(diff / 60)} minutes ago`;
    } else if (diff < 86400) {
        return t`${Math.floor(diff / 3600)} hours ago`;
    } else if (day_diff === 1) {
        return t`Yesterday`;
    } else if (day_diff < 7) {
        return t`${day_diff} days ago`;
    } else if (day_diff >= 7) {
        return t`${Math.ceil(day_diff / 7)} weeks ago`;
    }
}

export const getDelta = (newDate: string, oldDate: string) => {
    var oldAsDAte = new Date((oldDate || "")),
        newAsDAte = new Date((newDate || "")),
        diff = ((newAsDAte.getTime() - oldAsDAte.getTime()) / 1000),
        mins_diff = Math.floor(diff / 60),
        hours_diff = Math.floor(diff / 3600),
        day_diff = Math.floor(diff / 86400);

    if (isNaN(day_diff) || day_diff < 0) {
        return;
    }

    if (diff < 60) {
        return t`${diff} secs`;
    } else if (mins_diff < 60) {
        return t`${mins_diff} mins`;
    } else if (day_diff < 2) {
        if (hours_diff === 1) {
            if (mins_diff % 60 === 1) {
                return t`${hours_diff} hr ${mins_diff % 60} min`;
            } else {
                if (mins_diff % 60 === 0) {
                    return t`${hours_diff} hr`;
                } else {
                    return t`${hours_diff} hr ${mins_diff % 60} mins`;
                }
            }
        } else {
            if (mins_diff % 60 === 1) {
                return t`${hours_diff} hrs ${mins_diff % 60} min`;
            } else {
                return t`${hours_diff} hrs ${mins_diff % 60} mins`;
            }
        }
    } else {
        if (day_diff === 1) {
            if (hours_diff % 24 === 1) {
                return t`${day_diff} day ${hours_diff % 24} hr`;
            } else {
                return t`${day_diff} day ${hours_diff % 24} hrs`;
            }
        } else {
            if (hours_diff % 24 === 1) {
                return t`${day_diff} days ${hours_diff % 24} hr`;
            } else {
                return t`${day_diff} days ${hours_diff % 24} hrs`;
            }
        }
    }
}

export const getIntevalDescription = (interval: string): string => {
    var description = '';
    switch (interval) {
        case 'LastHour':
            description = t`Last Hour`;
            break;
        case 'Last12Hours':
            description = t`Last 12 hrs`;
            break;
        case 'Last24Hours':
            description = t`Last 24 hrs`;
            break;
        case 'Today':
            description = t`Today`;
            break;
        case 'Yesterday':
            description = t`Yesterday`;
            break;
        case 'Day':
            description = t`Day`;
            break;
        case 'Last7Days':
            description = t`Last 7 Days`;
            break;
        default:
            debugger;
            throw 'Invalid interval value';
    }
    return description;
}

export const getMonthDay = (inputDate: string) => {
    var date = new Date((inputDate));
    return `${date.getMonth() + 1}/${date.getDate()}`;
}

export const isToday = (inputDate: string) => {
    var date = new Date((inputDate));
    var now = new Date();
    return date.getMonth() === now.getMonth() &&
        date.getDate() === now.getDate() &&
        date.getFullYear() === now.getFullYear();
}

export const isYesterday = (inputDate: string) => {
    var date = new Date((inputDate));
    var now = new Date();
    return date.getMonth() === now.getMonth() &&
        date.getDate() === now.getDate() - 1 &&
        date.getFullYear() === now.getFullYear();
}
