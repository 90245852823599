import { Trans } from '@lingui/macro';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from '../../index.reducers';
import { IAccountStatModel } from '../../models/account-stats';
import ChiaQuote from '../../models/quote';
import { getIntevalDescription } from '../../utils/date-util';
import { SpacePoolTable } from '../spacepool-table/spacepool-table';
import './recent-farm-rewards-stats.scss';

type Props = {
    chiaQuote?: ChiaQuote;
    currencyInfo?: { price: number; change: number; key: string }
}

export const RecentFarmRewardsStats = (props: Props) => {
    const chiaPricePrimary = props.currencyInfo?.price || 0;
    const chiaPriceUSD = props.chiaQuote?.data?.XCH?.quote?.USD?.price || 0;
    const chiaPriceEUR = props.chiaQuote?.data?.XCH?.quote?.EUR?.price || 0;
    const chiaPriceSecondary = props.currencyInfo?.key !== 'USD' ? chiaPriceUSD : chiaPriceEUR;
    const chiaCurrencyKeySecondary = props.currencyInfo?.key !== 'USD' ? "USD" : "EUR";

    const { stats } = useSelector((state: RootState) => state.account);
    if (!stats) {
        return null;
    }

    return (
        <div className='recent-farm-rewards-stats'>
            <h4><Trans>Your Rewards</Trans></h4>
            <SpacePoolTable
                headerLine={true}
                rowLine={true}
                headers={[
                    { label: <Trans>Period</Trans> },
                    { label: 'XCH' },
                    { label: props.currencyInfo?.key, extraClassName: 'primary' },
                    { label: chiaCurrencyKeySecondary, extraClassName: 'secondary' },
                ]}
                rows={calcExtraStats(stats).map((row, i) => [
                    getIntevalDescription(row.period),
                    new Intl.NumberFormat(undefined, { maximumFractionDigits: 6 }).format(row.rewardTotal),
                    chiaPricePrimary > 0 ? new Intl.NumberFormat(undefined, { style: 'currency', currency: props.currencyInfo?.key }).format(row.rewardTotal * chiaPricePrimary) : "-",
                    chiaPricePrimary > 0 ?new Intl.NumberFormat(undefined, { style: 'currency', currency: chiaCurrencyKeySecondary }).format(row.rewardTotal * chiaPriceSecondary) : "-",
                ])}
            />
        </div>
    );
};

function calcExtraStats(stats: IAccountStatModel[]) {
    let reward12Hours = { period: 'Last12Hours', rewardTotal: 0 };
    let reward24Hours = { period: 'Last24Hours', rewardTotal: 0 };
    let rewardToday = { period: 'Today', rewardTotal: 0 };
    let rewardYesterday = { period: 'Yesterday', rewardTotal: 0 };
    let rewardLast7Days = { period: 'Last7Days', rewardTotal: 0 };

    try {
        const now = moment();

        const hours12Date = moment(now).subtract({ hours: 12 }).format('YYYY-MM-DDTHH');
        const hours24Date = moment(now).subtract({ hours: 24 }).format('YYYY-MM-DDTHH');
        const last7Days = moment(now).subtract({ days: 7 }).format('YYYY-MM-DDTHH');
        const todayDate = now.format('YYYY-MM-DDT00');
        const yesterdayDate = moment(now).subtract({ days: 1 }).format('YYYY-MM-DDT00');
        for (const stat of stats) {
            const amount = stat.balanceIncreaseInMojo / 10 ** 12;
            const date = moment.utc(stat.creditedDayHour).local().format('YYYY-MM-DDTHH');

            if (date > hours12Date) { reward12Hours.rewardTotal += amount; }
            if (date > hours24Date) { reward24Hours.rewardTotal += amount; }
            if (date > last7Days) { rewardLast7Days.rewardTotal += amount; }

            if (date >= todayDate) { rewardToday.rewardTotal += amount; }
            else if (date >= yesterdayDate) { rewardYesterday.rewardTotal += amount; }
        }
    } catch {
        return [];
    }

    return [
        reward12Hours,
        reward24Hours,
        rewardToday,
        rewardYesterday,
        rewardLast7Days,
    ];
}