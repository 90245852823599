import { t, Trans } from '@lingui/macro';
import classNames from 'classnames';
import { useEffect } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CopyIcon } from '../components/copy-icon/copy-icon';
import { HarvestersSummary } from '../components/harvesters-summary/harvesters-summary';
import { PartialsChart } from '../components/partials-chart/partials-chart';
import { PartialsDashboard } from '../components/partials-dashboard/partials-dashboard';
import { SpacePoolTable } from '../components/spacepool-table/spacepool-table';
import WarningImg from '../images/warning.png';
import { RootState } from "../index.reducers";
import { InfoIcon } from '../info-icon/info-icon';
import { actions } from "../store";
import { isEmbedMode } from '../utils/navigator-util';
import './partials-page.scss';

const getStatus = (code: number) => {
    switch (code) {
        case 0:
            return t`Validating...`;
        case 1:
            return t`Valid`;
        case 2:
            return t`Invalid`;
        default:
            return t`Unknown`;
    }
}

const getErrorDescription = (code: number) => {
    switch (code) {
        case 16:
            return {
                msg: t`Partial was created with a client version older than v2.4 (the minimum version supporting the latest Chia soft fork). Please upgrade your client to the latest official Chia version to continue pooling.`,
                type: t`Invalid`,
            };
        case 15:
            return {
                msg: t`Partial received too late. This indicates that this partial was valid but not submitted on time. This can happen from time to time due to your connectivity to the server and is generally not cause for concern. If this occurs more than 2% of the time then make sure that your OS time is synced, and that you are not plotting on the same drive as your existing plots. Check your log files and search for 'partial' and make sure your lookup times are ideally below 2 seconds.`,
                type: t`Stale`,
            };
        case 14:
            return {
                msg: t`Signage point for the proof was not found or reverted`,
                type: t`Stale`,
            };
        case 12:
            return {
                msg: t`Proof was not good enough for the current difficulty. This can happen occasionally when the pool adjusts difficulty and the farm does not adjust to the new difficulty fast enough. There is likely no action needed unless this occurs frequently`,
                type: t`Stale`,
            };
        case 11:
            return {
                msg: t`Proof of space was invalid`,
                type: t`Invalid`,
            };
        case 10:
            return {
                msg: t`Invalid partial`,
                type: t`Invalid`,
            };
        case 13:
            return {
                msg: t`Difficulty provided was invalid`,
                type: t`Invalid`,
            };
        case 3:
            return {
                msg: t`Launcher is not assigned to the pool`,
                type: t`Invalid`,
            };
        case 2:
            return {
                msg: t`Invalid delay time on launcher configuration`,
                type: t`Invalid`,
            };
        case 1:
            return {
                msg: t`Invalid launcher configuration`,
                type: t`Invalid`,
            };
        default:
            return {
                msg: t`Unknown`,
                type: t`Invalid`,
            };
    }
}

interface Props {
    autoRefresh?: boolean;
    autoRefreshValueInSecs?: number;
}

export const PartialsPage = (props: Props) => {
    const { accountId } = useParams<{ accountId: string }>();
    const dispatch = useDispatch();
    const getData = () => {
        dispatch(actions.accountPartialsGetAction({ launcherId: accountId }));
    };

    useEffect(() => {
        getData();
        if (props.autoRefresh) {
            const interval = setInterval(getData, (props.autoRefreshValueInSecs || 60) * 1000);
            return () => clearInterval(interval);
        }
    }, [dispatch, accountId, props.autoRefresh]);

    const { partials, accountDetail } = useSelector((state: RootState) => state.account);
    const { lastDiff, customDiff } = useSelector((state: RootState) => ({ lastDiff: state.account.accountDetail?.lastDifficulty || 0, customDiff: state.account.accountDetail?.customDifficulty || 0 }));

    if (!partials) {
        return null;
    }

    if (partials.length === 0) {
        return <div><Trans>We haven't received any partials yet. Please check again later.</Trans></div>
    } else {
        const lastPartialDate = new Date(partials[0].submissionDate);
        const hasRecentPatials = isEmbedMode() || (((Date.now() - lastPartialDate.getTime()) / 1000) / 60) < 70;
        const oldestPartialDate = Intl.DateTimeFormat(undefined, { dateStyle: 'short', timeStyle: 'short' }).format(new Date(partials[partials.length - 1].submissionDate));
        return (
            <div className='partials-page'>
                <div className={hasRecentPatials ? 'has-recent-partials' : ''}>
                    <img src={WarningImg} height="30" />
                    <span>&nbsp;<Trans>This farm has not sent partials in the last hour</Trans></span><br /><br />
                </div>

                <div className='section-header'><Trans>24-Hour Overview</Trans></div>
                <PartialsDashboard diff={customDiff || lastDiff} numHarvesters={new Set(partials.map(x => x.harvesterId)).size} hasCustomDifficulty={!!customDiff} />

                <div className='section-header'><Trans>Points</Trans></div>
                <div style={{ width: '100%', height: '300px' }}>
                    <PartialsChart />
                </div>


                <div className='section-header'><Trans>Latest {partials.length} Partials</Trans>&nbsp;-&nbsp;<Trans>Last:</Trans>&nbsp;&nbsp;<span>{Intl.DateTimeFormat(undefined, { dateStyle: 'short', timeStyle: 'short' }).format(new Date(lastPartialDate))}</span>&nbsp;-&nbsp;<Trans>Client version:</Trans>&nbsp;<span>{accountDetail?.clientVersion}</span></div>
                <HarvestersSummary partials={partials} />
                <SpacePoolTable
                    headerLine={true}

                    headers={[
                        {
                            label: <Trans>Date</Trans>,
                        },
                        {
                            label: <Trans>Harvester Id</Trans>,
                            flexible: true,
                        },
                        {
                            label: <Trans>Diff</Trans>,
                        },
                        {
                            label: <Trans>Status</Trans>,
                        },
                        {
                            label: <Trans>Proof</Trans>,
                            flexible: true,
                            extraClassName: 'proof-column',
                        },
                    ]}
                    rows={
                        partials.map((row, i) => [
                            Intl.DateTimeFormat(undefined, { dateStyle: 'short', timeStyle: 'short' }).format(new Date(row.submissionDate)),

                            <OverlayTrigger
                                trigger="click"
                                key={`partials-harvester-id-${i}`}
                                placement={"bottom-start"}
                                rootClose
                                overlay={
                                    <Popover id={`popover-positioned-harvester-id-${row.submissionDate}`}>
                                        <Popover.Content>
                                            <div className="partials-page-item-info">
                                                <div className='partials-page-label-container'>
                                                    <div className="partials-page-item-info-label"><Trans>Harvester Id</Trans></div>
                                                    <CopyIcon text={row.harvesterId} />
                                                </div>
                                                <div>{row.harvesterId}</div>
                                            </div>
                                        </Popover.Content>
                                    </Popover>
                                }
                            >
                                <span className="partials-harvester-id">{row.harvesterId}</span>
                            </OverlayTrigger>,

                            row.difficulty,

                            <div style={{ display: 'flex' }}>
                                {
                                    row.error
                                        ? <span className={classNames('status', getErrorDescription(row.error.code).type.toLocaleLowerCase())}>{getErrorDescription(row.error.code).type}</span>
                                        : <span className={classNames('status', 'valid')}>{getStatus(row.state.code)}</span>
                                }
                                {
                                    row.error && <InfoIcon tooltip={getErrorDescription(row.error.code).msg} />
                                }
                            </div>,

                            <OverlayTrigger
                                trigger="click"
                                key={`stat-box-${i}`}
                                placement={"left"}
                                rootClose
                                overlay={
                                    <Popover id={`popover-positioned-${row.submissionDate}`}>
                                        <Popover.Content>
                                            <div className="partials-page-item-info">
                                                <div className='partials-page-label-container'>
                                                    <div className="partials-page-item-info-label"><Trans>Proof</Trans></div>
                                                    <CopyIcon text={row.proof} />
                                                </div>
                                                <div>{row.proof}</div>
                                            </div>
                                        </Popover.Content>
                                    </Popover>
                                }
                            >
                                <span className="proof">{row.proof}</span>
                            </OverlayTrigger>,
                        ])
                    }
                />
            </div>
        );
    }
};

