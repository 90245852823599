import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import moment from 'moment';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Area, AreaChart, CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { RootState } from '../index.reducers';

export const MempoolChart = (props: {}) => {
    const darkMode = useSelector((state: RootState) => state.topLevel.darkMode);
    const { poolStats } = useSelector((state: RootState) => state.poolStats);

    let data: { sortKey: number; date: string, mempoolSize: number }[] = [];
    if (poolStats) {
        let i = 0;
        for (const entry of poolStats.recentStats.results) {
            const date = moment.utc(entry.date);
            if (date < moment.utc('2021-12-21T09:59:42.5080000Z')) {
                continue;
            }
            data.push({
                sortKey: date.valueOf(),
                date: date.local().format('YYYY/MM/DD HH:mm:ss'),
                mempoolSize: entry.mempoolSize
            });
        }
        data = data.sort((a, b) => a.sortKey - b.sortKey);
    }

    return (
        <>
        <ResponsiveContainer width="100%" height="100%" >
            <LineChart
                width={500}
                height={400}
                data={data}
                margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                }}
                >
                    <XAxis dataKey="date" minTickGap={80} tickSize={16} tickFormatter={(date) => moment.utc(date).format('MM/DD HH:mm:ss')} />
                    <YAxis  />
                    <CartesianGrid strokeDasharray="3 3" strokeOpacity="0.3" />
                    <Tooltip contentStyle={{ backgroundColor: darkMode ? '#000' : '#FFF', opacity: '0.7' }} />
                    <Legend />
                    <Line type="monotone" name={t`Mempool Size`} unit=" TXN" dataKey="mempoolSize" stroke="#03c000"  />
                </LineChart>
        </ResponsiveContainer>
        </>
    );
}
