import './faq-question.scss';
import React from 'react';
import classNames from 'classnames';

type Props = {
    title: string;
    children?: any;
};

export const FAQQuestion = (props: Props) => {
    const [expanded, setExpanded] = React.useState(false);

    var onExpand = (ev: any) => {
        if (expanded) {
            setExpanded(false);
        } else {
            setExpanded(true);
        }
    };

    return (
        <div className="faq-question">
            <div className="title">
                <div>{props.title}</div>
                <div className="expander" onClick={onExpand}>{expanded ? '-': '+'}</div>
            </div>
            <div className={classNames('description', expanded && ' expanded')}>
                {props.children}
            </div>
        </div>
    )
};