import { Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, exhaustMap, filter, map } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { RootState } from "../../index.reducers";
import * as API from "../../services/api";
import { actions, ActionsType } from "../../store";


export const stisticsEpic: Epic<
  ActionsType,
  ActionsType,
  RootState,
  typeof API
> = (action$, store, { getPoolHistory }) => {
  return action$.pipe(
    filter(isActionOf(actions.statisticsGetAction)),
    exhaustMap((action) => {
      return from(getPoolHistory()).pipe(
        map(actions.statisticsSetAction),
        catchError((error) => of(actions.statisticsErrorAction(error)))
      );
    }
    )
  );
}

export default stisticsEpic;