import { createReducer } from "typesafe-actions";
import PoolStats, { IPoolStatHistory } from "../models/pool-stats";
import Quote from "../models/quote";
import RecentlyFarmed from "../models/recently-farmed";
import { actions, ActionsType } from "../store";

export interface RecentlyFarmedState {
    recentlyFarmed?: RecentlyFarmed;
}

export interface ChiaQuoteState {
    chiaQuote?: Quote;
}

export interface PoolStatsState {
    poolStats?: PoolStats
    history?: IPoolStatHistory[];
}

export const initialState = {};

export const recentlyFarmedReducer = createReducer<RecentlyFarmedState, ActionsType>(
    initialState
)
    .handleAction(actions.recentlyFarmedSetAction, (state, action) => ({
        ...state,
        recentlyFarmed: new RecentlyFarmed(action.payload),
    }))
    .handleAction(actions.recentlyFarmedErrorAction, (state, action) => {
        console.error(action.payload);
        return state;
    });


export const chiaQuoteReducer = createReducer<ChiaQuoteState, ActionsType>(
    initialState
)
    .handleAction(actions.chiaQuoteSetAction, (state, action) => ({
        ...state,
        chiaQuote: new Quote(action.payload),
    }))
    .handleAction(actions.chiaQuoteErrorAction, (state, action) => {
        console.error(action.payload);
        return state;
    });


export const poolStatsReducer = createReducer<PoolStatsState, ActionsType>(
    initialState
)
    .handleAction(actions.poolStatsSetAction, (state, action) => ({
        ...state,
        poolStats: Object.assign({}, action.payload),
    }))
    .handleAction(actions.poolStatHistorySetAction, (state, action) => ({
        ...state,
        history: [...action.payload],
    }))
    .handleAction(actions.poolStatsErrorAction, (state, action) => {
        console.error(action.payload);
        return state;
    })
    .handleAction(actions.poolStatHistoryErrorAction, (state, action) => {
        console.error(action.payload);
        return state;
    });
