import { t } from '@lingui/macro';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { RootState } from '../../index.reducers';

export const RecentFarmRewardsStatsChart = () => {
    // https://recharts.org/en-US/examples/SimpleBarChart

    const { stats } = useSelector((state: RootState) => state.account);
    const dataMap = new Map<string, { sortKey: number, value: number }>();
    const darkMode = useSelector((state: RootState) => state.topLevel.darkMode);

    if (!stats || !stats.length) {
        return null;
    }

    const startDate = moment(moment().subtract({ days: 6 }).format('YYYY/MM/DD'));
    for (const stat of stats) {
        const date = moment.utc(stat.creditedDayHour).local();
        if (date.isBefore(startDate)) {
            continue;
        }
        const key = date.format('YY/MM/DD');
        let value = dataMap.get(key) || { sortKey: 0, value: 0 };
        dataMap.set(key, { sortKey: date.valueOf(), value: value.value + (stat.balanceIncreaseInMojo / 10 ** 12) });
    }

    let data: { sortKey: number; date: string, value: number }[] = [];
    for (const [date, value] of dataMap) {
        if (date < '21/07/30') continue;

        data.push({ date, sortKey: value.sortKey, value: +value.value.toFixed(4) });
    }

    data = data.sort((a, b) => a.sortKey - b.sortKey);

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                width={500}
                height={300}
                barSize={50}
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" opacity="0.3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip cursor={{ fill: '#555', fillOpacity: "0.3" }} contentStyle={{ backgroundColor: darkMode ? '#000' : '#FFF', opacity: '0.7' }} />
                <Legend />
                <Bar dataKey="value" name={t`XCH reward`} fill="#03b000" />
            </BarChart>
        </ResponsiveContainer>
    );
}
