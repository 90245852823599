export const isEmbedMode = () => {
    return navigator.userAgent.indexOf('SpacePoolApp') >=0
}

export const isEmbedModeDogfood = () => {
    return navigator.userAgent.indexOf('SpacePoolApp dogfood') >=0
}

export const handleMobileAppNavigation = (ev: any, url?: string) => {
    var openUrl = url || ev.target.href;
    if (!openUrl) {
        throw new Error("invalid openUrl request");
    }
    if (navigator.userAgent.indexOf('SpacePoolApp') >=0) {
        mobilePostMessage(JSON.stringify({navigateTo: 'OpenUrl', url: openUrl}), '*');
        ev.preventDefault();
    }
}

export const mobilePostMessage = (message: string, target: string) => {
    try {
        if(window['ReactNativeWebView']) {
            window['ReactNativeWebView'].postMessage(message);
        } else {
            window.parent.postMessage(message, target);
        }
    } catch(e) {
        alert(e);
    }
}