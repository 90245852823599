import { t, Trans } from '@lingui/macro';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { ChiaPriceTicker } from '../components/chia-price-ticker/chia-price-ticker';
import { HowToJoinModal } from '../components/how-to-join-modal/how-to-join-modal';
import { LuckInfoIcon } from '../components/luck-info-icon/luck-info-icon';
import { NetspaceChart } from '../components/netspace-chart/netspace-chart';
import { PoolNetspaceInfoIcon } from '../components/pool-netspace-info-icon/pool-netspace-info-icon';
import { RecentlyFarmed } from '../components/recently-farmed/recently-farmed';
import { RollingNumber } from '../components/rolling-number/rolling-number';
import { XCHTiBIcon } from '../components/xch-tib-icon/xch-tib-icon';
import BackgroundImg from '../images/background-landing.png';
import HeroImg from '../images/hero.png';
import { RootState } from "../index.reducers";
import { actions } from "../store";
import { isEmbedMode } from '../utils/navigator-util';
import { timeToWin } from '../utils/num-util';
import { formatTiB } from '../utils/tib-formatter';
import './landing-page.scss';

var howDoIJoinUrl = document.location.href.indexOf("testnet") >= 0 ?
    "https://blog.pool.space/testing-space-pool-on-testnet9-330926d746cc" :
    "https://blog.pool.space/space-pool-and-the-official-chia-pooling-protocol-are-officially-released-3cd348b57e73";

var isJoinBeta = true;

// HACK: calculate block reward and decide to show stats based on estimated halfing date
const blockHalfingDate = '03/07/2024';
const dateDiff = Date.now() - new Date(blockHalfingDate).getTime();
const diffDays = Math.ceil(dateDiff / (1000 * 3600 * 24));
const currentDatePastHalfing = new Date() > new Date(blockHalfingDate);
const show24HourStats = !currentDatePastHalfing || diffDays > 2;
const show30DayStats = !currentDatePastHalfing || diffDays > 31;
const blockRewardAmount = !currentDatePastHalfing ? 1.75 : 0.875;

export const LandingPage = () => {
    const isEmbed = isEmbedMode();
    const dispatch = useDispatch();

    const [showHowDoIJoinModal, setShowHowDoIJoinModal] = useState(false);
    useEffect(() => {
        dispatch(actions.poolStatsGetAction());
        dispatch(actions.poolStatHistoryGetAction());
    }, [dispatch]);
    let { poolStats, history: poolStatsHistory } = useSelector((state: RootState) => state.poolStats);
    if (!poolStats || !poolStatsHistory) {
        return null;
    }

    const handleHowDoIJoinModalHide = () => {
        setShowHowDoIJoinModal(false);
    };

    const historyLookbackDays = 30;

    poolStatsHistory = poolStatsHistory.filter(x => x.day >= moment.utc().subtract({ days: historyLookbackDays }).format('YYYY-MM-DD'));
    const blocksFoundhistoryLookbackDays = poolStatsHistory.reduce((sum, x) => sum + x.blocksFound, 0);

    const netspace = formatTiB(poolStats.totalNetSpaceTiB);
    const poolspace = formatTiB(poolStats.poolNetSpaceTiB);
    const xchPerTiB = show24HourStats && ((poolStats.last24HourStats?.blocksFound || 0) * blockRewardAmount) / (poolStats.poolNetSpaceTiB || 1);
    const xchPerTiBHistory = show30DayStats && ((blocksFoundhistoryLookbackDays * blockRewardAmount) / poolStatsHistory.reduce((sum, x) => sum + x.peakAsiaPoolNetspaceTiB + x.peakEuPoolNetspaceTiB + x.peakNaPoolNetspaceTiB, 0)) * poolStatsHistory.length;

    return (
        <div className="landing-page">
            <div className='hero'>
                <div className='content'>
                    <div className='image-container'>
                        <img className='image' src={HeroImg} alt='logo' />
                    </div>
                    <div className='pitch'>
                        <h4>
                            <Trans>Welcome to <span className='accent-color'>Space Pool</span></Trans>
                        </h4>
                        <div className="join-forces" style={{ color: 'red' }}>Space Pool is shutting down on Nov 15 2024. All remaining balances will automatically be paid out by Nov 30 2024. No minimum requirement.</div>
                        {/* <div className="buttons-group">
                            <span className="how-to-join">
                                {!isJoinBeta && <a onClick={(ev: any) => handleMobileAppNavigation(ev, howDoIJoinUrl)} href={howDoIJoinUrl} rel="noreferrer" target='_blank'><button className='primary'><Trans>How do I join?</Trans></button></a>}
                                {isJoinBeta && <a onClick={(ev: any) => { setShowHowDoIJoinModal(true); ev.preventDefault() }} href="#" rel="noreferrer" target='_blank'><button className='primary'><Trans>How do I join?</Trans></button></a>}
                            </span>

                            {!isEmbed &&
                                <a href="https://apps.apple.com/us/app/space-pool-chia-farming/id1574548334" target="_blank" rel="noreferrer">
                                    <img className="download-ios" src={DownloadIOSImg} alt={t`Download iOS App`} /></a>
                            }

                            {!isEmbed &&
                                <a href="https://play.google.com/store/apps/details?id=space.pool" target="_blank" rel="noreferrer">
                                    <img className="download-android" src={DownloadAndroidImg} alt={t`Download Android App`} /></a>
                            }
                        </div> */}

                        <div className="stats">
                            <div className="stat nestpace">
                                <div className="label"><Trans>Global Netspace</Trans> {document.location.href.indexOf("testnet") >= 0 ? ' (testnet9)' : ''}</div>
                                <div className="value"><RollingNumber value={netspace.value} /> {netspace.unit}</div>
                            </div>
                            <div className="stat power">
                                <div style={{ display: 'flex' }}>
                                    <div className="label"><Trans>Pool Netspace</Trans></div>
                                    <PoolNetspaceInfoIcon />
                                </div>
                                <div className="value"><RollingNumber value={poolspace.value} /> {poolspace.unit}</div>
                            </div>
                            <div className="stat farms">
                                <div className="label"><Trans>Farmers online</Trans></div>
                                <div className="value"><RollingNumber value={poolStats.totalFarms} decimals={0} /></div>
                            </div>
                            <div className="stat timeToWin">
                                <div className="label"><Trans>Est. Time to win</Trans></div>
                                <div className="value">{poolStats.estimatedTimeToWinSeconds ? timeToWin(poolStats.estimatedTimeToWinSeconds) : '-'}</div>
                            </div>
                            {isEmbedMode() && <div className="stat xchPrice">
                                <ChiaPriceTicker twoLines={true} />
                            </div>}
                        </div>

                        <div className='stats stats-headers'>
                            {
                                poolStats.last24HourStats?.blocksFound &&
                                <div className="stat">
                                    <div className="label"><Trans>Blocks Farmed</Trans></div>
                                    <div className="value"><RollingNumber value={poolStats.last24HourStats?.blocksFound} decimals={0} /></div>
                                </div>
                            }
                            {
                                show24HourStats && poolStats.last24HourStats?.blocksFound &&
                                <div className="stat">
                                    <div className="label"><Trans>XCH Farmed</Trans></div>
                                    <div className="value"><RollingNumber value={poolStats.last24HourStats?.blocksFound * blockRewardAmount} decimals={2} /></div>
                                </div>
                            }
                            {
                                poolStats.last24HourStats?.averageLuck &&
                                <div className="stat">
                                    <div className="label" style={{ display: 'flex' }}>
                                        <div><Trans>Luck</Trans></div>
                                        <LuckInfoIcon />
                                    </div>
                                    <div className="value"><RollingNumber value={poolStats.last24HourStats.averageLuck * 100} decimals={0} />%</div>
                                </div>
                            }
                            {
                                show24HourStats && xchPerTiB &&
                                <div className="stat">
                                    <div className="label" style={{ display: 'flex' }}>
                                        <div><Trans>XCH / 10 TiB</Trans></div>
                                        <XCHTiBIcon period={t`24 hours and ${poolStatsHistory.length} days`} />
                                    </div>
                                    <div className="value"><RollingNumber value={xchPerTiB * 10} decimals={5} /></div>
                                </div>
                            }
                        </div>

                        <div className='state-aggregate-header'><Trans>24 Hours</Trans></div>
                        <div className='stats stats-24-hours'>
                            {
                                poolStats.last24HourStats?.blocksFound &&
                                <div className="stat">
                                    <div className="label"><Trans>Blocks Farmed</Trans></div>
                                    <div className="value"><RollingNumber value={poolStats.last24HourStats?.blocksFound} decimals={0} /></div>
                                </div>
                            }
                            {
                                show24HourStats && poolStats.last24HourStats?.blocksFound &&
                                <div className="stat">
                                    <div className="label"><Trans>XCH Farmed</Trans></div>
                                    <div className="value"><RollingNumber value={poolStats.last24HourStats?.blocksFound * blockRewardAmount} decimals={2} /></div>
                                </div>
                            }
                            {
                                poolStats.last24HourStats?.averageLuck &&
                                <div className="stat">
                                    <div className="label" style={{ display: 'flex' }}>
                                        <div><Trans>Luck</Trans></div>
                                        <LuckInfoIcon />
                                    </div>
                                    <div className="value"><RollingNumber value={poolStats.last24HourStats.averageLuck * 100} decimals={0} />%</div>
                                </div>
                            }
                            {
                                xchPerTiB &&
                                <div className="stat">
                                    <div className="label" style={{ display: 'flex' }}>
                                        <div><Trans>XCH / 10 TiB</Trans></div>
                                        <XCHTiBIcon period={t`24 hours`} />
                                    </div>
                                    <div className="value"><RollingNumber value={xchPerTiB * 10} decimals={5} /></div>
                                </div>
                            }
                        </div>

                        {show30DayStats && poolStatsHistory.length === historyLookbackDays && <div className='state-aggregate-header'><Trans>{historyLookbackDays} days</Trans></div>}
                        {
                            show30DayStats && poolStatsHistory.length === historyLookbackDays && <div className='stats stats-history'>
                                <div className="stat">
                                    <div className="label"><Trans>Blocks Farmed</Trans></div>
                                    <div className="value"><RollingNumber value={blocksFoundhistoryLookbackDays} decimals={0} /></div>
                                </div>

                                <div className="stat">
                                    <div className="label"><Trans>XCH Farmed</Trans></div>
                                    <div className="value"><RollingNumber value={blocksFoundhistoryLookbackDays * blockRewardAmount} decimals={2} /></div>
                                </div>

                                <div className="stat">
                                    <div className="label" style={{ display: 'flex' }}>
                                        <div><Trans>Luck</Trans></div>
                                        <LuckInfoIcon />
                                    </div>
                                    <div className="value"><RollingNumber value={(poolStatsHistory.reduce((sum, x) => sum + x.averageLuck, 0) / poolStatsHistory.length) * 100} decimals={0} />%</div>
                                </div>
                                {
                                    xchPerTiBHistory && <div className="stat">
                                        <div className="label" style={{ display: 'flex' }}>
                                            <div><Trans>XCH / 10 TiB</Trans></div>
                                            <XCHTiBIcon period={t`30 days`} />
                                        </div>
                                        <div className="value"><RollingNumber value={xchPerTiBHistory * 10} decimals={5} /></div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
            <HowToJoinModal show={showHowDoIJoinModal} onHide={handleHowDoIJoinModalHide} />
            <div className='chart-container'>
                <h4><Trans>Pool Netspace</Trans></h4>
                <NetspaceChart range="30d" />
            </div>
            <RecentlyFarmed showTitle={true} showViewAll={true}></RecentlyFarmed>
            <img className='background-image' src={BackgroundImg} alt="" />
        </div>
    );
};