import classNames from 'classnames';
import './spacepool-table.scss';

export interface ISpacePoolTableProps {
    headerLine?: boolean;
    headers: {
        compact?: boolean;
        extraClassName?: string;
        flexible?: boolean;
        label: any;
    }[];
    rowLine?: boolean;
    rows: any[][];
}

export const SpacePoolTable = (props: ISpacePoolTableProps) => {
    return (
        <div className={classNames('spacepool-table', props.headerLine && 'header-line', props.rowLine && 'row-line')}>
            {
                props.headers.map((header, i) =>
                    <div key={i} className={classNames('spacepool-table-column', header.extraClassName, header.flexible && 'flexible', header.compact && 'compact')}>
                        {
                            <>
                                <div className='spacepool-table-column-header'>{header.label}</div>
                                {
                                    props.rows.map(x => x[i]).map((value, j) =>
                                        <div key={j} className='spacepool-table-column-value'>{value}</div>
                                    )
                                }
                            </>
                        }
                    </div>
                )
            }
        </div>
    );
};