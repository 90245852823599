import { t } from '@lingui/macro';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { RootState } from '../../index.reducers';

export const EstimatedPlotSizeChart = () => {
    const darkMode = useSelector((state: RootState) => state.topLevel.darkMode);
    const chartingData = useSelector((state: RootState) => state.account.chartingData);

    if (!chartingData) {
        return null;
    }
    return (
        <div style={{ width: '100%', height: '100%', paddingBottom: '100px' }}>
            <span style={{ display: 'block', clear: 'both' }}></span>
            <ResponsiveContainer width="100%" height="100%">
                <AreaChart data={chartingData}>
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="0%" stopColor="#03b000" stopOpacity={0.5} />
                            <stop offset="100%" stopColor="#03b000" stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="date" minTickGap={100} tickSize={16} tickFormatter={(date) => moment.utc(date).format('HH:mm')} />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" strokeOpacity="0.3" />
                    <Tooltip contentStyle={{ backgroundColor: darkMode ? '#000' : '#FFF', opacity: '0.7' }} />
                    <Legend />
                    <Area name={t`Estimated Plot Size`} unit=" TiB" type="monotone" dataKey="value" stroke="#03c000" fillOpacity={1} fill="url(#colorUv)" />
                    <Area name={t`Average`} unit=" TiB" type="monotone" dataKey="avg" stroke={darkMode ? "#ACB739" : "#A73489"} fillOpacity={0} />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
}
