import classNames from 'classnames';
import * as React from 'react';
import { useEffect } from 'react';
import './toggle-switch.scss';

export interface IToggleSwitchProps {
    disabled?: boolean;
    forceState?: boolean;
    initialState?: boolean;
    label: string;
    onChange?: (isActive: boolean) => void;
    showLabelFirst?: boolean;
    description?: string;
}

export const ToggleSwitch = (props: IToggleSwitchProps) => {
    const [isActive, setIsActive] = React.useState(props.initialState || props.forceState);

    useEffect(() => {
        if (typeof props.forceState === 'boolean') {
            setIsActive(props.forceState);
        }
    }, [props.forceState]);

    const handleClick = () => {
        if (props.disabled) {
            return;
        }

        if (typeof props.forceState !== 'boolean') {
            setIsActive(!isActive);
        }
        props.onChange && props.onChange(!isActive);
    };

    return (
        <div title={props.description} className={classNames('toggle-switch', props.disabled && 'disabled', (!props.disabled && isActive) && 'active', props.showLabelFirst && 'label-first')} onClick={handleClick}>
            {props.showLabelFirst && <div className='label'>{props.label}</div>}
            <div className='switch'>
                <div className='slider' />
            </div>
            {!props.showLabelFirst && <div className='label'>{props.label}</div>}
        </div>
    );
};