import { t, Trans } from '@lingui/macro';
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { SpacePoolTable } from '../components/spacepool-table/spacepool-table';
import { RootState } from "../index.reducers";
import { actions } from "../store";
import { handleMobileAppNavigation } from '../utils/navigator-util';
import './unclaimed-blocks-page.scss';

interface Props {
    autoRefresh?: boolean;
    autoRefreshValueInSecs?: number;
}

export const UnclaimedBlocksPage = (props: Props) => {
    const { accountId } = useParams<{ accountId: string }>();
    const dispatch = useDispatch();
    const getData = () => {
        dispatch(actions.accountUnclaimedBlocksGetAction({ launcherId: accountId }));
    };

    useEffect(() => {
        getData();
        if (props.autoRefresh) {
            const interval = setInterval(getData, (props.autoRefreshValueInSecs || 60) * 1000);
            return () => clearInterval(interval);
        }
    }, [dispatch, accountId, props.autoRefresh]);

    const { unclaimedBlocks } = useSelector((state: RootState) => state.account);

    if (!unclaimedBlocks) {
        return null;
    }

    var title = t`This account has ${unclaimedBlocks.length} unclaimed blocks`;
    if (unclaimedBlocks.length === 1) {
        title = t`This account has 1 unclaimed block`;
    }
    return (
        <div className='unclaimed-blocks-page'>
            <div className='section-header'><Trans>{title}</Trans></div>
            <br/><div><Trans>In accordance to section 3.5 (Unclaimed Block Winnings) of the <a onClick={(ev: any) => handleMobileAppNavigation(ev)} href="https://pool.space/terms" target="_blank"><Trans>terms</Trans></a> of service, Space Pool will send the full XCH pool reward, without pool fees, back to the block winner after verifying some information with the account owner via login link within 30 days of finding the block.</Trans></div><br/>
            <SpacePoolTable
                headerLine={true}

                headers={[
                    {
                        label: <Trans>Date</Trans>,
                    },
                    {
                        label: <Trans>Unclaimed Block #</Trans>,
                    },
                    {
                        label: <Trans>Coin Id</Trans>,
                        flexible: true,
                    }
                ]}
                rows={
                    unclaimedBlocks.map((row, i) => [
                        Intl.DateTimeFormat(undefined, { dateStyle: 'short', timeStyle: 'short' }).format(new Date(row.dateTimeUtc)),
                        row.claimBlockNumber,
                        row.claimCoinId
                    ])
                }
            />
        </div>
    );

};

