import { Trans } from '@lingui/macro';
import BackgroundImg from '../images/background-account.png';
import { handleMobileAppNavigation } from '../utils/navigator-util';
import './about-page.scss';

export const AboutPage = () => {
    return (
        <div className='about-page'>
            <h4><Trans>About Us</Trans></h4>
            <div className='about-page-content'>
                <p>
                    <Trans>Welcome to Space Pool! </Trans>
                </p>
                <p>
                    <Trans>We offer a service that normalizes frequency of farming rewards. While it does not increase the potential profit, it increases the frequency of those rewards. With Chia, 7/8 of farming rewards belong to the pool, and the pool distributes this amount to farmers proportional to their contribution.</Trans>
                </p>
                <p>
                    <Trans>Space Pool, LLC is made up of a team with over 35 years of industry experience. Our team brings expertise from the tech giants IBM, Microsoft, and Facebook to the next generation of cryptocurrency pools.</Trans>
                </p>
                <p>
                    <Trans>We value transparency, privacy, and support. Join forces to take back control of predictable Chia farming winnings.</Trans>
                </p>
                <p>
                    <Trans>To connect with Space Pool, find us on <a onClick={(ev: any) => handleMobileAppNavigation(ev)} href='https://pool.space/discord' rel="noreferrer" target='_blank'>Discord</a> or email us at <a href='mailto:info@pool.space'>info@pool.space</a></Trans>
                </p>
            </div>
            <img className='background-image' src={BackgroundImg} alt="" />
        </div>
    );
};