export const cookie: any = {};

for (const kvp of document.cookie.split(';')) {
    const split = kvp.split('=');
    if (split.length !== 2) {
        continue;
    }
    cookie[split[0].trim()] = split[1].trim();
}

export const getCookie = <T>(key: string): T | undefined => {
    return cookie[key] as T;
};

export const setCookie = (key: string, value: string, expDays?: number) => {
    const expiration = expDays || 365;
    let date = new Date();
    date.setTime(date.getTime() + (expiration * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = key + "=" + value + "; " + expires + "; path=/";
};

export const deleteCookie = (key: string) => {
    delete cookie[key];
    document.cookie = `${key}=`;
};
