import React from 'react';
import './copy-icon.scss';
import { t } from '@lingui/macro';

type Props = {
    text: string;
};

export const CopyIcon = (props: Props) => {
    const [isCopied, setIsCopied] = React.useState(false);

    var onCopy = (ev: any) => {
        if (!isCopied) {
            var clipboardData = {};
            clipboardData["text/plain"] = new Blob([props.text], { type: 'text/plain' });
            var clipboardItem = new window['ClipboardItem'](clipboardData);
            navigator.clipboard['write']([clipboardItem]);
            setIsCopied(true);
        }
    };

    return (
        <div className="copy-icon" title={!isCopied ? t`Copy to clipboard` : t`Copied to clipboard`}>
            { !isCopied && <svg onClick={onCopy} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard" viewBox="0 0 16 16">
                <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
            </svg>} 
            { isCopied &&  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check2" viewBox="0 0 16 16">
                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                </svg>
            }

        </div>
    )
};