import { t, Trans } from '@lingui/macro';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from "react-router-dom";
import { ToggleSwitch } from '../../components/toggle-switch/toggle-switch';
import useLocale from '../../hooks/useLocale';
import LogoImg from '../../images/logo.png';
import { setDarkModeAction } from '../../index.actions';
import { RootState } from '../../index.reducers';
import { ChiaPriceTicker } from '../chia-price-ticker/chia-price-ticker';
import './top-bar.scss';

export const TopBar = () => {
    const history = useHistory();
    const [locale, setLocale] = useLocale();
    const dispatch = useDispatch();

    const darkMode = useSelector((state: RootState) => state.topLevel.darkMode);

    var onEnter = (ev: any) => {
        const searchbox = document.getElementById('searchbox') as HTMLInputElement;
        if (searchbox) {
            history.push(`/account/${searchbox.value.trim()}`);
            ev.preventDefault();
        }
    };

    const onDarkModeToggle = (darkMode: boolean) => {
        dispatch(setDarkModeAction({ darkMode }));
    };

    return (
        <Navbar collapseOnSelect expand="lg" bg={darkMode ? "dark" : ""} sticky="top" >
            <Navbar.Brand> <Link to="/">
                <img
                    src={LogoImg}
                    height="50"
                    className="d-inline-block align-top"
                    alt="Space Pool"
                /></Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav>
                    <Nav.Link><Link to="/"><Trans>Home</Trans></Link></Nav.Link>
                    <Nav.Link target="_blank" href="https://pool.space/discord">Discord</Nav.Link>
                    <Nav.Link><Link to="/leaderboard"><Trans>Leaderboard</Trans></Link></Nav.Link>
                    <Nav.Link><Link to="/statistics"><Trans>Statistics</Trans></Link></Nav.Link>
                    <Nav.Link target="_blank" href="https://xch.space">Explorer</Nav.Link>
                </Nav>
                <Nav className="mr-auto">
                </Nav>
                <Form inline onSubmit={onEnter}>
                    <ToggleSwitch label={t`Dark Mode`} initialState={darkMode} onChange={onDarkModeToggle} />
                    <ChiaPriceTicker />
                    <FormControl type="text" placeholder={t`Launcher Id or alias`} id="searchbox" className="mr-sm-2" />
                    <Button onClick={onEnter} variant="outline-success"> <Trans>Search</Trans></Button>
                </Form>
            </Navbar.Collapse>
        </Navbar>
    );
};