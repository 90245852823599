import { Trans } from '@lingui/macro';
import './recent-blocks-page.scss';
import { RecentlyFarmed } from "../components/recently-farmed/recently-farmed";

export const RecentBlocksPage = () => {
    return (
        <div className='recent-blocks-page'>
            <div className='recent-blocks-page-title'>
                <h4><Trans>Recently Farmed Chia</Trans></h4>
            </div>
            <RecentlyFarmed></RecentlyFarmed>
        </div>
    );
};
