export default class Quote {
    public data!: {
      XCH: {
          quote: {
              USD: {
                percent_change_24h: number,
                price: number
              },
              EUR: {
                percent_change_24h: number,
                price: number
              }
          }
      }
    };
   
    constructor(args?: {}) {
      Object.assign(this, args);
    }
  }
  
